import React, { useState, useEffect } from 'react';
import { useAuthStore } from '../store/authStore';
import { supabase } from '../lib/supabase';
import { Gavel, Clock, AlertCircle, Loader, Trophy, Wallet } from 'lucide-react';
import CoachNavigation from '../components/CoachNavigation';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/locale';

interface Team {
  id: string;
  league_id: string;
  budget_remaining: number;
}

interface Auction {
  id: string;
  league: {
    id: string;
    name: string;
    manager: {
      username: string;
    };
  };
  player: {
    id: string;
    name: string;
    role: string;
    real_team: string;
    base_value: number;
  };
  current_bid: number;
  highest_bidder_id: string | null;
  highest_bidder?: {
    username: string;
  };
  ends_at: string;
  status: 'pending' | 'active' | 'completed';
}

const LiveAuctions: React.FC = () => {
  const { user } = useAuthStore();
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [placingBid, setPlacingBid] = useState<string | null>(null);
  const [bidAmounts, setBidAmounts] = useState<Record<string, number>>({});
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      loadAuctions();
      loadTeams();
      
      // Subscribe to auction changes
      const channel = supabase
        .channel('auction_changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'auctions'
          },
          () => {
            loadAuctions();
            loadTeams();
          }
        )
        .subscribe();

      // Set up periodic refresh
      const refreshInterval = setInterval(() => {
        loadAuctions();
        loadTeams();
      }, 10000);

      return () => {
        channel.unsubscribe();
        clearInterval(refreshInterval);
      };
    }
  }, [user]);

  const loadTeams = async () => {
    if (!user) return;

    try {
      const { data, error } = await supabase
        .from('teams')
        .select('id, league_id, budget_remaining')
        .eq('coach_id', user.id);

      if (error) throw error;

      if (data && data.length > 0) {
        setTeams(data);
        if (!selectedTeam) {
          setSelectedTeam(data[0].id);
        }
      }
    } catch (err) {
      console.error('Error loading teams:', err);
      setError('Errore nel caricamento delle squadre');
    }
  };

  const loadAuctions = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from('auctions')
        .select(`
          id,
          league:leagues(
            id,
            name,
            manager:profiles(username)
          ),
          player:players(
            id,
            name,
            role,
            real_team,
            base_value
          ),
          current_bid,
          highest_bidder_id,
          highest_bidder:profiles(username),
          ends_at,
          status
        `)
        .eq('status', 'active')
        .gt('ends_at', new Date().toISOString())
        .order('ends_at', { ascending: true });

      if (error) throw error;

      setAuctions(data || []);

      // Initialize bid amounts for new auctions
      const newBidAmounts = { ...bidAmounts };
      data?.forEach(auction => {
        if (!bidAmounts[auction.id]) {
          newBidAmounts[auction.id] = auction.current_bid + 1;
        }
      });
      setBidAmounts(newBidAmounts);
    } catch (err: any) {
      console.error('Error loading auctions:', err);
      setError('Errore nel caricamento delle aste');
    } finally {
      setLoading(false);
    }
  };

  const placeBid = async (auctionId: string, currentBid: number) => {
    if (!user || !selectedTeam) return;
    
    try {
      setPlacingBid(auctionId);
      setError(null);

      const bidAmount = bidAmounts[auctionId];
      if (!bidAmount || bidAmount <= currentBid) {
        throw new Error('L\'offerta deve essere superiore all\'offerta corrente');
      }

      const team = teams.find(t => t.id === selectedTeam);
      if (!team) {
        throw new Error('Squadra non trovata');
      }

      if (team.budget_remaining < bidAmount) {
        throw new Error('Budget insufficiente');
      }

      // First validate the bid - Note the correct parameter order
      const { data: validationData, error: validationError } = await supabase
        .rpc('validate_bid', {
          p_amount: bidAmount,
          p_auction_id: auctionId,
          p_user_id: user.id
        });

      if (validationError) throw validationError;

      // If validation passes, place the bid
      const { error: bidError } = await supabase
        .from('auctions')
        .update({
          current_bid: bidAmount,
          highest_bidder_id: user.id,
          updated_at: new Date().toISOString()
        })
        .eq('id', auctionId)
        .eq('status', 'active')
        .gt('ends_at', new Date().toISOString());

      if (bidError) throw bidError;

      // Clear bid amount for this auction
      setBidAmounts(prev => {
        const newAmounts = { ...prev };
        delete newAmounts[auctionId];
        return newAmounts;
      });

      await loadAuctions();
      await loadTeams();
    } catch (err: any) {
      console.error('Error placing bid:', err);
      setError(err.message);
    } finally {
      setPlacingBid(null);
    }
  };

  if (loading && auctions.length === 0) {
    return (
      <div>
        <CoachNavigation />
        <div className="flex justify-center items-center h-64">
          <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <CoachNavigation />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900">Aste Live</h2>
              {teams.length > 0 && (
                <div className="flex items-center space-x-4">
                  <div className="flex items-center space-x-2">
                    <Wallet className="w-5 h-5 text-gray-400" />
                    <select
                      value={selectedTeam || ''}
                      onChange={(e) => setSelectedTeam(e.target.value)}
                      className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      {teams.map(team => (
                        <option key={team.id} value={team.id}>
                          Budget: {team.budget_remaining}M
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>

            {error && (
              <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
                <AlertCircle className="w-5 h-5 mr-2" />
                {error}
              </div>
            )}

            {auctions.length === 0 ? (
              <div className="text-center py-12">
                <Gavel className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna asta attiva</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Al momento non ci sono aste in corso
                </p>
              </div>
            ) : (
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {auctions.map(auction => {
                  const team = teams.find(t => t.id === selectedTeam);
                  const canBid = team && team.budget_remaining >= (bidAmounts[auction.id] || auction.current_bid + 1);
                  const isHighestBidder = auction.highest_bidder_id === user?.id;
                  
                  return (
                    <div
                      key={auction.id}
                      className={`bg-white border rounded-lg shadow-sm hover:shadow-md transition-shadow ${
                        isHighestBidder ? 'border-green-500' : ''
                      }`}
                    >
                      <div className="p-6">
                        <div className="flex items-center justify-between mb-4">
                          <div>
                            <h3 className="text-lg font-medium text-gray-900">
                              {auction.player.name}
                            </h3>
                            <p className="text-sm text-gray-500 flex items-center">
                              <Trophy className="w-4 h-4 mr-1" />
                              {auction.league.name}
                            </p>
                          </div>
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            auction.player.role === 'P' ? 'bg-yellow-100 text-yellow-800' :
                            auction.player.role === 'D' ? 'bg-blue-100 text-blue-800' :
                            auction.player.role === 'C' ? 'bg-green-100 text-green-800' :
                            'bg-red-100 text-red-800'
                          }`}>
                            {auction.player.role}
                          </span>
                        </div>

                        <div className="space-y-2 mb-4">
                          <p className="text-sm text-gray-600">
                            Squadra: {auction.player.real_team}
                          </p>
                          <p className="text-sm text-gray-600">
                            Valore base: {auction.player.base_value}M
                          </p>
                          {auction.highest_bidder && (
                            <p className="text-sm text-gray-600">
                              Miglior offerente: {auction.highest_bidder.username}
                            </p>
                          )}
                          <p className="text-sm text-gray-600 flex items-center">
                            <Clock className="w-4 h-4 mr-1" />
                            Termina {formatDistanceToNow(new Date(auction.ends_at), { 
                              addSuffix: true,
                              locale: it 
                            })}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div>
                            <p className="text-sm text-gray-500">Offerta attuale</p>
                            <p className={`text-xl font-bold ${isHighestBidder ? 'text-green-600' : 'text-indigo-600'}`}>
                              {auction.current_bid}M
                            </p>
                          </div>
                          
                          <div className="flex items-center space-x-2">
                            <input
                              type="number"
                              min={auction.current_bid + 1}
                              value={bidAmounts[auction.id] || ''}
                              onChange={(e) => setBidAmounts({
                                ...bidAmounts,
                                [auction.id]: parseInt(e.target.value)
                              })}
                              className={`w-20 rounded-md shadow-sm focus:ring-indigo-500 ${
                                canBid ? 'border-gray-300 focus:border-indigo-500' : 'border-red-300 focus:border-red-500'
                              }`}
                              placeholder={(auction.current_bid + 1).toString()}
                            />
                            <button
                              className={`px-4 py-2 text-white rounded-md ${
                                isHighestBidder
                                  ? 'bg-green-600 cursor-not-allowed'
                                  : canBid 
                                    ? 'bg-indigo-600 hover:bg-indigo-700' 
                                    : 'bg-gray-400 cursor-not-allowed'
                              }`}
                              onClick={() => placeBid(auction.id, auction.current_bid)}
                              disabled={
                                placingBid === auction.id || 
                                !bidAmounts[auction.id] || 
                                bidAmounts[auction.id] <= auction.current_bid ||
                                isHighestBidder ||
                                !selectedTeam ||
                                !canBid
                              }
                            >
                              {placingBid === auction.id ? (
                                <Loader className="w-5 h-5 animate-spin" />
                              ) : isHighestBidder ? (
                                'Miglior Offerta'
                              ) : !canBid ? (
                                'Budget Insufficiente'
                              ) : (
                                'Offri'
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveAuctions;