import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Validate URL format
try {
  new URL(supabaseUrl);
} catch (error) {
  console.error('Invalid Supabase URL format:', error);
  throw new Error('Invalid Supabase URL format');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    storage: {
      getItem: (key) => {
        try {
          const value = localStorage.getItem(key);
          return value ? JSON.parse(value) : null;
        } catch (error) {
          console.error('Error reading from localStorage:', error);
          return null;
        }
      },
      setItem: (key, value) => {
        try {
          localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
          console.error('Error writing to localStorage:', error);
        }
      },
      removeItem: (key) => {
        try {
          localStorage.removeItem(key);
        } catch (error) {
          console.error('Error removing from localStorage:', error);
        }
      }
    },
    storageKey: 'supabase.auth.token',
    flowType: 'pkce'
  }
});

// Set up auth state change listener with error handling
supabase.auth.onAuthStateChange((event, session) => {
  try {
    if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
      // Clear all auth data on sign out or user deletion
      localStorage.removeItem('supabase.auth.token');
      localStorage.removeItem('supabase.auth.refreshToken');
      localStorage.removeItem('supabase.auth.accessToken');
    } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
      // Ensure the session is properly stored
      if (session?.refresh_token) {
        localStorage.setItem('supabase.auth.refreshToken', session.refresh_token);
      }
    }
  } catch (error) {
    console.error('Error handling auth state change:', error);
  }
});

// Test the connection on initialization
(async () => {
  try {
    const { error } = await supabase.from('leagues').select('id').limit(1);
    if (error) {
      console.error('Initial Supabase connection test failed:', error);
    }
  } catch (error) {
    console.error('Error testing Supabase connection:', error);
  }
})();