import React, { useState, useEffect } from 'react';
import { Users, Trophy, Settings, Activity, Plus, Search, UserPlus, Edit, Trash2, AlertCircle, Upload, Download, Book, Gavel } from 'lucide-react';
import { supabase } from '../lib/supabase';
import * as Papa from 'papaparse';
import LeagueRules from './LeagueRules';
import LeagueSettings from './LeagueSettings';

interface User {
  id: string;
  email: string;
  username: string;
  role: 'admin' | 'league_manager' | 'coach';
  created_at: string;
}

interface Player {
  id: string;
  name: string;
  real_team: string;
  role: 'P' | 'D' | 'C' | 'A';
  base_value: number;
  created_at: string;
}

interface Team {
  id: string;
  name: string;
  city: string;
  stadium: string;
  founded_year: number | null;
  active: boolean;
}

const AdminPanel: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'users' | 'players' | 'teams' | 'rules' | 'settings'>('users');
  const [users, setUsers] = useState<User[]>([]);
  const [players, setPlayers] = useState<Player[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [isCreating, setIsCreating] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [teamFormData, setTeamFormData] = useState({
    name: '',
    city: '',
    stadium: '',
    founded_year: '',
    active: true
  });
  const [playerFormData, setPlayerFormData] = useState({
    name: '',
    real_team: '',
    role: 'P' as 'P' | 'D' | 'C' | 'A',
    base_value: 1
  });
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    role: 'coach' as 'admin' | 'league_manager' | 'coach'
  });

  useEffect(() => {
    if (activeTab === 'users') {
      fetchUsers();
    } else if (activeTab === 'players') {
      fetchPlayers();
      fetchTeams();
    } else if (activeTab === 'teams') {
      fetchTeams();
    }
  }, [activeTab]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setUsers(data || []);
    } catch (err) {
      setError('Errore nel caricamento degli utenti');
      console.error('Error fetching users:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPlayers = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('players')
        .select('*')
        .order('name', { ascending: true });

      if (error) throw error;
      setPlayers(data || []);
    } catch (err) {
      setError('Errore nel caricamento dei giocatori');
      console.error('Error fetching players:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchTeams = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('teams_list')
        .select('*')
        .order('name');

      if (error) throw error;
      setTeams(data || []);
    } catch (err) {
      setError('Errore nel caricamento delle squadre');
      console.error('Error fetching teams:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const { data: existingUser } = await supabase
        .from('profiles')
        .select('id')
        .eq('username', formData.username)
        .single();

      if (existingUser) {
        throw new Error('Username già in uso');
      }

      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            username: formData.username,
            role: formData.role
          }
        }
      });

      if (signUpError) throw signUpError;
      if (!authData.user) throw new Error('Errore nella creazione dell\'utente');

      const { error: profileError } = await supabase
        .from('profiles')
        .insert({
          id: authData.user.id,
          username: formData.username,
          role: formData.role
        });

      if (profileError) throw profileError;

      await fetchUsers();
      setIsCreating(false);
      setFormData({
        email: '',
        username: '',
        password: '',
        role: 'coach'
      });
    } catch (err: any) {
      setError(err.message);
      console.error('Error creating user:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePlayer = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      if (!playerFormData.name.trim()) {
        throw new Error('Il nome del giocatore è obbligatorio');
      }

      if (!playerFormData.real_team.trim()) {
        throw new Error('La squadra del giocatore è obbligatoria');
      }

      const { error } = await supabase
        .from('players')
        .insert([{
          ...playerFormData,
          name: playerFormData.name.trim(),
          real_team: playerFormData.real_team.trim()
        }]);

      if (error) throw error;

      await fetchPlayers();
      setIsCreating(false);
      setPlayerFormData({
        name: '',
        real_team: '',
        role: 'P',
        base_value: 1
      });
    } catch (err: any) {
      setError(err.message);
      console.error('Error creating player:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateTeam = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const { error } = await supabase
        .from('teams_list')
        .insert([{
          ...teamFormData,
          founded_year: teamFormData.founded_year ? parseInt(teamFormData.founded_year) : null
        }]);

      if (error) throw error;

      await fetchTeams();
      setIsCreating(false);
      setTeamFormData({
        name: '',
        city: '',
        stadium: '',
        founded_year: '',
        active: true
      });
    } catch (err: any) {
      setError(err.message);
      console.error('Error creating team:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId: string) => {
    if (!confirm('Sei sicuro di voler eliminare questo utente?')) return;

    try {
      setLoading(true);
      const { error } = await supabase.auth.admin.deleteUser(userId);
      if (error) throw error;

      await fetchUsers();
    } catch (err: any) {
      setError('Errore durante l\'eliminazione dell\'utente');
      console.error('Error deleting user:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePlayer = async (playerId: string) => {
    if (!confirm('Sei sicuro di voler eliminare questo giocatore?')) return;

    try {
      setLoading(true);
      const { error } = await supabase
        .from('players')
        .delete()
        .eq('id', playerId);

      if (error) throw error;
      await fetchPlayers();
    } catch (err: any) {
      setError('Errore durante l\'eliminazione del giocatore');
      console.error('Error deleting player:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTeam = async (teamId: string) => {
    if (!confirm('Sei sicuro di voler eliminare questa squadra?')) return;

    try {
      setLoading(true);
      const { error } = await supabase
        .from('teams_list')
        .delete()
        .eq('id', teamId);

      if (error) throw error;
      await fetchTeams();
    } catch (err: any) {
      setError('Errore durante l\'eliminazione della squadra');
      console.error('Error deleting team:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      setLoading(true);
      const text = await file.text();
      Papa.parse(text, {
        header: true,
        complete: async (results) => {
          const validPlayers = results.data
            .filter((row: any) => {
              // Skip rows with missing or empty required fields
              if (!row.name?.trim()) {
                console.warn('Skipping row with missing name:', row);
                return false;
              }
              if (!row.real_team?.trim()) {
                console.warn('Skipping row with missing team:', row);
                return false;
              }
              if (!['P', 'D', 'C', 'A'].includes(row.role)) {
                console.warn('Skipping row with invalid role:', row);
                return false;
              }
              return true;
            })
            .map((row: any) => ({
              name: row.name.trim(),
              real_team: row.real_team.trim(),
              role: row.role,
              base_value: parseInt(row.base_value, 10) || 1
            }));

          if (validPlayers.length === 0) {
            throw new Error('Nessun giocatore valido trovato nel file CSV');
          }

          const { error } = await supabase
            .from('players')
            .insert(validPlayers);

          if (error) throw error;
          await fetchPlayers();
        },
        error: (error) => {
          throw new Error(`Errore durante il parsing del CSV: ${error.message}`);
        }
      });
    } catch (err: any) {
      setError(err.message);
      console.error('Error uploading players:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleExportPlayers = () => {
    const csv = Papa.unparse(players);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'players.csv';
    link.click();
  };

  const filteredUsers = users.filter(user => 
    user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.role.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredPlayers = players.filter(player =>
    player.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    player.real_team.toLowerCase().includes(searchTerm.toLowerCase()) ||
    player.role.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredTeams = teams.filter(team =>
    team.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    team.city?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const stats = [
    { label: 'Utenti Totali', value: users.length, icon: Users },
    { label: 'Gestori Leghe', value: users.filter(u => u.role === 'league_manager').length, icon: Trophy },
    { label: 'Allenatori', value: users.filter(u => u.role === 'coach').length, icon: Settings },
    { label: 'Admin', value: users.filter(u => u.role === 'admin').length, icon: Activity },
  ];

  if (loading && teams.length === 0) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Pannello di Amministrazione</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map((stat) => (
          <div key={stat.label} className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500">{stat.label}</p>
                <p className="text-2xl font-semibold text-gray-900">{stat.value}</p>
              </div>
              <stat.icon className="w-8 h-8 text-indigo-600" />
            </div>
          </div>
        ))}
      </div>

      <div className="bg-white rounded-lg shadow-md">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px">
            <button
              onClick={() => setActiveTab('users')}
              className={`py-4 px-6 text-sm font-medium ${
                activeTab === 'users'
                  ? 'border-b-2 border-indigo-500 text-indigo-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Utenti
            </button>
            <button
              onClick={() => setActiveTab('players')}
              className={`py-4 px-6 text-sm font-medium ${
                activeTab === 'players'
                  ? 'border-b-2 border-indigo-500 text-indigo-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Giocatori
            </button>
            <button
              onClick={() => setActiveTab('teams')}
              className={`py-4 px-6 text-sm font-medium ${
                activeTab === 'teams'
                  ? 'border-b-2 border-indigo-500 text-indigo-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Squadre
            </button>
            <button
              onClick={() => setActiveTab('rules')}
              className={`py-4 px-6 text-sm font-medium ${
                activeTab === 'rules'
                  ? 'border-b-2 border-indigo-500 text-indigo-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <Book className="w-4 h-4 inline mr-2" />
              Regole
            </button>
            <button
              onClick={() => setActiveTab('settings')}
              className={`py-4 px-6 text-sm font-medium ${
                activeTab === 'settings'
                  ? 'border-b-2 border-indigo-500 text-indigo-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <Gavel className="w-4 h-4 inline mr-2" />
              Impostazioni
            </button>
          </nav>
        </div>

        <div className="p-6">
          {activeTab !== 'settings' && activeTab !== 'rules' && (
            <div className="flex justify-between items-center mb-6">
              <div className="relative flex-1 max-w-md">
                <input
                  type="text"
                  placeholder={`Cerca ${activeTab === 'users' ? 'utenti' : activeTab === 'players' ? 'giocatori' : 'squadre'}...`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
              <div className="flex space-x-3">
                {activeTab === 'players' && (
                  <>
                    <label className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 cursor-pointer flex items-center">
                      <Upload className="w-5 h-5 mr-2" />
                      Importa CSV
                      <input
                        type="file"
                        accept=".csv"
                        className="hidden"
                        onChange={handleFileUpload}
                      />
                    </label>
                    <button
                      onClick={handleExportPlayers}
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
                    >
                      <Download className="w-5 h-5 mr-2" />
                      Esporta CSV
                    </button>
                  </>
                )}
                <button
                  onClick={() => setIsCreating(true)}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
                >
                  {activeTab === 'users' ? (
                    <>
                      <UserPlus className="w-5 h-5 mr-2" />
                      Nuovo Utente
                    </>
                  ) : activeTab === 'players' ? (
                    <>
                      <Plus className="w-5 h-5 mr-2" />
                      Nuovo Giocatore
                    </>
                  ) : (
                    <>
                      <Plus className="w-5 h-5 mr-2" />
                      Nuova Squadra
                    </>
                  )}
                </button>
              </div>
            </div>
          )}

          {error && (
            <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
              <AlertCircle className="w-5 h-5 mr-2" />
              {error}
            </div>
          )}

          {activeTab === 'users' && (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Username
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ruolo
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Data Creazione
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Azioni
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredUsers.map((user) => (
                    <tr key={user.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {user.username}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.email}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.role === 'admin' ? 'Amministratore' :
                         user.role === 'league_manager' ? 'Gestore Lega' :
                         'Allenatore'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(user.created_at).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          onClick={() => handleDeleteUser(user.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {activeTab === 'players' && (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nome
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Squadra
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ruolo
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Valore Base
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Azioni
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredPlayers.map((player) => (
                    <tr key={player.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {player.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {player.real_team}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {player.role === 'P' ? 'Portiere' :
                         player.role === 'D' ? 'Difensore' :
                         player.role === 'C' ? 'Centrocampista' :
                         'Attaccante'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {player.base_value}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          onClick={() => handleDeletePlayer(player.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {activeTab === 'teams' && (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nome
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Città
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Stadio
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Anno Fondazione
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Stato
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Azioni
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredTeams.map((team) => (
                    <tr key={team.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {team.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {team.city}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {team.stadium}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {team.founded_year}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          team.active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                        }`}>
                          {team.active ? 'Attiva' : 'Inattiva'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          onClick={() => handleDeleteTeam(team.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {activeTab === 'rules' && (
            <div className="mt-4">
              <LeagueRules />
            </div>
          )}

          {activeTab === 'settings' && (
            <div className="mt-4">
              <LeagueSettings />
            </div>
          )}

          {isCreating && activeTab === 'users' && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
              <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="mt-3">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Nuovo Utente</h3>
                  <form onSubmit={handleCreateUser} className="mt-2 space-y-4">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div>
                      <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                        Username
                      </label>
                      <input
                        type="text"
                        id="username"
                        value={formData.username}
                        onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        value={formData.password}
                        onChange={(e) =>
                          setFormData({ ...formData, password: e.target.value })
                        }
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div>
                      <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                        Ruolo
                      </label>
                      <select
                        id="role"
                        value={formData.role}
                        onChange={(e) => setFormData({ ...formData, role: e.target.value as 'admin' | 'league_manager' | 'coach' })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        <option value="coach">Allenatore</option>
                        <option value="league_manager">Gestore Lega</option>
                        <option value="admin">Amministratore</option>
                      </select>
                    </div>
                    <div className="flex justify-end space-x-3 mt-4">
                      <button
                        type="button"
                        onClick={() => setIsCreating(false)}
                        className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                      >
                        Annulla
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                      >
                        Crea
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {isCreating && activeTab === 'players' && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
              <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="mt-3">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Nuovo Giocatore</h3>
                  <form onSubmit={handleCreatePlayer} className="mt-2 space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Nome
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={playerFormData.name}
                        onChange={(e) => setPlayerFormData({ ...playerFormData, name: e.target.value })}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div>
                      <label htmlFor="real_team" className="block text-sm font-medium text-gray-700">
                        Squadra
                      </label>
                      <select
                        id="real_team"
                        value={playerFormData.real_team}
                        onChange={(e) => setPlayerFormData({ ...playerFormData, real_team: e.target.value })}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        <option value="">Seleziona squadra</option>
                        {teams
                          .filter(team => team.active)
                          .map(team => (
                            <option key={team.id} value={team.name}>
                              {team.name}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                    <div>
                      <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                        Ruolo
                      </label>
                      <select
                        id="role"
                        value={playerFormData.role}
                        onChange={(e) => setPlayerFormData({ ...playerFormData, role: e.target.value as 'P' | 'D' | 'C' | 'A' })}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        <option value="P">Portiere</option>
                        <option value="D">Difensore</option>
                        <option value="C">Centrocampista</option>
                        <option value="A">Attaccante</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="base_value" className="block text-sm font-medium text-gray-700">
                        Valore Base
                      </label>
                      <input
                        type="number"
                        id="base_value"
                        value={playerFormData.base_value}
                        onChange={(e) => setPlayerFormData({ ...playerFormData, base_value: parseInt(e.target.value) })}
                        min="1"
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="flex justify-end space-x-3 mt-4">
                      <button
                        type="button"
                        onClick={() => setIsCreating(false)}
                        className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                      >
                        Annulla
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                      >
                        Crea
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {isCreating && activeTab === 'teams' && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
              <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="mt-3">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Nuova Squadra</h3>
                  <form onSubmit={handleCreateTeam} className="mt-2 space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Nome
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={teamFormData.name}
                        onChange={(e) => setTeamFormData({ ...teamFormData, name: e.target.value })}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div>
                      <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                        Città
                      </label>
                      <input
                        type="text"
                        id="city"
                        value={teamFormData.city}
                        onChange={(e) => setTeamFormData({ ...teamFormData, city: e.target.value })}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div>
                      <label htmlFor="stadium" className="block text-sm font-medium text-gray-700">
                        Stadio
                      </label>
                      <input
                        type="text"
                        id="stadium"
                        value={teamFormData.stadium}
                        onChange={(e) => setTeamFormData({ ...teamFormData, stadium: e.target.value })}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div>
                      <label htmlFor="founded_year" className="block text-sm font-medium text-gray-700">
                        Anno di Fondazione
                      </label>
                      <input
                        type="number"
                        id="founded_year"
                        value={teamFormData.founded_year}
                        onChange={(e) => setTeamFormData({ ...teamFormData, founded_year: e.target.value })}
                        min="1850"
                        max={new Date().getFullYear()}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="active"
                        checked={teamFormData.active}
                        onChange={(e) => setTeamFormData({ ...teamFormData, active: e.target.checked })}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label htmlFor="active" className="ml-2 block text-sm text-gray-900">
                        Squadra Attiva
                      </label>
                    </div>
                    <div className="flex justify-end space-x-3 mt-4">
                      <button
                        type="button"
                        onClick={() => setIsCreating(false)}
                        className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                      >
                        Annulla
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                      >
                        Crea
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;