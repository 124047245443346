import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Trophy, Users, Settings, Trash2, Edit, AlertCircle, Loader, X } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { useLeagueStore } from '../store/leagueStore';
import { supabase } from '../lib/supabase';

interface League {
  id: string;
  name: string;
  max_teams: number;
  budget: number;
  scoring_system: string;
  registration_deadline: string;
  teams: {
    id: string;
    name: string;
    coach: {
      username: string;
    };
  }[];
}

const Leghe: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { createLeague } = useLeagueStore();
  const [leagues, setLeagues] = useState<League[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    maxTeams: 8,
    budget: 500,
    scoringSystem: 'classic',
    registrationDeadline: '',
    matchdays: 38
  });

  // Get tomorrow's date as minimum date for registration deadline
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  const minDateString = minDate.toISOString().split('T')[0];

  useEffect(() => {
    loadLeagues();
  }, [user]);

  const loadLeagues = async () => {
    if (!user) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      // Test connection first
      const { data: testData, error: testError } = await supabase
        .from('leagues')
        .select('id')
        .limit(1);

      if (testError) {
        console.error('Supabase connection test failed:', testError);
        throw new Error('Errore di connessione al database. Riprova più tardi.');
      }

      const { data: leagues, error: fetchError } = await supabase
        .from('leagues')
        .select(`
          *,
          teams (
            id,
            name,
            coach:profiles (username)
          )
        `)
        .eq('manager_id', user.id)
        .order('created_at', { ascending: false });

      if (fetchError) {
        console.error('Error fetching leagues:', fetchError);
        throw new Error('Errore nel caricamento delle leghe');
      }

      setLeagues(leagues || []);
    } catch (err: any) {
      console.error('Error in loadLeagues:', err);
      setError(err.message || 'Errore nel caricamento delle leghe');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (leagueId: string) => {
    if (!confirm('Sei sicuro di voler eliminare questa lega?')) return;

    try {
      setDeleting(leagueId);
      setError(null);

      const { error: deleteError } = await supabase
        .from('leagues')
        .delete()
        .eq('id', leagueId);

      if (deleteError) {
        console.error('Error deleting league:', deleteError);
        throw new Error('Errore durante l\'eliminazione della lega');
      }

      await loadLeagues();
    } catch (err: any) {
      console.error('Error in handleDelete:', err);
      setError(err.message || 'Errore durante l\'eliminazione della lega');
    } finally {
      setDeleting(null);
    }
  };

  const handleCreateSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      // Validate form data
      if (!formData.name.trim()) {
        throw new Error('Il nome della lega è obbligatorio');
      }

      if (!formData.registrationDeadline) {
        throw new Error('La data di scadenza è obbligatoria');
      }

      if (formData.matchdays < 2 || formData.matchdays > 76) {
        throw new Error('Il numero di giornate deve essere tra 2 e 76');
      }

      // Format the registration deadline to include time
      const deadline = new Date(formData.registrationDeadline);
      deadline.setHours(23, 59, 59);

      // Check if deadline is in the future
      if (deadline <= new Date()) {
        throw new Error('La data di scadenza deve essere futura');
      }

      const league = await createLeague({
        ...formData,
        registrationDeadline: deadline.toISOString()
      });

      if (league) {
        setShowCreateForm(false);
        loadLeagues();
      }
    } catch (err: any) {
      console.error('Error in handleCreateSubmit:', err);
      setError(err.message || 'Errore durante la creazione della lega');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Le Mie Leghe</h1>
        {!showCreateForm && (user?.role === 'league_manager' || user?.role === 'admin') && (
          <button
            onClick={() => setShowCreateForm(true)}
            className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
          >
            <Plus className="w-5 h-5 mr-2" />
            Crea Nuova Lega
          </button>
        )}
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      {showCreateForm && (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-gray-900">Crea Nuova Lega</h2>
            <button
              onClick={() => setShowCreateForm(false)}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <form onSubmit={handleCreateSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Nome della Lega
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
                minLength={3}
                maxLength={50}
              />
            </div>

            <div>
              <label htmlFor="maxTeams" className="block text-sm font-medium text-gray-700">
                Numero Massimo di Squadre
              </label>
              <input
                type="number"
                id="maxTeams"
                value={formData.maxTeams}
                onChange={(e) => setFormData({ ...formData, maxTeams: parseInt(e.target.value) })}
                min="4"
                max="20"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="matchdays" className="block text-sm font-medium text-gray-700">
                Numero di Giornate
              </label>
              <input
                type="number"
                id="matchdays"
                value={formData.matchdays}
                onChange={(e) => setFormData({ ...formData, matchdays: parseInt(e.target.value) })}
                min="2"
                max="76"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
              <p className="mt-1 text-sm text-gray-500">
                Numero di giornate nel campionato (andata e ritorno)
              </p>
            </div>

            <div>
              <label htmlFor="budget" className="block text-sm font-medium text-gray-700">
                Budget per Squadra (in milioni €)
              </label>
              <input
                type="number"
                id="budget"
                value={formData.budget}
                onChange={(e) => setFormData({ ...formData, budget: parseInt(e.target.value) })}
                min="50"
                max="500"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="scoringSystem" className="block text-sm font-medium text-gray-700">
                Sistema di Punteggio
              </label>
              <select
                id="scoringSystem"
                value={formData.scoringSystem}
                onChange={(e) => setFormData({ ...formData, scoringSystem: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="classic">Classico</option>
                <option value="head2head">Testa a Testa</option>
                <option value="mantra">Mantra</option>
              </select>
            </div>

            <div>
              <label htmlFor="registrationDeadline" className="block text-sm font-medium text-gray-700">
                Scadenza Iscrizioni
              </label>
              <input
                type="date"
                id="registrationDeadline"
                value={formData.registrationDeadline}
                onChange={(e) => setFormData({ ...formData, registrationDeadline: e.target.value })}
                min={minDateString}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => setShowCreateForm(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
              >
                Annulla
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
              >
                Crea Lega
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {leagues.length === 0 ? (
          <div className="col-span-full text-center py-12">
            <Trophy className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna lega trovata</h3>
            <p className="mt-1 text-sm text-gray-500">
              Inizia creando una nuova lega
            </p>
          </div>
        ) : (
          leagues.map((league) => (
            <div key={league.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-bold text-gray-900">{league.name}</h2>
                  <Trophy className="w-6 h-6 text-indigo-600" />
                </div>
                
                <div className="space-y-2">
                  <p className="text-gray-600">
                    <Users className="w-4 h-4 inline mr-2" />
                    {league.teams?.length || 0} / {league.max_teams} squadre
                  </p>
                  <p className="text-gray-600">
                    <Settings className="w-4 h-4 inline mr-2" />
                    Budget: €{league.budget}M
                  </p>
                  <p className="text-gray-600">
                    Sistema: {league.scoring_system}
                  </p>
                </div>

                <div className="mt-6 flex space-x-3">
                  <button
                    onClick={() => navigate(`/leagues/${league.id}/manage`)}
                    className="flex-1 text-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                  >
                    <Edit className="w-4 h-4 inline mr-2" />
                    Gestisci
                  </button>
                  <button
                    onClick={() => handleDelete(league.id)}
                    disabled={deleting === league.id}
                    className="px-4 py-2 text-red-600 hover:text-red-900"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Leghe;