import React, { useEffect, useState } from 'react';
import { Trophy, Users, Calendar, AlertCircle, Loader } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/authStore';
import { format } from 'date-fns';

interface League {
  id: string;
  name: string;
  budget: number;
  max_teams: number;
  scoring_system: string;
  registration_deadline: string;
  manager: {
    username: string;
  };
  teams: {
    id: string;
  }[];
}

const AvailableLeagues: React.FC = () => {
  const [leagues, setLeagues] = useState<League[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [joiningLeague, setJoiningLeague] = useState<string | null>(null);
  const { user } = useAuthStore();

  useEffect(() => {
    loadAvailableLeagues();
  }, []);

  const loadAvailableLeagues = async () => {
    try {
      setLoading(true);
      const { data, error: fetchError } = await supabase
        .from('leagues')
        .select(`
          *,
          manager:profiles(username),
          teams(id)
        `)
        .gt('registration_deadline', new Date().toISOString())
        .order('created_at', { ascending: false });

      if (fetchError) throw fetchError;
      setLeagues(data || []);
    } catch (err) {
      console.error('Error loading leagues:', err);
      setError('Errore nel caricamento delle leghe disponibili');
    } finally {
      setLoading(false);
    }
  };

  const joinLeague = async (leagueId: string) => {
    if (!user) return;
    
    try {
      setJoiningLeague(leagueId);
      const league = leagues.find(l => l.id === leagueId);
      
      if (!league) throw new Error('League not found');
      
      // Check if user already has a team in this league
      const { data: existingTeams, error: teamCheckError } = await supabase
        .from('teams')
        .select('id')
        .eq('league_id', leagueId)
        .eq('coach_id', user.id)
        .limit(1);

      if (teamCheckError) throw teamCheckError;

      if (existingTeams && existingTeams.length > 0) {
        throw new Error('Sei già iscritto a questa lega');
      }

      // Create new team
      const { error: teamError } = await supabase
        .from('teams')
        .insert({
          name: `Team ${user.username}`,
          coach_id: user.id,
          league_id: leagueId,
          budget_remaining: league.budget
        });

      if (teamError) throw teamError;

      // Refresh leagues
      await loadAvailableLeagues();
    } catch (err: any) {
      setError(err.message);
    } finally {
      setJoiningLeague(null);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Leghe Disponibili</h1>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      {leagues.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg shadow">
          <Trophy className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna lega disponibile</h3>
          <p className="mt-1 text-sm text-gray-500">
            Al momento non ci sono leghe aperte per le iscrizioni.
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {leagues.map((league) => {
            const availableSpots = league.max_teams - (league.teams?.length || 0);
            const isFullyBooked = availableSpots <= 0;
            const deadline = new Date(league.registration_deadline);
            const isExpired = deadline < new Date();

            return (
              <div key={league.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                <div className="p-6">
                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-xl font-bold text-gray-900">{league.name}</h2>
                    <Trophy className="w-6 h-6 text-indigo-600" />
                  </div>
                  
                  <div className="space-y-2">
                    <p className="text-gray-600">
                      <Users className="w-4 h-4 inline mr-2" />
                      {league.teams?.length || 0} / {league.max_teams} squadre
                    </p>
                    <p className="text-gray-600">
                      Budget iniziale: €{league.budget}M
                    </p>
                    <p className="text-gray-600">
                      Sistema: {league.scoring_system}
                    </p>
                    <p className="text-gray-600">
                      <Calendar className="w-4 h-4 inline mr-2" />
                      Scadenza: {format(new Date(league.registration_deadline), 'dd/MM/yyyy')}
                    </p>
                    <p className="text-gray-600">
                      Gestore: {league.manager?.username}
                    </p>
                  </div>

                  <div className="mt-6">
                    {isFullyBooked ? (
                      <button
                        disabled
                        className="w-full px-4 py-2 bg-gray-300 text-gray-500 rounded-md cursor-not-allowed"
                      >
                        Lega al completo
                      </button>
                    ) : isExpired ? (
                      <button
                        disabled
                        className="w-full px-4 py-2 bg-gray-300 text-gray-500 rounded-md cursor-not-allowed"
                      >
                        Iscrizioni chiuse
                      </button>
                    ) : (
                      <button
                        onClick={() => joinLeague(league.id)}
                        disabled={joiningLeague === league.id}
                        className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:bg-indigo-300"
                      >
                        {joiningLeague === league.id ? (
                          <span className="flex items-center justify-center">
                            <Loader className="w-4 h-4 mr-2 animate-spin" />
                            Iscrizione in corso...
                          </span>
                        ) : (
                          `Iscriviti (${availableSpots} ${availableSpots === 1 ? 'posto' : 'posti'} disponibili)`
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AvailableLeagues;