import { create } from 'zustand';
import { Player } from '../types';
import { playerService } from '../services/players';

interface PlayerState {
  players: Player[];
  loading: boolean;
  error: string | null;
  fetchPlayers: () => Promise<void>;
}

export const usePlayerStore = create<PlayerState>((set) => ({
  players: [],
  loading: false,
  error: null,
  fetchPlayers: async () => {
    set({ loading: true, error: null });
    try {
      const players = await playerService.getPlayers();
      set({ players, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  }
}));