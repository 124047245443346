import React, { useState, useEffect } from 'react';
import { Book, Plus, AlertCircle, Loader, Edit, Trash2, Save, X } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface Rule {
  id: string;
  category: string;
  title: string;
  description: string;
  level: 'basic' | 'advanced' | 'pro';
  order_index: number;
}

const LeagueRules: React.FC = () => {
  const [rules, setRules] = useState<Rule[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingRule, setEditingRule] = useState<Rule | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const [formData, setFormData] = useState({
    category: '',
    title: '',
    description: '',
    level: 'basic' as Rule['level'],
    order_index: 0
  });

  useEffect(() => {
    loadRules();
  }, []);

  const loadRules = async () => {
    try {
      setLoading(true);
      const { data, error: fetchError } = await supabase
        .from('league_rules')
        .select('*')
        .order('category', { ascending: true })
        .order('order_index', { ascending: true });

      if (fetchError) throw fetchError;
      setRules(data || []);
    } catch (err) {
      console.error('Error loading rules:', err);
      setError('Errore nel caricamento delle regole');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      if (editingRule) {
        const { error: updateError } = await supabase
          .from('league_rules')
          .update({
            ...formData,
            updated_at: new Date().toISOString()
          })
          .eq('id', editingRule.id);

        if (updateError) throw updateError;
      } else {
        const { error: insertError } = await supabase
          .from('league_rules')
          .insert([formData]);

        if (insertError) throw insertError;
      }

      await loadRules();
      setEditingRule(null);
      setIsCreating(false);
      setFormData({
        category: '',
        title: '',
        description: '',
        level: 'basic',
        order_index: 0
      });
    } catch (err: any) {
      console.error('Error saving rule:', err);
      setError(err.message);
    }
  };

  const handleEdit = (rule: Rule) => {
    setEditingRule(rule);
    setFormData({
      category: rule.category,
      title: rule.title,
      description: rule.description,
      level: rule.level,
      order_index: rule.order_index
    });
    setIsCreating(true);
  };

  const handleDelete = async (ruleId: string) => {
    if (!confirm('Sei sicuro di voler eliminare questa regola?')) return;

    try {
      const { error: deleteError } = await supabase
        .from('league_rules')
        .delete()
        .eq('id', ruleId);

      if (deleteError) throw deleteError;
      await loadRules();
    } catch (err: any) {
      console.error('Error deleting rule:', err);
      setError(err.message);
    }
  };

  const groupedRules = rules.reduce((acc, rule) => {
    if (!acc[rule.category]) {
      acc[rule.category] = [];
    }
    acc[rule.category].push(rule);
    return acc;
  }, {} as Record<string, Rule[]>);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold text-gray-900">Gestione Regole</h2>
        <button
          onClick={() => {
            setIsCreating(true);
            setEditingRule(null);
            setFormData({
              category: '',
              title: '',
              description: '',
              level: 'basic',
              order_index: 0
            });
          }}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
        >
          <Plus className="w-5 h-5 mr-2" />
          Nuova Regola
        </button>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      {isCreating && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {editingRule ? 'Modifica Regola' : 'Nuova Regola'}
              </h3>
              <form onSubmit={handleSubmit} className="mt-2 space-y-4">
                <div>
                  <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                    Categoria
                  </label>
                  <input
                    type="text"
                    id="category"
                    value={formData.category}
                    onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                    Titolo
                  </label>
                  <input
                    type="text"
                    id="title"
                    value={formData.title}
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                    Descrizione
                  </label>
                  <textarea
                    id="description"
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    required
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label htmlFor="level" className="block text-sm font-medium text-gray-700">
                    Livello
                  </label>
                  <select
                    id="level"
                    value={formData.level}
                    onChange={(e) => setFormData({ ...formData, level: e.target.value as Rule['level'] })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  >
                    <option value="basic">Base</option>
                    <option value="advanced">Avanzato</option>
                    <option value="pro">Pro</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="order" className="block text-sm font-medium text-gray-700">
                    Ordine
                  </label>
                  <input
                    type="number"
                    id="order"
                    value={formData.order_index}
                    onChange={(e) => setFormData({ ...formData, order_index: parseInt(e.target.value) })}
                    required
                    min="0"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div className="flex justify-end space-x-3 mt-4">
                  <button
                    type="button"
                    onClick={() => {
                      setIsCreating(false);
                      setEditingRule(null);
                    }}
                    className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                  >
                    Annulla
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                  >
                    {editingRule ? 'Salva Modifiche' : 'Crea'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-8">
        {Object.entries(groupedRules).map(([category, categoryRules]) => (
          <div key={category} className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
              <h3 className="text-lg font-medium text-gray-900 flex items-center">
                <Book className="w-5 h-5 mr-2 text-indigo-600" />
                {category}
              </h3>
            </div>
            <div className="divide-y divide-gray-200">
              {categoryRules.map((rule) => (
                <div key={rule.id} className="p-6">
                  <div className="flex justify-between items-start">
                    <div className="flex-1">
                      <h4 className="text-lg font-medium text-gray-900">{rule.title}</h4>
                      <p className="mt-1 text-gray-600">{rule.description}</p>
                      <div className="mt-2">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          rule.level === 'basic' ? 'bg-green-100 text-green-800' :
                          rule.level === 'advanced' ? 'bg-yellow-100 text-yellow-800' :
                          'bg-red-100 text-red-800'
                        }`}>
                          {rule.level === 'basic' ? 'Base' :
                           rule.level === 'advanced' ? 'Avanzato' : 'Pro'}
                        </span>
                      </div>
                    </div>
                    <div className="ml-4 flex space-x-2">
                      <button
                        onClick={() => handleEdit(rule)}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <Edit className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(rule.id)}
                        className="text-red-400 hover:text-red-500"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        {Object.keys(groupedRules).length === 0 && (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <Book className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna regola trovata</h3>
            <p className="mt-1 text-sm text-gray-500">
              Inizia aggiungendo una nuova regola per la tua lega.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeagueRules;