import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Users, Shirt, Calendar, Gavel, ShoppingCart, Radio } from 'lucide-react';

const CoachNavigation: React.FC = () => {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const navItems = [
    {
      path: '/my-players',
      icon: Users,
      label: 'I Miei Giocatori'
    },
    {
      path: '/formation',
      icon: Shirt,
      label: 'Formazione'
    },
    {
      path: '/calendar',
      icon: Calendar,
      label: 'Calendario'
    },
    {
      path: '/calendar/serie-a',
      icon: Calendar,
      label: 'Calendario Serie A'
    },
    {
      path: '/live-auctions',
      icon: Gavel,
      label: 'Aste Live'
    },
    {
      path: '/free-players',
      icon: ShoppingCart,
      label: 'Svincolati'
    },
    {
      path: '/live-match',
      icon: Radio,
      label: 'Live Match'
    }
  ];

  return (
    <nav className="bg-white shadow-sm mb-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between space-x-4 -mb-px overflow-x-auto">
          {navItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`
                flex items-center py-4 px-1 border-b-2 text-sm font-medium whitespace-nowrap
                ${isActive(item.path)
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
              `}
            >
              <item.icon className="w-5 h-5 mr-2" />
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default CoachNavigation;