import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Users, Shield, Trophy, AlertCircle, Loader } from 'lucide-react';
import { supabase } from '../lib/supabase';
import ManagerNavigation from '../components/ManagerNavigation';

interface Team {
  id: string;
  name: string;
  coach: {
    username: string;
  };
  points: number;
  budget_remaining: number;
  players: {
    player: {
      name: string;
      role: string;
      real_team: string;
    };
  }[];
}

const LeagueTeams: React.FC = () => {
  const { id: leagueId } = useParams<{ id: string }>();
  const [teams, setTeams] = useState<Team[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadTeams();
  }, [leagueId]);

  const loadTeams = async () => {
    try {
      setLoading(true);
      const { data, error: fetchError } = await supabase
        .from('teams')
        .select(`
          *,
          coach:profiles(username),
          players:team_players(
            player:players(
              name,
              role,
              real_team
            )
          )
        `)
        .eq('league_id', leagueId)
        .order('points', { ascending: false });

      if (fetchError) throw fetchError;
      setTeams(data || []);
    } catch (err) {
      console.error('Error loading teams:', err);
      setError('Errore nel caricamento delle squadre');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div>
        <ManagerNavigation />
        <div className="flex justify-center items-center h-64">
          <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <ManagerNavigation />
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      </div>
    );
  }

  return (
    <div>
      <ManagerNavigation />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Squadre Partecipanti</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {teams.map((team) => (
                <div key={team.id} className="bg-white border rounded-lg shadow-sm p-6">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center">
                      <Shield className="w-8 h-8 text-indigo-600 mr-3" />
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">{team.name}</h3>
                        <p className="text-sm text-gray-500">Coach: {team.coach.username}</p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Trophy className="w-5 h-5 text-yellow-500 mr-1" />
                      <span className="font-medium">{team.points}</span>
                    </div>
                  </div>

                  <div className="space-y-3">
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Budget Rimanente:</span>
                      <span className="font-medium">{team.budget_remaining}M</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Giocatori:</span>
                      <span className="font-medium">{team.players.length}</span>
                    </div>
                  </div>

                  <div className="mt-4 pt-4 border-t">
                    <h4 className="text-sm font-medium text-gray-900 mb-2">Rosa</h4>
                    <div className="space-y-2">
                      {['P', 'D', 'C', 'A'].map(role => {
                        const rolePlayers = team.players.filter(p => p.player.role === role);
                        return (
                          <div key={role} className="flex items-center text-sm">
                            <span className={`
                              w-6 h-6 rounded-full flex items-center justify-center text-white text-xs mr-2
                              ${role === 'P' ? 'bg-yellow-500' :
                                role === 'D' ? 'bg-blue-500' :
                                role === 'C' ? 'bg-green-500' :
                                'bg-red-500'}
                            `}>
                              {role}
                            </span>
                            <span className="text-gray-600">{rolePlayers.length} giocatori</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeagueTeams;