import { create } from 'zustand';
import { League } from '../types';
import { supabase } from '../lib/supabase';

interface LeagueState {
  leagues: League[];
  loading: boolean;
  error: string | null;
  fetchLeagues: () => Promise<void>;
  createLeague: (data: {
    name: string;
    maxTeams: number;
    budget: number;
    scoringSystem: string;
    registrationDeadline: string;
    matchdays: number;
  }) => Promise<League | null>;
}

export const useLeagueStore = create<LeagueState>((set) => ({
  leagues: [],
  loading: false,
  error: null,
  fetchLeagues: async () => {
    console.log('Fetching leagues...');
    set({ loading: true, error: null });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      console.log('Current user:', user?.id);
      
      if (!user) {
        console.error('No user found during fetchLeagues');
        throw new Error('User not authenticated');
      }

      const { data: leagues, error } = await supabase
        .from('leagues')
        .select(`
          *,
          manager:profiles(username),
          teams:teams(
            id,
            name,
            coach:profiles(username),
            points
          )
        `)
        .eq('manager_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching leagues:', error);
        throw error;
      }

      console.log('Fetched leagues:', leagues?.length);
      set({ leagues: leagues || [], loading: false });
    } catch (error: any) {
      console.error('Error in fetchLeagues:', error);
      set({ error: error.message, loading: false });
    }
  },
  createLeague: async (data) => {
    console.log('Creating league with data:', data);
    set({ loading: true, error: null });
    try {
      // Get current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      console.log('Auth check - User:', user?.id);
      
      if (userError) {
        console.error('Auth error:', userError);
        throw userError;
      }
      if (!user) {
        console.error('No user found during league creation');
        throw new Error('User not authenticated');
      }

      // Get user profile to check role
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('role')
        .eq('id', user.id)
        .single();

      console.log('Profile check - Role:', profile?.role);

      if (profileError) {
        console.error('Profile error:', profileError);
        throw profileError;
      }
      if (!profile) {
        console.error('No profile found');
        throw new Error('Profile not found');
      }

      // Check if user has permission to create leagues
      if (profile.role !== 'league_manager' && profile.role !== 'admin') {
        console.error('Invalid role for league creation:', profile.role);
        throw new Error('Non hai i permessi per creare una lega');
      }

      // Check maximum leagues limit
      const { data: leagueCount, error: countError } = await supabase
        .from('leagues')
        .select('id', { count: 'exact' })
        .eq('manager_id', user.id);

      if (countError) {
        console.error('Error checking league count:', countError);
        throw countError;
      }

      const maxLeagues = profile.role === 'admin' ? Infinity : 5;
      if ((leagueCount?.length || 0) >= maxLeagues) {
        console.error('Maximum leagues limit reached');
        throw new Error(`Hai raggiunto il limite massimo di ${maxLeagues} leghe`);
      }

      // Format the registration deadline to include time
      const deadline = new Date(data.registrationDeadline);
      deadline.setHours(23, 59, 59);

      console.log('Creating league...');
      
      // Create the league
      const { data: league, error: createError } = await supabase
        .from('leagues')
        .insert({
          name: data.name,
          max_teams: data.maxTeams,
          budget: data.budget,
          scoring_system: data.scoringSystem,
          registration_deadline: deadline.toISOString(),
          manager_id: user.id,
          matchdays: data.matchdays
        })
        .select()
        .single();

      if (createError) {
        console.error('League creation error:', createError);
        throw createError;
      }

      console.log('League created successfully:', league);

      // Create match sync settings for the new league
      const { error: syncError } = await supabase
        .from('match_sync_settings')
        .insert({
          league_id: league.id,
          sync_enabled: true,
          time_offset: '00:00',
          auto_complete: false
        });

      if (syncError) {
        console.error('Match sync settings error:', syncError);
        throw syncError;
      }

      // Update local state
      set((state) => ({
        leagues: [league, ...state.leagues],
        loading: false
      }));

      return league;
    } catch (error: any) {
      console.error('Error in createLeague:', error);
      set({ 
        error: error.message || 'Errore durante la creazione della lega',
        loading: false 
      });
      return null;
    }
  }
}));