import React, { useState, useEffect } from 'react';
import { Calendar as CalendarIcon, Clock, Trophy, AlertCircle, Loader } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/authStore';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import CoachNavigation from '../components/CoachNavigation';

interface Team {
  id: string;
  name: string;
  league: {
    id: string;
    name: string;
  };
}

interface Match {
  id: string;
  matchday: number;
  home_team: {
    id: string;
    name: string;
  };
  away_team: {
    id: string;
    name: string;
  };
  home_score: number | null;
  away_score: number | null;
  status: 'scheduled' | 'in_progress' | 'completed';
  played_at: string | null;
}

const Calendar: React.FC = () => {
  const { user } = useAuthStore();
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  const [matches, setMatches] = useState<Match[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      loadTeams();
    }
  }, [user]);

  useEffect(() => {
    if (selectedTeam) {
      loadMatches();
    }
  }, [selectedTeam]);

  const loadTeams = async () => {
    try {
      setLoading(true);
      const { data: userTeams, error: teamsError } = await supabase
        .from('teams')
        .select(`
          id,
          name,
          league:leagues(
            id,
            name
          )
        `)
        .eq('coach_id', user?.id);

      if (teamsError) throw teamsError;

      if (userTeams && userTeams.length > 0) {
        setTeams(userTeams);
        setSelectedTeam(userTeams[0].id);
      }
    } catch (err) {
      console.error('Error loading teams:', err);
      setError('Errore nel caricamento delle squadre');
    } finally {
      setLoading(false);
    }
  };

  const loadMatches = async () => {
    if (!selectedTeam) return;

    try {
      setLoading(true);
      const { data: matchesData, error: matchesError } = await supabase
        .from('matches')
        .select(`
          id,
          matchday,
          home_team:teams!home_team_id(id, name),
          away_team:teams!away_team_id(id, name),
          home_score,
          away_score,
          status,
          played_at
        `)
        .or(`home_team_id.eq.${selectedTeam},away_team_id.eq.${selectedTeam}`)
        .order('matchday', { ascending: true });

      if (matchesError) throw matchesError;
      setMatches(matchesData || []);
    } catch (err) {
      console.error('Error loading matches:', err);
      setError('Errore nel caricamento delle partite');
    } finally {
      setLoading(false);
    }
  };

  const getMatchStatus = (match: Match) => {
    switch (match.status) {
      case 'completed':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            Completata
          </span>
        );
      case 'in_progress':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            In Corso
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            Programmata
          </span>
        );
    }
  };

  if (loading && teams.length === 0) {
    return (
      <div>
        <CoachNavigation />
        <div className="flex justify-center items-center h-64">
          <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <CoachNavigation />
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      </div>
    );
  }

  if (teams.length === 0) {
    return (
      <div>
        <CoachNavigation />
        <div className="text-center py-12">
          <Trophy className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna squadra trovata</h3>
          <p className="mt-1 text-sm text-gray-500">
            Non hai ancora creato una squadra.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <CoachNavigation />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
              <h2 className="text-2xl font-bold text-gray-900">Calendario Partite</h2>
              
              <select
                className="block w-full sm:w-auto rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={selectedTeam || ''}
                onChange={(e) => setSelectedTeam(e.target.value)}
              >
                {teams.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.name} ({team.league.name})
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-4">
              {matches.length === 0 ? (
                <div className="text-center py-12">
                  <CalendarIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna partita trovata</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Non ci sono partite programmate per questa squadra.
                  </p>
                </div>
              ) : (
                matches.map((match) => (
                  <div
                    key={match.id}
                    className="bg-white border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex-1">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <Trophy className="w-5 h-5 text-yellow-500" />
                            <span className="font-medium text-gray-900">{match.home_team.name}</span>
                          </div>
                          <div className="font-bold text-2xl text-gray-900 px-4">
                            {match.status === 'completed' ? 
                              `${match.home_score} - ${match.away_score}` : 
                              'vs'
                            }
                          </div>
                          <div className="flex items-center space-x-2">
                            <span className="font-medium text-gray-900">{match.away_team.name}</span>
                            <Trophy className="w-5 h-5 text-yellow-500" />
                          </div>
                        </div>
                      </div>
                      
                      <div className="ml-4 flex flex-col items-end">
                        <div className="flex items-center text-sm text-gray-500 mb-2">
                          <Clock className="w-4 h-4 mr-1" />
                          {match.played_at ? 
                            format(new Date(match.played_at), 'dd MMM yyyy', { locale: it }) :
                            'Data da definire'
                          }
                        </div>
                        {getMatchStatus(match)}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;