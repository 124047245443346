import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Settings, Mail, Copy, Check, AlertCircle, Loader, Search, Filter, Clock, Gavel, Trophy } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/authStore';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/locale';
import ManagerNavigation from '../components/ManagerNavigation';

interface Player {
  id: string;
  name: string;
  role: string;
  real_team: string;
  base_value: number;
}

interface Auction {
  id: string;
  player_id: string;
  current_bid: number;
  highest_bidder_id: string | null;
  highest_bidder?: {
    username: string;
  };
  ends_at: string;
  status: 'active' | 'completed' | 'pending';
  player: {
    name: string;
    role: string;
    real_team: string;
    base_value: number;
  };
  winner?: {
    username: string;
    team: {
      name: string;
    };
  };
}

const LiveAuction: React.FC = () => {
  const { id: leagueId } = useParams<{ id: string }>();
  const { user } = useAuthStore();
  const [players, setPlayers] = useState<Player[]>([]);
  const [filteredPlayers, setFilteredPlayers] = useState<Player[]>([]);
  const [activeAuctions, setActiveAuctions] = useState<Auction[]>([]);
  const [completedAuctions, setCompletedAuctions] = useState<Auction[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [defaultTimer, setDefaultTimer] = useState<number>(300);
  
  // Filters
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState<string>('all');
  const [teamFilter, setTeamFilter] = useState<string>('all');
  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(100);

  useEffect(() => {
    if (leagueId) {
      loadPlayers();
      loadAuctions();

      // Subscribe to auction changes
      const channel = supabase
        .channel('auction_changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'auctions',
            filter: `league_id=eq.${leagueId}`
          },
          () => {
            loadAuctions();
            loadPlayers();
          }
        )
        .subscribe();

      // Set up periodic refresh
      const refreshInterval = setInterval(() => {
        loadAuctions();
        loadPlayers();
      }, 10000);

      return () => {
        channel.unsubscribe();
        clearInterval(refreshInterval);
      };
    }
  }, [leagueId]);

  useEffect(() => {
    filterPlayers();
  }, [players, searchTerm, roleFilter, teamFilter, minValue, maxValue]);

  const loadPlayers = async () => {
    if (!leagueId) return;

    try {
      setLoading(true);
      setError(null);

      // Get all player IDs that are already in teams
      const { data: teamPlayers, error: teamPlayersError } = await supabase
        .from('team_players')
        .select('player_id')
        .eq('team_id', leagueId);

      if (teamPlayersError) throw teamPlayersError;

      const assignedPlayerIds = teamPlayers?.map(tp => tp.player_id) || [];

      // Get all players that are in active auctions
      const { data: activePlayers, error: activeAuctionsError } = await supabase
        .from('auctions')
        .select('player_id')
        .eq('league_id', leagueId)
        .in('status', ['pending', 'active']);

      if (activeAuctionsError) throw activeAuctionsError;

      const activePlayerIds = activePlayers?.map(ap => ap.player_id) || [];

      // Get all available players
      let query = supabase
        .from('players')
        .select('*')
        .order('role');

      // Filter out assigned and active auction players
      const excludedPlayerIds = [...new Set([...assignedPlayerIds, ...activePlayerIds])];
      if (excludedPlayerIds.length > 0) {
        query = query.not('id', 'in', `(${excludedPlayerIds.join(',')})`);
      }

      const { data: playersData, error: playersError } = await query;

      if (playersError) throw playersError;

      setPlayers(playersData || []);
      setFilteredPlayers(playersData || []);
    } catch (err) {
      console.error('Error loading players:', err);
      setError('Errore nel caricamento dei giocatori');
    } finally {
      setLoading(false);
    }
  };

  const loadAuctions = async () => {
    if (!leagueId) return;

    try {
      // Load active auctions
      const { data: activeData, error: activeError } = await supabase
        .from('auctions')
        .select(`
          *,
          player:players(*),
          highest_bidder:profiles(username)
        `)
        .eq('league_id', leagueId)
        .in('status', ['active', 'pending'])
        .order('created_at', { ascending: false });

      if (activeError) throw activeError;

      // Load completed auctions
      const { data: completedData, error: completedError } = await supabase
        .from('auctions')
        .select(`
          *,
          player:players(*),
          winner:profiles!auctions_highest_bidder_id_fkey(
            username,
            team:teams(name)
          )
        `)
        .eq('league_id', leagueId)
        .eq('status', 'completed')
        .order('updated_at', { ascending: false })
        .limit(10);

      if (completedError) throw completedError;

      setActiveAuctions(activeData || []);
      setCompletedAuctions(completedData || []);
    } catch (err) {
      console.error('Error loading auctions:', err);
    }
  };

  const filterPlayers = () => {
    if (!players.length) return;

    let filtered = [...players];

    if (searchTerm) {
      filtered = filtered.filter(player =>
        player.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        player.real_team.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (roleFilter !== 'all') {
      filtered = filtered.filter(player => player.role === roleFilter);
    }

    if (teamFilter !== 'all') {
      filtered = filtered.filter(player => player.real_team === teamFilter);
    }

    filtered = filtered.filter(player =>
      player.base_value >= minValue && player.base_value <= maxValue
    );

    setFilteredPlayers(filtered);
  };

  const startAuction = async (player: Player) => {
    if (!leagueId) return;
    
    try {
      setError(null);
      
      // Create auction record
      const { error: auctionError } = await supabase
        .from('auctions')
        .insert({
          league_id: leagueId,
          player_id: player.id,
          current_bid: player.base_value,
          ends_at: new Date(Date.now() + (defaultTimer * 1000)).toISOString(),
          status: 'active'
        });

      if (auctionError) throw auctionError;

      // Refresh players list
      await loadPlayers();
      await loadAuctions();
    } catch (err: any) {
      console.error('Error starting auction:', err);
      setError(err.message);
    }
  };

  const cancelAuction = async (auctionId: string) => {
    try {
      setError(null);

      const { error } = await supabase
        .from('auctions')
        .delete()
        .eq('id', auctionId)
        .eq('status', 'active')
        .is('highest_bidder_id', null);

      if (error) throw error;

      await loadAuctions();
      await loadPlayers();
    } catch (err: any) {
      console.error('Error canceling auction:', err);
      setError(err.message);
    }
  };

  if (loading && !players.length) {
    return (
      <div>
        <ManagerNavigation />
        <div className="flex justify-center items-center h-64">
          <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <ManagerNavigation />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900">Gestione Asta Live</h2>
              
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-2">
                  <Clock className="w-5 h-5 text-gray-400" />
                  <input
                    type="number"
                    value={defaultTimer}
                    onChange={(e) => setDefaultTimer(parseInt(e.target.value))}
                    min="10"
                    max="3600"
                    className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                  <span className="text-sm text-gray-500">secondi</span>
                </div>
              </div>
            </div>

            {error && (
              <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
                <AlertCircle className="w-5 h-5 mr-2" />
                {error}
              </div>
            )}

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {/* Active and Completed Auctions */}
              <div className="space-y-6">
                {/* Active Auctions */}
                <div className="bg-white rounded-lg shadow">
                  <div className="p-6">
                    <h2 className="text-lg font-medium text-gray-900 mb-4">Aste Attive</h2>
                    
                    {activeAuctions.length === 0 ? (
                      <div className="text-center py-12">
                        <Gavel className="mx-auto h-12 w-12 text-gray-400" />
                        <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna asta attiva</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Seleziona un giocatore dalla lista per iniziare un'asta
                        </p>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        {activeAuctions.map(auction => (
                          <div
                            key={auction.id}
                            className="bg-white border rounded-lg p-4 shadow-sm"
                          >
                            <div className="flex justify-between items-start">
                              <div>
                                <h3 className="font-medium text-gray-900">{auction.player.name}</h3>
                                <p className="text-sm text-gray-500">{auction.player.real_team}</p>
                              </div>
                              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                auction.player.role === 'P' ? 'bg-yellow-100 text-yellow-800' :
                                auction.player.role === 'D' ? 'bg-blue-100 text-blue-800' :
                                auction.player.role === 'C' ? 'bg-green-100 text-green-800' :
                                'bg-red-100 text-red-800'
                              }`}>
                                {auction.player.role}
                              </span>
                            </div>

                            <div className="mt-2 grid grid-cols-2 gap-4">
                              <div>
                                <p className="text-sm text-gray-500">Offerta attuale</p>
                                <p className="text-lg font-semibold text-indigo-600">
                                  {auction.current_bid}M
                                </p>
                              </div>
                              <div>
                                <p className="text-sm text-gray-500">Miglior offerente</p>
                                <p className="text-lg font-semibold text-gray-900">
                                  {auction.highest_bidder?.username || '-'}
                                </p>
                              </div>
                            </div>

                            <div className="mt-2">
                              <p className="text-sm text-gray-500 flex items-center">
                                <Clock className="w-4 h-4 mr-1" />
                                Termina {formatDistanceToNow(new Date(auction.ends_at), { 
                                  addSuffix: true,
                                  locale: it 
                                })}
                              </p>
                            </div>

                            {!auction.highest_bidder_id && (
                              <div className="mt-4">
                                <button
                                  onClick={() => cancelAuction(auction.id)}
                                  className="text-red-600 hover:text-red-800 text-sm font-medium"
                                >
                                  Annulla asta
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* Completed Auctions */}
                <div className="bg-white rounded-lg shadow">
                  <div className="p-6">
                    <h2 className="text-lg font-medium text-gray-900 mb-4">Ultime Aste Completate</h2>
                    
                    {completedAuctions.length === 0 ? (
                      <div className="text-center py-6">
                        <p className="text-sm text-gray-500">Nessuna asta completata</p>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        {completedAuctions.map(auction => (
                          <div
                            key={auction.id}
                            className="bg-gray-50 border rounded-lg p-4"
                          >
                            <div className="flex justify-between items-start">
                              <div>
                                <h3 className="font-medium text-gray-900">{auction.player.name}</h3>
                                <p className="text-sm text-gray-500">{auction.player.real_team}</p>
                              </div>
                              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                auction.player.role === 'P' ? 'bg-yellow-100 text-yellow-800' :
                                auction.player.role === 'D' ? 'bg-blue-100 text-blue-800' :
                                auction.player.role === 'C' ? 'bg-green-100 text-green-800' :
                                'bg-red-100 text-red-800'
                              }`}>
                                {auction.player.role}
                              </span>
                            </div>

                            <div className="mt-2 space-y-1">
                              <div className="flex items-center text-sm">
                                <Trophy className="w-4 h-4 text-green-500 mr-2" />
                                <span className="text-gray-600">Vinto da:</span>
                                <span className="font-medium text-gray-900 ml-1">
                                  {auction.winner?.username} ({auction.winner?.team?.name})
                                </span>
                              </div>
                              <p className="text-sm text-gray-500">
                                Prezzo finale: <span className="font-medium text-gray-900">{auction.current_bid}M</span>
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Available Players */}
              <div className="bg-white rounded-lg shadow">
                <div className="p-6">
                  <div className="mb-6 space-y-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-lg font-medium text-gray-900">Giocatori Disponibili</h2>
                      <span className="text-sm text-gray-500">
                        {filteredPlayers.length} giocatori
                      </span>
                    </div>

                    {/* Filters */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="Cerca giocatore..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="w-full pl-10 pr-4 py-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                      </div>

                      <select
                        value={roleFilter}
                        onChange={(e) => setRoleFilter(e.target.value)}
                        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        <option value="all">Tutti i ruoli</option>
                        <option value="P">Portieri</option>
                        <option value="D">Difensori</option>
                        <option value="C">Centrocampisti</option>
                        <option value="A">Attaccanti</option>
                      </select>
                    </div>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Giocatore
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Ruolo
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Squadra
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Base
                          </th>
                          <th className="px-6 py-3 bg-gray-50"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {loading ? (
                          <tr>
                            <td colSpan={5} className="px-6 py-4 text-center">
                              <div className="flex justify-center">
                                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-indigo-600"></div>
                              </div>
                            </td>
                          </tr>
                        ) : filteredPlayers.length === 0 ? (
                          <tr>
                            <td colSpan={5} className="px-6 py-4 text-center text-gray-500">
                              Nessun giocatore disponibile
                            </td>
                          </tr>
                        ) : (
                          filteredPlayers.map((player) => (
                            <tr key={player.id} className="hover:bg-gray-50">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {player.name}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                  player.role === 'P' ? 'bg-yellow-100 text-yellow-800' :
                                  player.role === 'D' ? 'bg-blue-100 text-blue-800' :
                                  player.role === 'C' ? 'bg-green-100 text-green-800' :
                                  'bg-red-100 text-red-800'
                                }`}>
                                  {player.role}
                                </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {player.real_team}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {player.base_value}M
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <button
                                  onClick={() => startAuction(player)}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  Avvia Asta
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveAuction;