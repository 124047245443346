import React, { useState, useEffect } from 'react';
import { Settings, Save, AlertCircle, Loader } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface LeagueSetting {
  id: string;
  name: string;
  value: any;
  description: string;
  category: string;
}

const LeagueSettings: React.FC = () => {
  const [settings, setSettings] = useState<LeagueSetting[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      setLoading(true);
      const { data, error: fetchError } = await supabase
        .from('league_settings')
        .select('*')
        .order('category', { ascending: true });

      if (fetchError) throw fetchError;

      // Deduplicate settings based on name
      const uniqueSettings = data?.reduce((acc: LeagueSetting[], current) => {
        const exists = acc.find(item => item.name === current.name);
        if (!exists) {
          acc.push(current);
        }
        return acc;
      }, []) || [];

      setSettings(uniqueSettings);
    } catch (err) {
      console.error('Error loading settings:', err);
      setError('Errore nel caricamento delle impostazioni');
    } finally {
      setLoading(false);
    }
  };

  const handleSettingChange = (settingId: string, value: any) => {
    setSettings(prevSettings =>
      prevSettings.map(setting =>
        setting.id === settingId
          ? { ...setting, value }
          : setting
      )
    );
  };

  const handleSaveSettings = async () => {
    try {
      setSaving(true);
      setError(null);
      setSuccess(null);

      for (const setting of settings) {
        const { error: updateError } = await supabase
          .from('league_settings')
          .update({
            value: setting.value,
            updated_at: new Date().toISOString()
          })
          .eq('id', setting.id);

        if (updateError) throw updateError;
      }

      setSuccess('Impostazioni salvate con successo');
    } catch (err: any) {
      console.error('Error saving settings:', err);
      setError(err.message);
    } finally {
      setSaving(false);
    }
  };

  const renderSettingInput = (setting: LeagueSetting) => {
    switch (setting.name) {
      case 'free_players_market':
        return (
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id={`market-enabled-${setting.id}`}
                checked={setting.value.enabled}
                onChange={(e) => handleSettingChange(setting.id, {
                  ...setting.value,
                  enabled: e.target.checked
                })}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor={`market-enabled-${setting.id}`} className="ml-2 block text-sm text-gray-900">
                Abilita mercato svincolati
              </label>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Tipo di pianificazione
              </label>
              <select
                value={setting.value.type}
                onChange={(e) => handleSettingChange(setting.id, {
                  ...setting.value,
                  type: e.target.value
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="always">Sempre attivo</option>
                <option value="scheduled">Pianificato</option>
              </select>
            </div>

            {setting.value.type === 'scheduled' && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Data inizio
                  </label>
                  <input
                    type="date"
                    value={setting.value.start_date || ''}
                    onChange={(e) => handleSettingChange(setting.id, {
                      ...setting.value,
                      start_date: e.target.value
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Data fine
                  </label>
                  <input
                    type="date"
                    value={setting.value.end_date || ''}
                    onChange={(e) => handleSettingChange(setting.id, {
                      ...setting.value,
                      end_date: e.target.value
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </>
            )}

            <div className="space-y-4 border-t pt-4 mt-4">
              <h4 className="font-medium text-gray-900">Restrizioni</h4>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Minimo giocatori per squadra
                </label>
                <input
                  type="number"
                  value={setting.value.restrictions?.min_team_players || 18}
                  onChange={(e) => handleSettingChange(setting.id, {
                    ...setting.value,
                    restrictions: {
                      ...setting.value.restrictions,
                      min_team_players: parseInt(e.target.value)
                    }
                  })}
                  min="11"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Massimo giocatori per squadra
                </label>
                <input
                  type="number"
                  value={setting.value.restrictions?.max_team_players || 25}
                  onChange={(e) => handleSettingChange(setting.id, {
                    ...setting.value,
                    restrictions: {
                      ...setting.value.restrictions,
                      max_team_players: parseInt(e.target.value)
                    }
                  })}
                  min={setting.value.restrictions?.min_team_players || 18}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Budget minimo richiesto
                </label>
                <input
                  type="number"
                  value={setting.value.restrictions?.min_budget || 0}
                  onChange={(e) => handleSettingChange(setting.id, {
                    ...setting.value,
                    restrictions: {
                      ...setting.value.restrictions,
                      min_budget: parseInt(e.target.value)
                    }
                  })}
                  min="0"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        );

      case 'auction_system':
        return (
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id={`auction-enabled-${setting.id}`}
                checked={setting.value.enabled}
                onChange={(e) => handleSettingChange(setting.id, {
                  ...setting.value,
                  enabled: e.target.checked
                })}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor={`auction-enabled-${setting.id}`} className="ml-2 block text-sm text-gray-900">
                Abilita sistema d'asta
              </label>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Durata predefinita asta (secondi)
              </label>
              <input
                type="number"
                value={setting.value.default_duration}
                onChange={(e) => handleSettingChange(setting.id, {
                  ...setting.value,
                  default_duration: parseInt(e.target.value)
                })}
                min={setting.value.min_duration}
                max={setting.value.max_duration}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        );

      case 'auction_schedule':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Tipo di pianificazione
              </label>
              <select
                value={setting.value.type}
                onChange={(e) => handleSettingChange(setting.id, {
                  ...setting.value,
                  type: e.target.value
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="always">Sempre attive</option>
                <option value="scheduled">Pianificate</option>
              </select>
            </div>

            {setting.value.type === 'scheduled' && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Data inizio
                  </label>
                  <input
                    type="date"
                    value={setting.value.start_date || ''}
                    onChange={(e) => handleSettingChange(setting.id, {
                      ...setting.value,
                      start_date: e.target.value
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Data fine
                  </label>
                  <input
                    type="date"
                    value={setting.value.end_date || ''}
                    onChange={(e) => handleSettingChange(setting.id, {
                      ...setting.value,
                      end_date: e.target.value
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </>
            )}
          </div>
        );

      case 'auction_restrictions':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Numero massimo di aste attive
              </label>
              <input
                type="number"
                value={setting.value.max_active_auctions}
                onChange={(e) => handleSettingChange(setting.id, {
                  ...setting.value,
                  max_active_auctions: parseInt(e.target.value)
                })}
                min="1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Incremento minimo offerta
              </label>
              <input
                type="number"
                value={setting.value.min_bid_increment}
                onChange={(e) => handleSettingChange(setting.id, {
                  ...setting.value,
                  min_bid_increment: parseInt(e.target.value)
                })}
                min="1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        );

      case 'league_defaults':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Numero massimo squadre
              </label>
              <input
                type="number"
                value={setting.value.max_teams}
                onChange={(e) => handleSettingChange(setting.id, {
                  ...setting.value,
                  max_teams: parseInt(e.target.value)
                })}
                min="4"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Budget iniziale
              </label>
              <input
                type="number"
                value={setting.value.budget}
                onChange={(e) => handleSettingChange(setting.id, {
                  ...setting.value,
                  budget: parseInt(e.target.value)
                })}
                min="100"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Sistema di punteggio
              </label>
              <select
                value={setting.value.scoring_system}
                onChange={(e) => handleSettingChange(setting.id, {
                  ...setting.value,
                  scoring_system: e.target.value
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="classic">Classico</option>
                <option value="head2head">Testa a Testa</option>
                <option value="mantra">Mantra</option>
              </select>
            </div>
          </div>
        );

      case 'team_restrictions':
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Minimo giocatori
                </label>
                <input
                  type="number"
                  value={setting.value.min_players}
                  onChange={(e) => handleSettingChange(setting.id, {
                    ...setting.value,
                    min_players: parseInt(e.target.value)
                  })}
                  min="11"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Massimo giocatori
                </label>
                <input
                  type="number"
                  value={setting.value.max_players}
                  onChange={(e) => handleSettingChange(setting.id, {
                    ...setting.value,
                    max_players: parseInt(e.target.value)
                  })}
                  min={setting.value.min_players}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-3">Limiti per ruolo</h4>
              <div className="space-y-4">
                {Object.entries(setting.value.required_roles).map(([role, limits]: [string, any]) => (
                  <div key={role} className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Min {role === 'P' ? 'Portieri' : role === 'D' ? 'Difensori' : role === 'C' ? 'Centrocampisti' : 'Attaccanti'}
                      </label>
                      <input
                        type="number"
                        value={limits.min}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          handleSettingChange(setting.id, {
                            ...setting.value,
                            required_roles: {
                              ...setting.value.required_roles,
                              [role]: { ...limits, min: newValue }
                            }
                          });
                        }}
                        min="1"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Max {role === 'P' ? 'Portieri' : role === 'D' ? 'Difensori' : role === 'C' ? 'Centrocampisti' : 'Attaccanti'}
                      </label>
                      <input
                        type="number"
                        value={limits.max}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          handleSettingChange(setting.id, {
                            ...setting.value,
                            required_roles: {
                              ...setting.value.required_roles,
                              [role]: { ...limits, max: newValue }
                            }
                          });
                        }}
                        min={limits.min}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className="text-sm text-gray-500">
            Impostazione non supportata
          </div>
        );
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  // Group settings by category and ensure uniqueness
  const groupedSettings = settings.reduce((acc, setting) => {
    if (!acc[setting.category]) {
      acc[setting.category] = [];
    }
    // Only add if not already present
    if (!acc[setting.category].find(s => s.name === setting.name)) {
      acc[setting.category].push(setting);
    }
    return acc;
  }, {} as Record<string, LeagueSetting[]>);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold text-gray-900">Impostazioni Leghe</h2>
        <button
          onClick={handleSaveSettings}
          disabled={saving}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center disabled:bg-indigo-300"
        >
          {saving ? (
            <Loader className="w-4 h-4 mr-2 animate-spin" />
          ) : (
            <Save className="w-4 h-4 mr-2" />
          )}
          Salva Modifiche
        </button>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-50 border border-green-200 text-green-600 px-4 py-3 rounded flex items-center">
          <Save className="w-5 h-5 mr-2" />
          {success}
        </div>
      )}

      <div className="space-y-8">
        {Object.entries(groupedSettings).map(([category, categorySettings]) => (
          <div key={category} className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
              <h3 className="text-lg font-medium text-gray-900 flex items-center">
                <Settings className="w-5 h-5 mr-2 text-indigo-600" />
                {category === 'auctions' ? 'Impostazioni Aste' :
                 category === 'leagues' ? 'Impostazioni Leghe' :
                 category === 'teams' ? 'Impostazioni Squadre' :
                 category}
              </h3>
            </div>
            <div className="divide-y divide-gray-200">
              {categorySettings.map((setting) => (
                <div key={setting.id} className="p-6">
                  <div className="mb-4">
                    <h4 className="text-lg font-medium text-gray-900">{setting.description}</h4>
                  </div>
                  {renderSettingInput(setting)}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeagueSettings;