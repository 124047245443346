import React, { useState, useEffect } from 'react';
import { Users, Trophy, ArrowRight, Shirt, Loader } from 'lucide-react';
import { useTeamStore } from '../store/teamStore';
import { usePlayerStore } from '../store/playerStore';
import { Player } from '../types';

const TeamManagement: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'roster'|'formation'|'transfers'>('roster');
  const [selectedFormation, setSelectedFormation] = useState('4-3-3');
  const { currentTeam, loading: teamLoading, error: teamError, fetchMyTeams } = useTeamStore();
  const { players, loading: playersLoading, error: playersError, fetchPlayers } = usePlayerStore();
  
  useEffect(() => {
    fetchMyTeams();
    fetchPlayers();
  }, [fetchMyTeams, fetchPlayers]);

  const formations = ['4-3-3', '4-4-2', '3-5-2', '3-4-3'];

  if (teamLoading || playersLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  if (teamError || playersError) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded">
        {teamError || playersError}
      </div>
    );
  }

  if (!currentTeam) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Nessuna squadra trovata</h2>
        <p className="text-gray-600">Non hai ancora creato una squadra.</p>
      </div>
    );
  }

  const renderPlayerCard = (player: Player, isDraggable: boolean = true) => (
    <div
      key={player.id}
      draggable={isDraggable}
      className="bg-white p-3 rounded-lg shadow-sm hover:shadow-md transition-shadow"
    >
      <div className="flex items-center space-x-3">
        <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white ${
          player.role === 'P' ? 'bg-yellow-500' :
          player.role === 'D' ? 'bg-blue-500' :
          player.role === 'C' ? 'bg-green-500' :
          'bg-red-500'
        }`}>
          {player.role}
        </div>
        <div>
          <div className="font-medium text-gray-900">{player.name}</div>
          <div className="text-sm text-gray-500">{player.team}</div>
        </div>
      </div>
      <div className="mt-2 grid grid-cols-3 gap-2 text-sm text-gray-600">
        <div>
          <span className="font-medium">{player.stats.goals}</span> gol
        </div>
        <div>
          <span className="font-medium">{player.stats.assists}</span> assist
        </div>
        <div>
          <span className="font-medium">{player.stats.averageRating?.toFixed(2) || '-'}</span> media
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">{currentTeam.name}</h1>
        <div className="flex items-center space-x-4">
          <span className="text-gray-600">
            <Trophy className="w-4 h-4 inline mr-1" />
            {currentTeam.points} punti
          </span>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="border-b">
          <nav className="flex -mb-px">
            <button
              onClick={() => setActiveTab('roster')}
              className={`py-4 px-6 text-sm font-medium ${
                activeTab === 'roster'
                  ? 'border-b-2 border-indigo-500 text-indigo-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Rosa
            </button>
            <button
              onClick={() => setActiveTab('formation')}
              className={`py-4 px-6 text-sm font-medium ${
                activeTab === 'formation'
                  ? 'border-b-2 border-indigo-500 text-indigo-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Formazione
            </button>
            <button
              onClick={() => setActiveTab('transfers')}
              className={`py-4 px-6 text-sm font-medium ${
                activeTab === 'transfers'
                  ? 'border-b-2 border-indigo-500 text-indigo-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Trasferimenti
            </button>
          </nav>
        </div>

        <div className="p-6">
          {activeTab === 'roster' && (
            <div className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {['P', 'D', 'C', 'A'].map((role) => (
                  <div key={role} className="bg-gray-50 rounded-lg p-4">
                    <h3 className="font-semibold text-gray-700 mb-2">
                      {role === 'P' ? 'Portieri' :
                       role === 'D' ? 'Difensori' :
                       role === 'C' ? 'Centrocampisti' : 'Attaccanti'}
                    </h3>
                    <div className="space-y-2">
                      {currentTeam.players
                        .filter((p) => p.role === role)
                        .map((player) => renderPlayerCard(player, false))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === 'formation' && (
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <select
                  value={selectedFormation}
                  onChange={(e) => setSelectedFormation(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  {formations.map((f) => (
                    <option key={f} value={f}>{f}</option>
                  ))}
                </select>
                <button 
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                  onClick={() => {
                    // TODO: Save formation
                    console.log('Save formation:', selectedFormation);
                  }}
                >
                  Salva Formazione
                </button>
              </div>

              <div className="relative h-[600px] bg-green-800 rounded-lg overflow-hidden">
                <div className="absolute inset-0 flex flex-col justify-between p-8">
                  <div className="border-2 border-white/20 h-full rounded-lg relative">
                    {/* Formation grid will be dynamically generated based on selectedFormation */}
                    <div className="absolute inset-0 grid grid-rows-4">
                      {/* Rows will be populated based on formation */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="font-semibold text-gray-700 mb-2">Panchina</h3>
                <div className="grid grid-cols-8 gap-2">
                  {currentTeam.players.slice(0, 8).map((player) => (
                    <div key={player.id} className="bg-white p-2 rounded shadow-sm text-center">
                      <Shirt className="w-6 h-6 mx-auto text-gray-400" />
                      <span className="text-sm">{player.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {activeTab === 'transfers' && (
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-gray-900">Mercato</h2>
                <button className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700">
                  Cerca Giocatori
                </button>
              </div>

              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Giocatore
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Ruolo
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Squadra
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Valore
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Statistiche
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Azioni
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {players.map((player) => (
                      <tr key={player.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">{player.name}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            player.role === 'P' ? 'bg-yellow-100 text-yellow-800' :
                            player.role === 'D' ? 'bg-blue-100 text-blue-800' :
                            player.role === 'C' ? 'bg-green-100 text-green-800' :
                            'bg-red-100 text-red-800'
                          }`}>
                            {player.role}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{player.team}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">€{player.value}M</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            <div className="grid grid-cols-3 gap-2">
                              <div>
                                <span className="font-medium">{player.stats.goals}</span> gol
                              </div>
                              <div>
                                <span className="font-medium">{player.stats.assists}</span> assist
                              </div>
                              <div>
                                <span className="font-medium">{player.stats.averageRating?.toFixed(2) || '-'}</span> media
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            className="text-indigo-600 hover:text-indigo-900 font-medium"
                            onClick={() => {
                              // TODO: Implement transfer logic
                              console.log('Transfer player:', player.id);
                            }}
                          >
                            Acquista
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamManagement;