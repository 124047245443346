import React from 'react';
import { Link, useLocation, useParams, Navigate } from 'react-router-dom';
import { Users, Settings, Calendar, Gavel } from 'lucide-react';

const ManagerNavigation: React.FC = () => {
  const location = useLocation();
  const { id: leagueId } = useParams<{ id: string }>();

  console.log('ManagerNavigation - Current League ID:', leagueId);
  console.log('ManagerNavigation - Current Location:', location.pathname);

  // Redirect to leagues list if no leagueId is available
  if (!leagueId) {
    console.log('ManagerNavigation - No league ID found, redirecting to leagues list');
    return <Navigate to="/leagues" replace />;
  }

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const navItems = [
    {
      path: `/leagues/${leagueId}/settings`,
      icon: Settings,
      label: 'Impostazioni Lega'
    },
    {
      path: `/leagues/${leagueId}/teams`,
      icon: Users,
      label: 'Squadre'
    },
    {
      path: `/leagues/${leagueId}/calendar`,
      icon: Calendar,
      label: 'Calendario'
    },
    {
      path: `/leagues/${leagueId}/auction`,
      icon: Gavel,
      label: 'Gestione Aste'
    }
  ];

  return (
    <nav className="bg-white shadow-sm mb-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between space-x-4 -mb-px">
          {navItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`
                flex items-center py-4 px-1 border-b-2 text-sm font-medium
                ${isActive(item.path)
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
              `}
            >
              <item.icon className="w-5 h-5 mr-2" />
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default ManagerNavigation;