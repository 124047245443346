import React, { useState, useEffect } from 'react';
import { Shield, Palette, Save, AlertCircle, Loader } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/authStore';
import ProfileSubMenu from '../components/ProfileSubMenu';

interface Team {
  id: string;
  name: string;
  crest_config?: {
    shape: 'shield' | 'circle' | 'diamond';
    primaryColor: string;
    secondaryColor: string;
    pattern: 'solid' | 'striped' | 'halved' | 'quartered';
  };
}

const TeamDetails: React.FC = () => {
  const { user } = useAuthStore();
  const [team, setTeam] = useState<Team | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    name: '',
    shape: 'shield',
    primaryColor: '#1e40af',
    secondaryColor: '#ffffff',
    pattern: 'solid'
  });

  useEffect(() => {
    loadTeam();
  }, [user]);

  const loadTeam = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('teams')
        .select('*')
        .eq('coach_id', user.id)
        .single();

      if (error) throw error;

      if (data) {
        setTeam(data);
        setFormData({
          name: data.name,
          shape: data.crest_config?.shape || 'shield',
          primaryColor: data.crest_config?.primaryColor || '#1e40af',
          secondaryColor: data.crest_config?.secondaryColor || '#ffffff',
          pattern: data.crest_config?.pattern || 'solid'
        });
      }
    } catch (err) {
      console.error('Error loading team:', err);
      setError('Errore nel caricamento della squadra');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!team) return;

    try {
      setSaving(true);
      setError(null);
      setSuccess(null);

      const { error } = await supabase
        .from('teams')
        .update({
          name: formData.name,
          crest_config: {
            shape: formData.shape,
            primaryColor: formData.primaryColor,
            secondaryColor: formData.secondaryColor,
            pattern: formData.pattern
          },
          updated_at: new Date().toISOString()
        })
        .eq('id', team.id);

      if (error) throw error;

      setSuccess('Dettagli squadra aggiornati con successo');
      await loadTeam();
    } catch (err) {
      console.error('Error updating team:', err);
      setError('Errore nell\'aggiornamento dei dettagli della squadra');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div>
        <ProfileSubMenu />
        <div className="flex justify-center items-center h-64">
          <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        </div>
      </div>
    );
  }

  if (!team) {
    return (
      <div>
        <ProfileSubMenu />
        <div className="text-center py-12">
          <Shield className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna squadra trovata</h3>
          <p className="mt-1 text-sm text-gray-500">
            Non hai ancora creato una squadra.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ProfileSubMenu />
      
      <div className="max-w-4xl mx-auto">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-lg font-medium text-gray-900 flex items-center">
                <Shield className="w-5 h-5 mr-2" />
                Dettagli Squadra
              </h2>
              <Palette className="w-5 h-5 text-gray-400" />
            </div>

            {error && (
              <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
                <AlertCircle className="w-5 h-5 mr-2" />
                {error}
              </div>
            )}

            {success && (
              <div className="mb-6 bg-green-50 border border-green-200 text-green-600 px-4 py-3 rounded flex items-center">
                <Save className="w-5 h-5 mr-2" />
                {success}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Nome Squadra
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Scudetto
                </label>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm text-gray-500 mb-1">Forma</label>
                    <select
                      value={formData.shape}
                      onChange={(e) => setFormData({ ...formData, shape: e.target.value as 'shield' | 'circle' | 'diamond' })}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="shield">Scudo</option>
                      <option value="circle">Cerchio</option>
                      <option value="diamond">Rombo</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-500 mb-1">Pattern</label>
                    <select
                      value={formData.pattern}
                      onChange={(e) => setFormData({ ...formData, pattern: e.target.value as 'solid' | 'striped' | 'halved' | 'quartered' })}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="solid">Tinta unita</option>
                      <option value="striped">Righe</option>
                      <option value="halved">Diviso a metà</option>
                      <option value="quartered">Quarti</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-500 mb-1">Colore Primario</label>
                    <div className="flex items-center space-x-2">
                      <input
                        type="color"
                        value={formData.primaryColor}
                        onChange={(e) => setFormData({ ...formData, primaryColor: e.target.value })}
                        className="h-8 w-8 rounded-md border-gray-300 shadow-sm cursor-pointer"
                      />
                      <input
                        type="text"
                        value={formData.primaryColor}
                        onChange={(e) => setFormData({ ...formData, primaryColor: e.target.value })}
                        className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm text-gray-500 mb-1">Colore Secondario</label>
                    <div className="flex items-center space-x-2">
                      <input
                        type="color"
                        value={formData.secondaryColor}
                        onChange={(e) => setFormData({ ...formData, secondaryColor: e.target.value })}
                        className="h-8 w-8 rounded-md border-gray-300 shadow-sm cursor-pointer"
                      />
                      <input
                        type="text"
                        value={formData.secondaryColor}
                        onChange={(e) => setFormData({ ...formData, secondaryColor: e.target.value })}
                        className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <label className="block text-sm text-gray-500 mb-2">Anteprima</label>
                  <div className="bg-gray-50 p-6 rounded-lg flex justify-center">
                    <div
                      className={`w-32 h-32 relative ${
                        formData.shape === 'shield'
                          ? 'clip-path-shield'
                          : formData.shape === 'circle'
                          ? 'rounded-full'
                          : 'transform rotate-45'
                      }`}
                      style={{
                        backgroundColor: formData.primaryColor,
                        backgroundImage: formData.pattern === 'striped'
                          ? `repeating-linear-gradient(45deg, ${formData.secondaryColor} 0px, ${formData.secondaryColor} 10px, transparent 10px, transparent 20px)`
                          : formData.pattern === 'halved'
                          ? `linear-gradient(90deg, ${formData.primaryColor} 50%, ${formData.secondaryColor} 50%)`
                          : formData.pattern === 'quartered'
                          ? `conic-gradient(from 45deg, ${formData.primaryColor} 25%, ${formData.secondaryColor} 25% 50%, ${formData.primaryColor} 50% 75%, ${formData.secondaryColor} 75%)`
                          : 'none'
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={saving}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center disabled:bg-indigo-300"
                >
                  {saving && <Loader className="w-4 h-4 mr-2 animate-spin" />}
                  Salva Modifiche
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;