import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Settings, Mail, Copy, Check, AlertCircle, Loader, Users, Trash2, ArrowLeft } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface League {
  id: string;
  name: string;
  budget: number;
  max_teams: number;
  scoring_system: string;
  registration_deadline: string;
}

interface TeamPlayer {
  id: string;
  team: {
    id: string;
    name: string;
    coach: {
      username: string;
    } | null;
  };
  player: {
    name: string;
    role: string;
    real_team: string;
  };
  purchase_price: number;
}

const LeagueManage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [league, setLeague] = useState<League | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [inviteEmail, setInviteEmail] = useState('');
  const [quickCode, setQuickCode] = useState('');
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState<'settings' | 'players'>('settings');
  const [teamPlayers, setTeamPlayers] = useState<TeamPlayer[]>([]);
  const [formData, setFormData] = useState({
    name: '',
    budget: 100,
    maxTeams: 8,
    scoringSystem: 'classic',
    registrationDeadline: ''
  });

  useEffect(() => {
    if (id) {
      loadLeague();
      generateQuickCode();
      if (activeTab === 'players') {
        loadTeamPlayers();
      }
    }
  }, [id, activeTab]);

  const loadLeague = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('leagues')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      setLeague(data);
      setFormData({
        name: data.name,
        budget: data.budget,
        maxTeams: data.max_teams,
        scoringSystem: data.scoring_system,
        registrationDeadline: new Date(data.registration_deadline).toISOString().split('T')[0]
      });
    } catch (err) {
      console.error('Error loading league:', err);
      setError('Errore nel caricamento della lega');
    } finally {
      setLoading(false);
    }
  };

  const loadTeamPlayers = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('team_players')
        .select(`
          id,
          team:teams(
            id,
            name,
            coach:profiles(username)
          ),
          player:players(
            name,
            role,
            real_team
          ),
          purchase_price
        `)
        .eq('team.league_id', id);

      if (error) throw error;
      setTeamPlayers(data || []);
    } catch (err) {
      console.error('Error loading team players:', err);
      setError('Errore nel caricamento dei giocatori');
    } finally {
      setLoading(false);
    }
  };

  const handleRemovePlayer = async (teamPlayerId: string, teamId: string | undefined, price: number) => {
    if (!teamId) {
      setError('ID squadra non valido');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setSuccess(null);

      // Begin transaction
      const { error: deleteError } = await supabase
        .from('team_players')
        .delete()
        .eq('id', teamPlayerId);

      if (deleteError) throw deleteError;

      // Update team budget
      const { error: updateError } = await supabase
        .from('teams')
        .update({ 
          budget_remaining: supabase.rpc('increment', { x: price }),
          updated_at: new Date().toISOString()
        })
        .eq('id', teamId);

      if (updateError) throw updateError;

      setSuccess('Giocatore rimosso e budget aggiornato con successo');
      await loadTeamPlayers();
    } catch (err: any) {
      console.error('Error removing player:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const generateQuickCode = () => {
    const code = Math.random().toString(36).substring(2, 8).toUpperCase();
    setQuickCode(code);
  };

  const handleUpdateLeague = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);

    try {
      const { error } = await supabase
        .from('leagues')
        .update({
          name: formData.name,
          budget: formData.budget,
          max_teams: formData.maxTeams,
          scoring_system: formData.scoringSystem,
          registration_deadline: formData.registrationDeadline,
          updated_at: new Date().toISOString()
        })
        .eq('id', id);

      if (error) throw error;

      setSuccess('Lega aggiornata con successo');
      loadLeague();
    } catch (err) {
      console.error('Error updating league:', err);
      setError('Errore nell\'aggiornamento della lega');
    } finally {
      setLoading(false);
    }
  };

  const handleInviteByEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);

    try {
      const { error } = await supabase
        .from('league_invitations')
        .insert({
          league_id: id,
          email: inviteEmail,
          code: quickCode,
          expires_at: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString()
        });

      if (error) throw error;

      setSuccess('Invito inviato con successo');
      setInviteEmail('');
    } catch (err) {
      console.error('Error sending invitation:', err);
      setError('Errore nell\'invio dell\'invito');
    } finally {
      setLoading(false);
    }
  };

  const copyQuickCode = () => {
    navigator.clipboard.writeText(quickCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (loading && !league) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <div className="space-y-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Gestione Lega</h1>
          <p className="mt-1 text-sm text-gray-500">
            Gestisci le impostazioni e gli inviti per la tua lega
          </p>
        </div>

        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px">
              <button
                onClick={() => setActiveTab('settings')}
                className={`py-4 px-6 text-sm font-medium ${
                  activeTab === 'settings'
                    ? 'border-b-2 border-indigo-500 text-indigo-600'
                    : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                <Settings className="w-5 h-5 inline mr-2" />
                Impostazioni
              </button>
              <button
                onClick={() => setActiveTab('players')}
                className={`py-4 px-6 text-sm font-medium ${
                  activeTab === 'players'
                    ? 'border-b-2 border-indigo-500 text-indigo-600'
                    : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                <Users className="w-5 h-5 inline mr-2" />
                Lista Giocatori Assegnati
              </button>
            </nav>
          </div>

          <div className="p-6">
            {error && (
              <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
                <AlertCircle className="w-5 h-5 mr-2" />
                {error}
              </div>
            )}

            {success && (
              <div className="mb-6 bg-green-50 border border-green-200 text-green-600 px-4 py-3 rounded flex items-center">
                <Check className="w-5 h-5 mr-2" />
                {success}
              </div>
            )}

            {activeTab === 'settings' ? (
              <>
                <form onSubmit={handleUpdateLeague} className="space-y-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Nome della Lega
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="budget" className="block text-sm font-medium text-gray-700">
                      Budget per Squadra (in milioni €)
                    </label>
                    <input
                      type="number"
                      id="budget"
                      value={formData.budget}
                      onChange={(e) => setFormData({ ...formData, budget: parseInt(e.target.value) })}
                      min="50"
                      max="500"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="maxTeams" className="block text-sm font-medium text-gray-700">
                      Numero Massimo di Squadre
                    </label>
                    <input
                      type="number"
                      id="maxTeams"
                      value={formData.maxTeams}
                      onChange={(e) => setFormData({ ...formData, maxTeams: parseInt(e.target.value) })}
                      min="4"
                      max="20"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="scoringSystem" className="block text-sm font-medium text-gray-700">
                      Sistema di Punteggio
                    </label>
                    <select
                      id="scoringSystem"
                      value={formData.scoringSystem}
                      onChange={(e) => setFormData({ ...formData, scoringSystem: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="classic">Classico</option>
                      <option value="head2head">Testa a Testa</option>
                      <option value="mantra">Mantra</option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="registrationDeadline" className="block text-sm font-medium text-gray-700">
                      Scadenza Iscrizioni
                    </label>
                    <input
                      type="date"
                      id="registrationDeadline"
                      value={formData.registrationDeadline}
                      onChange={(e) => setFormData({ ...formData, registrationDeadline: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      required
                    />
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={loading}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {loading && <Loader className="w-4 h-4 mr-2 animate-spin" />}
                      Salva Modifiche
                    </button>
                  </div>
                </form>

                <div className="pt-6 border-t border-gray-200 mt-6">
                  <h2 className="text-lg font-medium text-gray-900 flex items-center">
                    <Mail className="w-5 h-5 mr-2" />
                    Invita Partecipanti
                  </h2>

                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                    <div>
                      <h3 className="text-sm font-medium text-gray-700">Invito via Email</h3>
                      <form onSubmit={handleInviteByEmail} className="mt-2">
                        <div className="flex rounded-md shadow-sm">
                          <input
                            type="email"
                            value={inviteEmail}
                            onChange={(e) => setInviteEmail(e.target.value)}
                            className="flex-1 min-w-0 block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="email@esempio.com"
                          />
                          <button
                            type="submit"
                            className="inline-flex items-center px-3 py-2 border border-l-0 border-gray-300 rounded-r-md bg-gray-50 text-gray-500 hover:bg-gray-100"
                          >
                            Invia
                          </button>
                        </div>
                      </form>
                    </div>

                    <div>
                      <h3 className="text-sm font-medium text-gray-700">Codice Rapido</h3>
                      <div className="mt-2 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          value={quickCode}
                          readOnly
                          className="flex-1 min-w-0 block w-full rounded-none rounded-l-md border-gray-300 bg-gray-50"
                        />
                        <button
                          type="button"
                          onClick={copyQuickCode}
                          className="inline-flex items-center px-3 py-2 border border-l-0 border-gray-300 rounded-r-md bg-gray-50 text-gray-500 hover:bg-gray-100"
                        >
                          {copied ? <Check className="w-5 h-5 text-green-500" /> : <Copy className="w-5 h-5" />}
                        </button>
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        Condividi questo codice con i partecipanti per un accesso rapido
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="space-y-6">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Giocatore
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Squadra Reale
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Ruolo
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Fanta Allenatore
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Prezzo
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Azioni
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {teamPlayers.map((tp) => (
                        <tr key={tp.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {tp.player.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {tp.player.real_team}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              tp.player.role === 'P' ? 'bg-yellow-100 text-yellow-800' :
                              tp.player.role === 'D' ? 'bg-blue-100 text-blue-800' :
                              tp.player.role === 'C' ? 'bg-green-100 text-green-800' :
                              'bg-red-100 text-red-800'
                            }`}>
                              {tp.player.role}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {tp.team?.coach?.username || 'N/A'}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {tp.purchase_price}M
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <button
                              onClick={() => handleRemovePlayer(tp.id, tp.team?.id, tp.purchase_price)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <Trash2 className="w-5 h-5" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeagueManage;