import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Calendar as CalendarIcon, Clock, Trophy, AlertCircle, Loader } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import ManagerNavigation from '../components/ManagerNavigation';

interface Match {
  id: string;
  matchday: number;
  home_team: {
    name: string;
    points: number;
  };
  away_team: {
    name: string;
    points: number;
  };
  home_score: number;
  away_score: number;
  status: 'scheduled' | 'in_progress' | 'completed';
  played_at: string | null;
}

const LeagueCalendar: React.FC = () => {
  const { id: leagueId } = useParams<{ id: string }>();
  const [matches, setMatches] = useState<Match[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadMatches();
  }, [leagueId]);

  const loadMatches = async () => {
    try {
      setLoading(true);
      const { data, error: fetchError } = await supabase
        .from('matches')
        .select(`
          *,
          home_team:teams!home_team_id(name, points),
          away_team:teams!away_team_id(name, points)
        `)
        .eq('league_id', leagueId)
        .order('matchday', { ascending: true });

      if (fetchError) throw fetchError;
      setMatches(data || []);
    } catch (err) {
      console.error('Error loading matches:', err);
      setError('Errore nel caricamento del calendario');
    } finally {
      setLoading(false);
    }
  };

  const getMatchStatus = (match: Match) => {
    switch (match.status) {
      case 'completed':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            Completata
          </span>
        );
      case 'in_progress':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            In Corso
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            Programmata
          </span>
        );
    }
  };

  if (loading) {
    return (
      <div>
        <ManagerNavigation />
        <div className="flex justify-center items-center h-64">
          <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <ManagerNavigation />
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      </div>
    );
  }

  // Group matches by matchday
  const matchesByDay = matches.reduce((acc, match) => {
    if (!acc[match.matchday]) {
      acc[match.matchday] = [];
    }
    acc[match.matchday].push(match);
    return acc;
  }, {} as Record<number, Match[]>);

  return (
    <div>
      <ManagerNavigation />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Calendario</h2>
            
            <div className="space-y-8">
              {Object.entries(matchesByDay).map(([matchday, dayMatches]) => (
                <div key={matchday} className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900 flex items-center">
                    <CalendarIcon className="w-5 h-5 mr-2 text-indigo-600" />
                    Giornata {matchday}
                  </h3>
                  
                  <div className="grid gap-4">
                    {dayMatches.map((match) => (
                      <div
                        key={match.id}
                        className="bg-white border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow"
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex-1">
                            <div className="flex items-center justify-between">
                              <div className="flex items-center space-x-2">
                                <Trophy className="w-5 h-5 text-yellow-500" />
                                <span className="font-medium text-gray-900">{match.home_team.name}</span>
                                <span className="text-sm text-gray-500">({match.home_team.points} pt)</span>
                              </div>
                              <div className="font-bold text-2xl text-gray-900">
                                {match.status === 'completed' ? `${match.home_score} - ${match.away_score}` : 'vs'}
                              </div>
                              <div className="flex items-center space-x-2">
                                <span className="font-medium text-gray-900">{match.away_team.name}</span>
                                <span className="text-sm text-gray-500">({match.away_team.points} pt)</span>
                                <Trophy className="w-5 h-5 text-yellow-500" />
                              </div>
                            </div>
                          </div>
                          
                          <div className="ml-4 flex flex-col items-end">
                            {match.played_at && (
                              <div className="flex items-center text-sm text-gray-500 mb-2">
                                <Clock className="w-4 h-4 mr-1" />
                                {format(new Date(match.played_at), 'dd MMM yyyy', { locale: it })}
                              </div>
                            )}
                            {getMatchStatus(match)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}

              {Object.keys(matchesByDay).length === 0 && (
                <div className="text-center py-12">
                  <CalendarIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna partita trovata</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Non ci sono partite programmate per questa lega.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeagueCalendar;