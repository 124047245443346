import React, { useState, useEffect } from 'react';
import { Search, Filter, Loader, AlertCircle, ShoppingCart } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/authStore';
import CoachNavigation from '../components/CoachNavigation';

interface Player {
  id: string;
  name: string;
  role: string;
  real_team: string;
  base_value: number;
  stats: {
    goals: number;
    assists: number;
    yellowCards: number;
    redCards: number;
    rating: number | null;
  };
}

interface Team {
  id: string;
  name: string;
  league: {
    id: string;
    name: string;
  };
  budget_remaining: number;
}

const FreePlayers: React.FC = () => {
  const { user } = useAuthStore();
  const [players, setPlayers] = useState<Player[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState<string>('all');
  const [teamFilter, setTeamFilter] = useState<string>('all');
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  const [purchasing, setPurchasing] = useState<string | null>(null);
  const [isEnabled, setIsEnabled] = useState(true); // Changed to true by default

  useEffect(() => {
    if (user) {
      loadTeams();
      loadFreePlayers();
    }
  }, [user]);

  const loadTeams = async () => {
    try {
      setLoading(true);
      const { data: userTeams, error: teamsError } = await supabase
        .from('teams')
        .select(`
          id,
          name,
          league:leagues(
            id,
            name
          ),
          budget_remaining
        `)
        .eq('coach_id', user?.id);

      if (teamsError) throw teamsError;
      setTeams(userTeams || []);
      
      // Only set selected team if none is selected yet
      if (!selectedTeam && userTeams?.length > 0) {
        setSelectedTeam(userTeams[0].id);
      }
    } catch (err) {
      console.error('Error loading teams:', err);
      setError('Errore nel caricamento delle squadre');
    }
  };

  const loadFreePlayers = async () => {
    try {
      setLoading(true);
      
      // First get all player IDs that are in teams
      const { data: teamPlayers, error: teamPlayersError } = await supabase
        .from('team_players')
        .select('player_id');

      if (teamPlayersError) throw teamPlayersError;

      // Extract player IDs into an array
      const takenPlayerIds = teamPlayers?.map(tp => tp.player_id) || [];

      // Get all players that are not in any team
      const query = supabase
        .from('players')
        .select(`
          *,
          stats:player_stats(
            goals,
            assists,
            yellow_cards,
            red_cards,
            rating
          )
        `);

      // Only apply the not-in filter if there are taken players
      if (takenPlayerIds.length > 0) {
        query.not('id', 'in', `(${takenPlayerIds.join(',')})`);
      }

      const { data: freePlayers, error: playersError } = await query;

      if (playersError) throw playersError;

      const formattedPlayers = freePlayers?.map(player => ({
        ...player,
        stats: {
          goals: player.stats?.[0]?.goals || 0,
          assists: player.stats?.[0]?.assists || 0,
          yellowCards: player.stats?.[0]?.yellow_cards || 0,
          redCards: player.stats?.[0]?.red_cards || 0,
          rating: player.stats?.[0]?.rating || null
        }
      })) || [];

      setPlayers(formattedPlayers);
    } catch (err) {
      console.error('Error loading free players:', err);
      setError('Errore nel caricamento dei giocatori svincolati');
    } finally {
      setLoading(false);
    }
  };

  const handlePurchase = async (player: Player) => {
    if (!selectedTeam) {
      setError('Seleziona una squadra');
      return;
    }

    const team = teams.find(t => t.id === selectedTeam);
    if (!team) {
      setError('Squadra non trovata');
      return;
    }

    if (team.budget_remaining < player.base_value) {
      setError('Budget insufficiente');
      return;
    }

    try {
      setPurchasing(player.id);
      setError(null);

      const { error: purchaseError } = await supabase.rpc('buy_player', {
        p_team_id: selectedTeam,
        p_player_id: player.id,
        p_amount: player.base_value
      });

      if (purchaseError) throw purchaseError;

      // Reload data but maintain selected team
      await Promise.all([
        loadTeams(),
        loadFreePlayers()
      ]);
    } catch (err: any) {
      console.error('Error purchasing player:', err);
      setError(err.message);
    } finally {
      setPurchasing(null);
    }
  };

  const filteredPlayers = players.filter(player => {
    const matchesSearch = player.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         player.real_team.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesRole = roleFilter === 'all' || player.role === roleFilter;
    const matchesTeam = teamFilter === 'all' || player.real_team === teamFilter;
    return matchesSearch && matchesRole && matchesTeam;
  });

  if (!isEnabled) {
    return (
      <div>
        <CoachNavigation />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center py-12">
            <AlertCircle className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Mercato chiuso</h3>
            <p className="mt-1 text-sm text-gray-500">
              Il mercato dei giocatori svincolati non è attualmente disponibile.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <CoachNavigation />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
              <h2 className="text-2xl font-bold text-gray-900">Giocatori Svincolati</h2>
              
              <div className="flex items-center gap-4">
                <select
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={selectedTeam || ''}
                  onChange={(e) => setSelectedTeam(e.target.value)}
                >
                  <option value="">Seleziona squadra</option>
                  {teams.map(team => (
                    <option key={team.id} value={team.id}>
                      {team.name} ({team.league.name}) - Budget: {team.budget_remaining}M
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {error && (
              <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
                <AlertCircle className="w-5 h-5 mr-2" />
                {error}
              </div>
            )}

            <div className="mb-6 grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Cerca giocatore..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>

              <select
                value={roleFilter}
                onChange={(e) => setRoleFilter(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="all">Tutti i ruoli</option>
                <option value="P">Portieri</option>
                <option value="D">Difensori</option>
                <option value="C">Centrocampisti</option>
                <option value="A">Attaccanti</option>
              </select>

              <select
                value={teamFilter}
                onChange={(e) => setTeamFilter(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="all">Tutte le squadre</option>
                {Array.from(new Set(players.map(p => p.real_team))).map(team => (
                  <option key={team} value={team}>{team}</option>
                ))}
              </select>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Giocatore
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ruolo
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Squadra
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Valore
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Statistiche
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Azioni
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan={6} className="px-6 py-4 text-center">
                        <Loader className="w-6 h-6 animate-spin mx-auto text-indigo-600" />
                      </td>
                    </tr>
                  ) : filteredPlayers.length === 0 ? (
                    <tr>
                      <td colSpan={6} className="px-6 py-4 text-center text-gray-500">
                        Nessun giocatore svincolato trovato
                      </td>
                    </tr>
                  ) : (
                    filteredPlayers.map((player) => (
                      <tr key={player.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">{player.name}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            player.role === 'P' ? 'bg-yellow-100 text-yellow-800' :
                            player.role === 'D' ? 'bg-blue-100 text-blue-800' :
                            player.role === 'C' ? 'bg-green-100 text-green-800' :
                            'bg-red-100 text-red-800'
                          }`}>
                            {player.role}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{player.real_team}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">{player.base_value}M</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            <div className="grid grid-cols-3 gap-2">
                              <div>
                                <span className="font-medium">{player.stats.goals}</span> gol
                              </div>
                              <div>
                                <span className="font-medium">{player.stats.assists}</span> assist
                              </div>
                              <div>
                                <span className="font-medium">{player.stats.rating?.toFixed(2) || '-'}</span> media
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            onClick={() => handlePurchase(player)}
                            disabled={purchasing === player.id || !selectedTeam}
                            className="text-indigo-600 hover:text-indigo-900 font-medium flex items-center disabled:text-gray-400"
                          >
                            {purchasing === player.id ? (
                              <Loader className="w-4 h-4 mr-2 animate-spin" />
                            ) : (
                              <ShoppingCart className="w-4 h-4 mr-2" />
                            )}
                            {!selectedTeam ? 'Seleziona squadra' : 'Acquista'}
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreePlayers;