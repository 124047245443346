import { create } from 'zustand';
import { Team } from '../types';
import { teamService } from '../services/teams';

interface TeamState {
  teams: Team[];
  currentTeam: Team | null;
  loading: boolean;
  error: string | null;
  fetchTeamsByLeague: (leagueId: string) => Promise<void>;
  fetchMyTeams: () => Promise<void>;
  createTeam: (data: { name: string; leagueId: string; budgetRemaining: number }) => Promise<void>;
}

export const useTeamStore = create<TeamState>((set) => ({
  teams: [],
  currentTeam: null,
  loading: false,
  error: null,
  fetchTeamsByLeague: async (leagueId: string) => {
    set({ loading: true, error: null });
    try {
      const teams = await teamService.getTeamsByLeague(leagueId);
      set({ teams, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  },
  fetchMyTeams: async () => {
    set({ loading: true, error: null });
    try {
      const teams = await teamService.getMyTeams();
      set({ teams, loading: false });
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  },
  createTeam: async (data) => {
    set({ loading: true, error: null });
    try {
      const team = await teamService.createTeam(data);
      set((state) => ({
        teams: [...state.teams, team],
        loading: false
      }));
    } catch (error) {
      set({ error: (error as Error).message, loading: false });
    }
  }
}));