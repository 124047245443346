import React from 'react';
import { User, Shield, Settings, Bell } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const ProfileSubMenu: React.FC = () => {
  const location = useLocation();

  const menuItems = [
    {
      path: '/profile',
      icon: User,
      label: 'Profilo',
      exact: true
    },
    {
      path: '/profile/team',
      icon: Shield,
      label: 'Dettagli Squadra'
    },
    {
      path: '/profile/settings',
      icon: Settings,
      label: 'Impostazioni'
    },
    {
      path: '/profile/notifications',
      icon: Bell,
      label: 'Notifiche'
    }
  ];

  const isActive = (path: string, exact = false) => {
    if (exact) {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  return (
    <nav className="bg-white shadow-sm mb-6 overflow-x-auto">
      <div className="max-w-7xl mx-auto">
        {/* Desktop menu */}
        <div className="hidden md:flex justify-between space-x-8">
          {menuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`
                flex items-center py-4 px-1 border-b-2 text-sm font-medium
                ${isActive(item.path, item.exact)
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
              `}
            >
              <item.icon className="w-5 h-5 mr-2" />
              {item.label}
            </Link>
          ))}
        </div>

        {/* Mobile menu */}
        <div className="flex md:hidden justify-between">
          {menuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`
                flex flex-col items-center py-3 px-2 text-xs font-medium
                ${isActive(item.path, item.exact)
                  ? 'text-indigo-600'
                  : 'text-gray-500 hover:text-gray-700'}
              `}
            >
              <item.icon className="w-6 h-6 mb-1" />
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default ProfileSubMenu;