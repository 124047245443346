import { create } from 'zustand';
import { User } from '../types';
import { supabase } from '../lib/supabase';

interface AuthState {
  user: User | null;
  isLoading: boolean;
  setUser: (user: User | null) => void;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, username: string) => Promise<void>;
  logout: () => Promise<void>;
  checkSession: () => Promise<void>;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isLoading: false,
  setUser: (user) => set({ user }),
  checkSession: async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (session?.user) {
        const { data: profile } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', session.user.id)
          .single();

        if (profile) {
          set({
            user: {
              id: session.user.id,
              email: session.user.email!,
              username: profile.username,
              role: profile.role,
            }
          });
        }
      }
    } catch (error) {
      console.error('Error checking session:', error);
      // Silent fail on session check
    }
  },
  login: async (email: string, password: string) => {
    set({ isLoading: true });
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;
      if (!data.user) throw new Error('No user data');

      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', data.user.id)
        .single();

      if (profileError || !profile) {
        await supabase.auth.signOut();
        throw new Error('Profile not found');
      }

      set({
        user: {
          id: data.user.id,
          email: data.user.email!,
          username: profile.username,
          role: profile.role,
        },
        isLoading: false
      });
    } catch (error: any) {
      set({ isLoading: false });
      throw new Error('Login failed: ' + error.message);
    }
  },
  register: async (email: string, password: string, username: string) => {
    set({ isLoading: true });
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            username,
            role: 'coach'
          }
        }
      });

      if (error) throw error;
      if (!data.user) throw new Error('No user data');

      const { error: profileError } = await supabase
        .from('profiles')
        .insert({
          id: data.user.id,
          username,
          role: 'coach',
        });

      if (profileError) {
        await supabase.auth.signOut();
        throw new Error('Failed to create profile');
      }

      set({
        user: {
          id: data.user.id,
          email: data.user.email!,
          username,
          role: 'coach',
        },
        isLoading: false
      });
    } catch (error: any) {
      set({ isLoading: false });
      throw new Error('Registration failed: ' + error.message);
    }
  },
  logout: async () => {
    set({ isLoading: true });
    try {
      await supabase.auth.signOut();
      set({ user: null, isLoading: false });
    } catch (error: any) {
      set({ isLoading: false });
      throw new Error('Logout failed: ' + error.message);
    }
  },
}));