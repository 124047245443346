import React, { useState, useEffect } from 'react';
import { Calendar as CalendarIcon, Clock, AlertCircle, Loader, Save, RefreshCw, Plus } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';

interface League {
  id: string;
  name: string;
  matchdays: number;
  matches: {
    count: number;
    distinctMatchdays: number;
  };
  manager: {
    username: string;
  };
}

interface Match {
  id: string;
  league_id: string;
  home_team_id: string;
  away_team_id: string;
  matchday: number;
  played_at: string | null;
  status: 'scheduled' | 'in_progress' | 'completed';
  home_score: number;
  away_score: number;
}

const CalendarManagement: React.FC = () => {
  const [leagues, setLeagues] = useState<League[]>([]);
  const [selectedLeague, setSelectedLeague] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    loadLeagues();
  }, []);

  const loadLeagues = async () => {
    try {
      setLoading(true);
      
      // First, get the leagues
      const { data: leaguesData, error: fetchError } = await supabase
        .from('leagues')
        .select(`
          id,
          name,
          matchdays,
          manager:profiles(username)
        `)
        .order('created_at', { ascending: false });

      if (fetchError) throw fetchError;
      
      // Now get the match counts and distinct matchdays for each league
      const leaguesWithCounts = await Promise.all((leaguesData || []).map(async (league) => {
        // Get total match count
        const { count, error: countError } = await supabase
          .from('matches')
          .select('*', { count: 'exact', head: true })
          .eq('league_id', league.id);
          
        if (countError) {
          console.error('Error counting matches for league', league.id, countError);
          return {
            ...league,
            matches: { count: 0, distinctMatchdays: 0 }
          };
        }
        
        // Get distinct matchdays count
        const { data: matchdaysData, error: matchdaysError } = await supabase
          .from('matches')
          .select('matchday')
          .eq('league_id', league.id)
          .order('matchday');
          
        if (matchdaysError) {
          console.error('Error getting matchdays for league', league.id, matchdaysError);
          return {
            ...league,
            matches: { count: count || 0, distinctMatchdays: 0 }
          };
        }
        
        // Count distinct matchdays
        const distinctMatchdays = new Set(matchdaysData?.map(m => m.matchday)).size;
        
        return {
          ...league,
          matches: { 
            count: count || 0,
            distinctMatchdays: distinctMatchdays
          }
        };
      }));
      
      setLeagues(leaguesWithCounts);
      if (leaguesWithCounts && leaguesWithCounts.length > 0) {
        setSelectedLeague(leaguesWithCounts[0].id);
      }
    } catch (err) {
      console.error('Error loading leagues:', err);
      setError('Errore nel caricamento delle leghe');
    } finally {
      setLoading(false);
    }
  };

  const generateMatches = async (leagueId: string) => {
    try {
      setGenerating(true);
      setError(null);
      setSuccess(null);

      // Get league details including number of matchdays
      const { data: league, error: leagueError } = await supabase
        .from('leagues')
        .select('*')
        .eq('id', leagueId)
        .single();

      if (leagueError) throw leagueError;

      // Get all teams in the league
      const { data: teams, error: teamsError } = await supabase
        .from('teams')
        .select('id, name')
        .eq('league_id', leagueId);

      if (teamsError) throw teamsError;
      if (!teams || teams.length < 2) {
        throw new Error('Servono almeno due squadre per generare il calendario');
      }

      // Get Serie A matches for reference dates
      const { data: serieAMatches, error: serieAError } = await supabase
        .from('serie_a_matches')
        .select('*')
        .order('matchday', { ascending: true });

      if (serieAError) throw serieAError;

      // Generate round-robin schedule
      const numTeams = teams.length;
      const totalMatchdays = league.matchdays || 38; // Default to 38 if not set
      const matchesPerRound = numTeams / 2;
      const matchesToCreate = [];

      // Create matches for each round
      for (let matchday = 1; matchday <= totalMatchdays; matchday++) {
        const serieAMatchday = ((matchday - 1) % 19) + 1; // Map to Serie A matchday (1-19)
        const isSecondHalf = matchday > totalMatchdays / 2;

        // Find corresponding Serie A match date
        const serieAMatch = serieAMatches?.find(m => m.matchday === serieAMatchday);
        const matchDate = serieAMatch?.match_date || null;

        // Generate matches for this round
        for (let i = 0; i < matchesPerRound; i++) {
          const homeTeamIndex = i;
          const awayTeamIndex = numTeams - 1 - i;

          // Skip invalid team combinations
          if (homeTeamIndex >= awayTeamIndex) continue;

          const homeTeam = teams[isSecondHalf ? awayTeamIndex : homeTeamIndex];
          const awayTeam = teams[isSecondHalf ? homeTeamIndex : awayTeamIndex];

          matchesToCreate.push({
            league_id: leagueId,
            home_team_id: homeTeam.id,
            away_team_id: awayTeam.id,
            matchday: matchday,
            status: 'scheduled',
            played_at: matchDate,
            home_score: 0,
            away_score: 0
          });
        }

        // Rotate teams for next round (keeping first team fixed)
        teams.splice(1, 0, teams.pop()!);
      }

      // Delete existing matches if any
      const { error: deleteError } = await supabase
        .from('matches')
        .delete()
        .eq('league_id', leagueId);

      if (deleteError) throw deleteError;

      // Insert all matches
      const { error: insertError } = await supabase
        .from('matches')
        .insert(matchesToCreate);

      if (insertError) throw insertError;

      setSuccess('Calendario generato con successo');
      await loadLeagues();
    } catch (err: any) {
      console.error('Error generating matches:', err);
      setError(err.message);
    } finally {
      setGenerating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold text-gray-900">Gestione Calendario</h2>
        <div className="flex items-center space-x-4">
          <select
            value={selectedLeague || ''}
            onChange={(e) => setSelectedLeague(e.target.value)}
            className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          >
            {leagues.map((league) => (
              <option key={league.id} value={league.id}>
                {league.name} (Gestore: {league.manager.username})
              </option>
            ))}
          </select>
          <button
            onClick={() => selectedLeague && generateMatches(selectedLeague)}
            disabled={generating || !selectedLeague}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center disabled:bg-indigo-300"
          >
            {generating ? (
              <Loader className="w-4 h-4 mr-2 animate-spin" />
            ) : (
              <Plus className="w-4 h-4 mr-2" />
            )}
            Genera Calendario
          </button>
          <button
            onClick={loadLeagues}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
          >
            <RefreshCw className="w-4 h-4 mr-2" />
            Aggiorna
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-50 border border-green-200 text-green-600 px-4 py-3 rounded flex items-center">
          <Save className="w-5 h-5 mr-2" />
          {success}
        </div>
      )}

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Stato Generazione Calendari</h3>
          <div className="space-y-4">
            {leagues.map((league) => (
              <div key={league.id} className="flex items-center justify-between py-3 border-b border-gray-200">
                <div>
                  <h4 className="font-medium text-gray-900">{league.name}</h4>
                  <p className="text-sm text-gray-500">Gestore: {league.manager.username}</p>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-500">
                    {league.matches?.distinctMatchdays || 0} / {league.matchdays} giornate generate
                  </span>
                  {(league.matches?.distinctMatchdays || 0) === league.matchdays ? (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      Completato
                    </span>
                  ) : (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                      Incompleto
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarManagement;