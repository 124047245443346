import React, { useState, useEffect } from 'react';
import { Calendar as CalendarIcon, Plus, AlertCircle, Loader, Save, RefreshCw, Users } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import MatchBlock from '../components/MatchBlock';
import PlayerStatsInput from '../components/PlayerStatsInput';

interface SerieAMatch {
  id: string;
  matchday: number;
  home_team: string;
  away_team: string;
  match_date: string;
  home_score: number | null;
  away_score: number | null;
  status: 'scheduled' | 'in_progress' | 'completed';
}

interface Player {
  id: string;
  name: string;
  team: string;
}

interface PlayerStats {
  goals: number;
  assists: number;
  yellowCards: number;
  redCard: boolean;
  minutesPlayed: number;
}

const SerieAMatchManagement: React.FC = () => {
  const [matches, setMatches] = useState<SerieAMatch[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [selectedMatchId, setSelectedMatchId] = useState<string | null>(null);
  const [players, setPlayers] = useState<Player[]>([]);
  const [teams, setTeams] = useState<{ name: string }[]>([]);
  const [isCreating, setIsCreating] = useState(false);
  const [formData, setFormData] = useState({
    matchday: 1,
    home_team: '',
    away_team: '',
    match_date: '',
    status: 'scheduled' as const
  });

  useEffect(() => {
    loadTeams();
    loadMatches();
    loadPlayers();
  }, []);

  const loadTeams = async () => {
    try {
      const { data, error } = await supabase
        .from('teams_list')
        .select('name')
        .eq('active', true)
        .order('name');

      if (error) throw error;
      setTeams(data || []);
    } catch (err) {
      console.error('Error loading teams:', err);
      setError('Errore nel caricamento delle squadre');
    }
  };

  const loadPlayers = async () => {
    try {
      const { data, error } = await supabase
        .from('players')
        .select('id, name, real_team')
        .order('name');

      if (error) throw error;
      setPlayers(data || []);
    } catch (err) {
      console.error('Error loading players:', err);
      setError('Errore nel caricamento dei giocatori');
    }
  };

  const loadMatches = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('serie_a_matches')
        .select('*')
        .order('matchday', { ascending: true })
        .order('match_date', { ascending: true });

      if (error) throw error;
      setMatches(data || []);
    } catch (err) {
      console.error('Error loading matches:', err);
      setError('Errore nel caricamento delle partite');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveScore = async (matchId: string, homeScore: number, awayScore: number) => {
    try {
      setError(null);
      const { error } = await supabase
        .from('serie_a_matches')
        .update({
          home_score: homeScore,
          away_score: awayScore,
          status: 'completed',
          updated_at: new Date().toISOString()
        })
        .eq('id', matchId);

      if (error) throw error;
      setSuccess('Risultato salvato con successo');
      await loadMatches();
    } catch (err: any) {
      console.error('Error saving score:', err);
      setError(err.message);
    }
  };

  const handleSavePlayerStats = async (matchId: string, stats: Record<string, PlayerStats>) => {
    try {
      setError(null);
      
      // Create player stats entries
      const statsEntries = Object.entries(stats).map(([playerId, playerStats]) => ({
        match_id: matchId,
        player_id: playerId,
        minutes_played: playerStats.minutesPlayed,
        goals: playerStats.goals,
        assists: playerStats.assists,
        yellow_cards: playerStats.yellowCards,
        red_card: playerStats.redCard
      }));

      const { error } = await supabase
        .from('match_players')
        .upsert(statsEntries);

      if (error) throw error;
      setSuccess('Statistiche salvate con successo');
      setSelectedMatchId(null);
    } catch (err: any) {
      console.error('Error saving player stats:', err);
      setError(err.message);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      if (formData.home_team === formData.away_team) {
        throw new Error('La squadra di casa e quella ospite devono essere diverse');
      }

      const matchDate = new Date(formData.match_date);
      if (isNaN(matchDate.getTime())) {
        throw new Error('Data partita non valida');
      }

      const { error: insertError } = await supabase
        .from('serie_a_matches')
        .insert([formData]);

      if (insertError) throw insertError;
      setSuccess('Partita creata con successo');
      setIsCreating(false);
      setFormData({
        matchday: 1,
        home_team: '',
        away_team: '',
        match_date: '',
        status: 'scheduled'
      });
      await loadMatches();
    } catch (err: any) {
      console.error('Error saving match:', err);
      setError(err.message);
    }
  };

  // Group matches by matchday
  const matchesByDay = matches.reduce((acc, match) => {
    if (!acc[match.matchday]) {
      acc[match.matchday] = [];
    }
    acc[match.matchday].push(match);
    return acc;
  }, {} as Record<number, SerieAMatch[]>);

  if (loading && matches.length === 0) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900">Gestione Calendario Serie A</h2>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setIsCreating(true)}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
          >
            <Plus className="w-4 h-4 mr-2" />
            Nuova Partita
          </button>
          <button
            onClick={loadMatches}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
          >
            <RefreshCw className="w-4 h-4 mr-2" />
            Aggiorna
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-50 border border-green-200 text-green-600 px-4 py-3 rounded flex items-center">
          <Save className="w-5 h-5 mr-2" />
          {success}
        </div>
      )}

      {isCreating && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Nuova Partita
              </h3>
              <form onSubmit={handleSubmit} className="mt-2 space-y-4">
                <div>
                  <label htmlFor="matchday" className="block text-sm font-medium text-gray-700">
                    Giornata
                  </label>
                  <input
                    type="number"
                    id="matchday"
                    min="1"
                    max="38"
                    value={formData.matchday}
                    onChange={(e) => setFormData({ ...formData, matchday: parseInt(e.target.value) })}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label htmlFor="home_team" className="block text-sm font-medium text-gray-700">
                    Squadra Casa
                  </label>
                  <select
                    id="home_team"
                    value={formData.home_team}
                    onChange={(e) => setFormData({ ...formData, home_team: e.target.value })}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  >
                    <option value="">Seleziona squadra</option>
                    {teams.map((team) => (
                      <option key={team.name} value={team.name}>
                        {team.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="away_team" className="block text-sm font-medium text-gray-700">
                    Squadra Ospite
                  </label>
                  <select
                    id="away_team"
                    value={formData.away_team}
                    onChange={(e) => setFormData({ ...formData, away_team: e.target.value })}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  >
                    <option value="">Seleziona squadra</option>
                    {teams.map((team) => (
                      <option key={team.name} value={team.name}>
                        {team.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="match_date" className="block text-sm font-medium text-gray-700">
                    Data Partita
                  </label>
                  <input
                    type="date"
                    id="match_date"
                    value={formData.match_date}
                    onChange={(e) => setFormData({ ...formData, match_date: e.target.value })}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div className="flex justify-end space-x-3 mt-4">
                  <button
                    type="button"
                    onClick={() => setIsCreating(false)}
                    className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                  >
                    Annulla
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                  >
                    Crea
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {selectedMatchId && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border max-w-4xl shadow-lg rounded-md bg-white">
            {(() => {
              const match = matches.find(m => m.id === selectedMatchId);
              if (!match) return null;

              const homePlayers = players.filter(p => p.team === match.home_team);
              const awayPlayers = players.filter(p => p.team === match.away_team);

              return (
                <PlayerStatsInput
                  matchId={match.id}
                  homeTeam={match.home_team}
                  awayTeam={match.away_team}
                  homePlayers={homePlayers}
                  awayPlayers={awayPlayers}
                  onSave={handleSavePlayerStats}
                  onClose={() => setSelectedMatchId(null)}
                />
              );
            })()}
          </div>
        </div>
      )}

      <div className="space-y-8">
        {Object.entries(matchesByDay).map(([matchday, dayMatches]) => (
          <div key={matchday} className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
              <h3 className="text-lg font-medium text-gray-900 flex items-center">
                <CalendarIcon className="w-5 h-5 mr-2 text-indigo-600" />
                Giornata {matchday}
              </h3>
            </div>
            
            <div className="p-6 grid gap-4">
              {dayMatches.map((match) => (
                <div key={match.id} className="relative">
                  <MatchBlock
                    matchId={match.id}
                    homeTeam={match.home_team}
                    awayTeam={match.away_team}
                    homeScore={match.home_score}
                    awayScore={match.away_score}
                    date={format(new Date(match.match_date), 'dd MMM yyyy', { locale: it })}
                    onSave={handleSaveScore}
                  />
                  <button
                    onClick={() => setSelectedMatchId(match.id)}
                    className="absolute top-4 right-16 text-indigo-600 hover:text-indigo-900"
                  >
                    <Users className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SerieAMatchManagement;