import React, { useEffect, useState } from 'react';
import { useTeamStore } from '../store/teamStore';
import { Player } from '../types';
import { Loader, Star, AlertCircle, Trophy } from 'lucide-react';
import CoachNavigation from '../components/CoachNavigation';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/authStore';

interface TeamPlayer {
  id: string;
  name: string;
  team: string;
  role: string;
  value: number;
  stats: {
    goals: number;
    assists: number;
    yellowCards: number;
    redCards: number;
    averageRating: number | null;
  };
}

interface Team {
  id: string;
  name: string;
  budget_remaining: number;
}

const MyPlayers: React.FC = () => {
  const { user } = useAuthStore();
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [players, setPlayers] = useState<TeamPlayer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      loadTeams();
    }
  }, [user]);

  useEffect(() => {
    if (selectedTeam) {
      loadPlayers(selectedTeam.id);
    }
  }, [selectedTeam]);

  const loadTeams = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: teamsData, error: teamsError } = await supabase
        .from('teams')
        .select('id, name, budget_remaining, league:leagues(name)')
        .eq('coach_id', user?.id);

      if (teamsError) throw teamsError;
      if (!teamsData || teamsData.length === 0) {
        setTeams([]);
        setSelectedTeam(null);
        return;
      }

      setTeams(teamsData);
      setSelectedTeam(teamsData[0]); // Select the first team by default
    } catch (err) {
      console.error('Error loading teams:', err);
      setError('Errore nel caricamento delle squadre');
    }
  };

  const loadPlayers = async (teamId: string) => {
    try {
      setLoading(true);
      setError(null);

      const { data: playerData, error: playerError } = await supabase
        .from('team_players')
        .select(`
          player:players (
            id,
            name,
            role,
            real_team,
            stats:player_stats (
              goals,
              assists,
              yellow_cards,
              red_cards,
              rating
            )
          ),
          purchase_price
        `)
        .eq('team_id', teamId);

      if (playerError) throw playerError;

      const formattedPlayers = playerData.map(item => ({
        id: item.player.id,
        name: item.player.name,
        team: item.player.real_team,
        role: item.player.role,
        value: item.purchase_price,
        stats: {
          goals: item.player.stats?.[0]?.goals || 0,
          assists: item.player.stats?.[0]?.assists || 0,
          yellowCards: item.player.stats?.[0]?.yellow_cards || 0,
          redCards: item.player.stats?.[0]?.red_cards || 0,
          averageRating: item.player.stats?.[0]?.rating || null
        }
      }));

      setPlayers(formattedPlayers);
    } catch (err) {
      console.error('Error loading players:', err);
      setError('Errore nel caricamento dei giocatori');
    } finally {
      setLoading(false);
    }
  };

  if (loading && teams.length === 0) {
    return (
      <div>
        <CoachNavigation />
        <div className="flex justify-center items-center h-64">
          <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <CoachNavigation />
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      </div>
    );
  }

  if (teams.length === 0) {
    return (
      <div>
        <CoachNavigation />
        <div className="text-center py-12">
          <Trophy className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna squadra trovata</h3>
          <p className="mt-1 text-sm text-gray-500">
            Non hai ancora creato una squadra.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <CoachNavigation />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
              <h2 className="text-2xl font-bold text-gray-900">I Miei Giocatori</h2>
              
              <div className="flex items-center gap-4">
                <select
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={selectedTeam?.id}
                  onChange={(e) => {
                    const team = teams.find(t => t.id === e.target.value);
                    setSelectedTeam(team || null);
                  }}
                >
                  {teams.map((team) => (
                    <option key={team.id} value={team.id}>
                      {team.name} {team.league?.name ? `(${team.league.name})` : ''}
                    </option>
                  ))}
                </select>
                
                <div className="text-sm text-gray-500">
                  Budget: €{selectedTeam?.budget_remaining}M
                </div>
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {['P', 'D', 'C', 'A'].map((role) => {
                const rolePlayers = players.filter(p => p.role === role);
                return (
                  <div key={role} className="space-y-4">
                    <h3 className="font-semibold text-gray-700 flex items-center">
                      {role === 'P' ? 'Portieri' :
                       role === 'D' ? 'Difensori' :
                       role === 'C' ? 'Centrocampisti' :
                       'Attaccanti'}
                      <span className="ml-2 text-sm text-gray-500">
                        ({rolePlayers.length})
                      </span>
                    </h3>
                    
                    {rolePlayers.length === 0 ? (
                      <div className="bg-gray-50 rounded-lg p-4 text-center text-gray-500 text-sm">
                        Nessun giocatore
                      </div>
                    ) : (
                      rolePlayers.map((player) => (
                        <div
                          key={player.id}
                          className="bg-white border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow"
                        >
                          <div className="flex justify-between items-start">
                            <div>
                              <h4 className="font-medium text-gray-900">{player.name}</h4>
                              <p className="text-sm text-gray-500">{player.team}</p>
                            </div>
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              role === 'P' ? 'bg-yellow-100 text-yellow-800' :
                              role === 'D' ? 'bg-blue-100 text-blue-800' :
                              role === 'C' ? 'bg-green-100 text-green-800' :
                              'bg-red-100 text-red-800'
                            }`}>
                              {role}
                            </span>
                          </div>
                          
                          <div className="mt-3 grid grid-cols-3 gap-2 text-sm text-gray-600">
                            <div className="flex items-center">
                              <Star className="w-4 h-4 text-yellow-400 mr-1" />
                              <span>{player.stats.averageRating?.toFixed(1) || '-'}</span>
                            </div>
                            <div>
                              {player.stats.goals} gol
                            </div>
                            <div>
                              {player.stats.assists} assist
                            </div>
                          </div>
                          <div className="mt-2 text-sm text-gray-500">
                            Acquistato per: €{player.value}M
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPlayers;