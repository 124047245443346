import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trophy, Users, Settings, AlertCircle, Loader } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { supabase } from '../lib/supabase';

interface League {
  id: string;
  name: string;
  max_teams: number;
  budget: number;
  scoring_system: string;
  registration_deadline: string;
  teams: {
    id: string;
    name: string;
    coach: {
      username: string;
    };
  }[];
}

const MyLeagues: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [leagues, setLeagues] = useState<League[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadLeagues();
  }, [user]);

  const loadLeagues = async () => {
    if (!user) return;

    try {
      setLoading(true);
      setError(null);

      // First get the teams where the user is a coach
      const { data: teams, error: teamsError } = await supabase
        .from('teams')
        .select('league_id')
        .eq('coach_id', user.id);

      if (teamsError) throw teamsError;

      // Get the unique league IDs
      const leagueIds = [...new Set(teams?.map(t => t.league_id) || [])];

      if (leagueIds.length === 0) {
        setLeagues([]);
        return;
      }

      // Then fetch the leagues with those IDs
      const { data: leagues, error: leaguesError } = await supabase
        .from('leagues')
        .select(`
          *,
          teams (
            id,
            name,
            coach:profiles (username)
          )
        `)
        .in('id', leagueIds)
        .order('created_at', { ascending: false });

      if (leaguesError) throw leaguesError;
      setLeagues(leagues || []);
    } catch (err: any) {
      console.error('Error loading leagues:', err);
      setError('Errore nel caricamento delle leghe');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Le Mie Leghe</h1>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {leagues.length === 0 ? (
          <div className="col-span-full text-center py-12">
            <Trophy className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna lega trovata</h3>
            <p className="mt-1 text-sm text-gray-500">
              Non sei ancora iscritto a nessuna lega
            </p>
          </div>
        ) : (
          leagues.map((league) => (
            <div key={league.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-bold text-gray-900">{league.name}</h2>
                  <Trophy className="w-6 h-6 text-indigo-600" />
                </div>
                
                <div className="space-y-2">
                  <p className="text-gray-600">
                    <Users className="w-4 h-4 inline mr-2" />
                    {league.teams?.length || 0} / {league.max_teams} squadre
                  </p>
                  <p className="text-gray-600">
                    <Settings className="w-4 h-4 inline mr-2" />
                    Budget: €{league.budget}M
                  </p>
                  <p className="text-gray-600">
                    Sistema: {league.scoring_system}
                  </p>
                </div>

                <div className="mt-6 flex space-x-3">
                  <button
                    onClick={() => navigate(`/leagues/${league.id}/standings`)}
                    className="flex-1 text-center px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                  >
                    Classifica
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MyLeagues;