import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Users, ArrowRight, AlertCircle, Loader, Search } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import ManagerNavigation from '../components/ManagerNavigation';

interface Transfer {
  id: string;
  type: 'BUY' | 'SELL';
  amount: number;
  created_at: string;
  team: {
    name: string;
    coach: {
      username: string;
    };
  };
  player: {
    name: string;
    role: string;
    real_team: string;
  };
}

const LeagueTransfers: React.FC = () => {
  const { id: leagueId } = useParams<{ id: string }>();
  const [transfers, setTransfers] = useState<Transfer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadTransfers();
  }, [leagueId]);

  const loadTransfers = async () => {
    try {
      setLoading(true);
      const { data, error: fetchError } = await supabase
        .from('market_transactions')
        .select(`
          *,
          team:teams!market_transactions_team_id_fkey(
            name,
            coach:profiles(username)
          ),
          player:players(
            name,
            role,
            real_team
          )
        `)
        .eq('teams.league_id', leagueId)
        .order('created_at', { ascending: false });

      if (fetchError) throw fetchError;
      setTransfers(data || []);
    } catch (err) {
      console.error('Error loading transfers:', err);
      setError('Errore nel caricamento dei trasferimenti');
    } finally {
      setLoading(false);
    }
  };

  const filteredTransfers = transfers.filter(transfer => 
    transfer.player.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    transfer.team.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    transfer.team.coach.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div>
        <ManagerNavigation />
        <div className="flex justify-center items-center h-64">
          <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <ManagerNavigation />
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      </div>
    );
  }

  return (
    <div>
      <ManagerNavigation />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900">Trasferimenti</h2>
              
              <div className="relative">
                <input
                  type="text"
                  placeholder="Cerca trasferimenti..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
            </div>

            <div className="space-y-4">
              {filteredTransfers.map((transfer) => (
                <div
                  key={transfer.id}
                  className="bg-white border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <div className={`
                        w-10 h-10 rounded-full flex items-center justify-center text-white
                        ${transfer.player.role === 'P' ? 'bg-yellow-500' :
                          transfer.player.role === 'D' ? 'bg-blue-500' :
                          transfer.player.role === 'C' ? 'bg-green-500' :
                          'bg-red-500'}
                      `}>
                        {transfer.player.role}
                      </div>
                      
                      <div>
                        <h3 className="font-medium text-gray-900">{transfer.player.name}</h3>
                        <p className="text-sm text-gray-500">{transfer.player.real_team}</p>
                      </div>
                      
                      <ArrowRight className="w-5 h-5 text-gray-400" />
                      
                      <div>
                        <h3 className="font-medium text-gray-900">{transfer.team.name}</h3>
                        <p className="text-sm text-gray-500">Coach: {transfer.team.coach.username}</p>
                      </div>
                    </div>

                    <div className="text-right">
                      <div className="text-lg font-bold text-gray-900">
                        {transfer.amount}M
                      </div>
                      <div className="text-sm text-gray-500">
                        {format(new Date(transfer.created_at), 'dd MMM yyyy', { locale: it })}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {filteredTransfers.length === 0 && (
                <div className="text-center py-12">
                  <Users className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">Nessun trasferimento trovato</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Non ci sono trasferimenti che corrispondono alla tua ricerca.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeagueTransfers;