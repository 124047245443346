import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { Shield, Users, ArrowLeftRight, AlertCircle, Loader, Radio } from 'lucide-react';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';

interface Team {
  id: string;
  name: string;
  crest_config: {
    shape: 'shield' | 'circle' | 'diamond';
    primaryColor: string;
    secondaryColor: string;
    pattern: 'solid' | 'striped' | 'halved' | 'quartered';
  };
}

interface Match {
  id: string;
  home_team: Team;
  away_team: Team;
  home_score: number;
  away_score: number;
  status: 'scheduled' | 'in_progress' | 'completed';
  matchday: number;
  played_at: string | null;
}

const LiveMatch: React.FC = () => {
  const { id: matchId } = useParams<{ id: string }>();
  const [matches, setMatches] = useState<Match[]>([]);
  const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState<'all' | 'live' | 'completed'>('live');

  useEffect(() => {
    loadMatches();

    // Set up real-time subscription for match updates
    const channel = supabase
      .channel('match_updates')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'matches'
        },
        () => {
          loadMatches();
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const loadMatches = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('matches')
        .select(`
          *,
          home_team:teams!home_team_id(
            id,
            name,
            crest_config
          ),
          away_team:teams!away_team_id(
            id,
            name,
            crest_config
          )
        `)
        .order('matchday', { ascending: true });

      if (error) throw error;

      setMatches(data || []);
      
      // If no match is selected, select the first live match or first match
      if (!selectedMatch && data && data.length > 0) {
        const liveMatch = data.find(m => m.status === 'in_progress');
        setSelectedMatch(liveMatch || data[0]);
      }
    } catch (err) {
      console.error('Error loading matches:', err);
      setError('Errore nel caricamento delle partite');
    } finally {
      setLoading(false);
    }
  };

  const filteredMatches = matches.filter(match => {
    switch (filter) {
      case 'live':
        return match.status === 'in_progress';
      case 'completed':
        return match.status === 'completed';
      default:
        return true;
    }
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-900">Partite</h2>
          <div className="flex items-center space-x-4">
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value as 'all' | 'live' | 'completed')}
              className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="all">Tutte le partite</option>
              <option value="live">Partite in corso</option>
              <option value="completed">Partite terminate</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
          {/* Match List */}
          <div className="lg:col-span-1 bg-white rounded-lg shadow overflow-hidden">
            <div className="p-4">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Seleziona Partita</h3>
              <div className="space-y-2">
                {filteredMatches.length === 0 ? (
                  <p className="text-gray-500 text-center py-4">
                    Nessuna partita disponibile
                  </p>
                ) : (
                  filteredMatches.map((match) => (
                    <button
                      key={match.id}
                      onClick={() => setSelectedMatch(match)}
                      className={`w-full text-left px-4 py-3 rounded-lg transition-colors ${
                        selectedMatch?.id === match.id
                          ? 'bg-indigo-50 border-indigo-500'
                          : 'hover:bg-gray-50'
                      }`}
                    >
                      <div className="flex items-center justify-between">
                        <div>
                          <div className="font-medium text-gray-900">
                            {match.home_team.name} vs {match.away_team.name}
                          </div>
                          <div className="text-sm text-gray-500">
                            Giornata {match.matchday}
                          </div>
                        </div>
                        {match.status === 'in_progress' && (
                          <div className="flex items-center">
                            <span className="w-2 h-2 bg-green-500 rounded-full pulse-green" />
                          </div>
                        )}
                      </div>
                    </button>
                  ))
                )}
              </div>
            </div>
          </div>

          {/* Match Details */}
          <div className="lg:col-span-3">
            {selectedMatch ? (
              <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="p-6">
                  <div className="flex items-center justify-between mb-6">
                    <h3 className="text-lg font-medium text-gray-900">
                      Giornata {selectedMatch.matchday}
                    </h3>
                    <div className="flex items-center space-x-2">
                      {selectedMatch.status === 'in_progress' && (
                        <span className="flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          <span className="w-2 h-2 bg-green-500 rounded-full pulse-green mr-2" />
                          Live
                        </span>
                      )}
                      {selectedMatch.status === 'completed' && (
                        <span className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          Terminata
                        </span>
                      )}
                      {selectedMatch.status === 'scheduled' && (
                        <span className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                          In programma
                        </span>
                      )}
                      {selectedMatch.played_at && (
                        <span className="text-sm text-gray-500">
                          {format(new Date(selectedMatch.played_at), 'dd MMM yyyy HH:mm', { locale: it })}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-8">
                      {/* Home Team */}
                      <div className="text-center">
                        <div className="w-24 h-24 relative">
                          <div 
                            className={`w-full h-full ${
                              selectedMatch.home_team.crest_config.shape === 'circle' ? 'rounded-full' :
                              selectedMatch.home_team.crest_config.shape === 'diamond' ? 'transform rotate-45' :
                              'clip-path-shield'
                            }`}
                            style={{
                              backgroundColor: selectedMatch.home_team.crest_config.primaryColor,
                              backgroundImage: selectedMatch.home_team.crest_config.pattern === 'striped'
                                ? `repeating-linear-gradient(45deg, ${selectedMatch.home_team.crest_config.secondaryColor} 0px, ${selectedMatch.home_team.crest_config.secondaryColor} 10px, transparent 10px, transparent 20px)`
                                : selectedMatch.home_team.crest_config.pattern === 'halved'
                                ? `linear-gradient(90deg, ${selectedMatch.home_team.crest_config.primaryColor} 50%, ${selectedMatch.home_team.crest_config.secondaryColor} 50%)`
                                : selectedMatch.home_team.crest_config.pattern === 'quartered'
                                ? `conic-gradient(from 45deg, ${selectedMatch.home_team.crest_config.primaryColor} 25%, ${selectedMatch.home_team.crest_config.secondaryColor} 25% 50%, ${selectedMatch.home_team.crest_config.primaryColor} 50% 75%, ${selectedMatch.home_team.crest_config.secondaryColor} 75%)`
                                : 'none'
                            }}
                          />
                        </div>
                        <h3 className="mt-2 text-lg font-medium">{selectedMatch.home_team.name}</h3>
                      </div>

                      {/* Score */}
                      <div className="text-4xl font-bold text-gray-900">
                        {selectedMatch.home_score} - {selectedMatch.away_score}
                      </div>

                      {/* Away Team */}
                      <div className="text-center">
                        <div className="w-24 h-24 relative">
                          <div 
                            className={`w-full h-full ${
                              selectedMatch.away_team.crest_config.shape === 'circle' ? 'rounded-full' :
                              selectedMatch.away_team.crest_config.shape === 'diamond' ? 'transform rotate-45' :
                              'clip-path-shield'
                            }`}
                            style={{
                              backgroundColor: selectedMatch.away_team.crest_config.primaryColor,
                              backgroundImage: selectedMatch.away_team.crest_config.pattern === 'striped'
                                ? `repeating-linear-gradient(45deg, ${selectedMatch.away_team.crest_config.secondaryColor} 0px, ${selectedMatch.away_team.crest_config.secondaryColor} 10px, transparent 10px, transparent 20px)`
                                : selectedMatch.away_team.crest_config.pattern === 'halved'
                                ? `linear-gradient(90deg, ${selectedMatch.away_team.crest_config.primaryColor} 50%, ${selectedMatch.away_team.crest_config.secondaryColor} 50%)`
                                : selectedMatch.away_team.crest_config.pattern === 'quartered'
                                ? `conic-gradient(from 45deg, ${selectedMatch.away_team.crest_config.primaryColor} 25%, ${selectedMatch.away_team.crest_config.secondaryColor} 25% 50%, ${selectedMatch.away_team.crest_config.primaryColor} 50% 75%, ${selectedMatch.away_team.crest_config.secondaryColor} 75%)`
                                : 'none'
                            }}
                          />
                        </div>
                        <h3 className="mt-2 text-lg font-medium">{selectedMatch.away_team.name}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-white rounded-lg shadow p-6 text-center">
                <Radio className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900">Nessuna partita selezionata</h3>
                <p className="mt-1 text-gray-500">
                  Seleziona una partita dalla lista per visualizzare i dettagli
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveMatch;