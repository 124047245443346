import { supabase } from '../lib/supabase';
import { Player } from '../types';

export const playerService = {
  async getPlayers() {
    const { data: players, error } = await supabase
      .from('players')
      .select(`
        *,
        stats:player_stats(
          goals,
          assists,
          yellow_cards,
          red_cards,
          rating
        )
      `)
      .order('name');

    if (error) {
      console.error('Error fetching players:', error);
      throw error;
    }

    // Add console.log to debug
    console.log('Fetched players:', players);

    return players.map(player => ({
      ...player,
      stats: {
        goals: player.stats?.[0]?.goals || 0,
        assists: player.stats?.[0]?.assists || 0,
        yellowCards: player.stats?.[0]?.yellow_cards || 0,
        redCards: player.stats?.[0]?.red_cards || 0,
        averageRating: player.stats?.[0]?.rating || null
      }
    }));
  },

  async getPlayersByTeam(teamId: string) {
    const { data: players, error } = await supabase
      .from('team_players')
      .select(`
        player:players(
          *,
          stats:player_stats(
            goals,
            assists,
            yellow_cards,
            red_cards,
            rating
          )
        ),
        purchase_price
      `)
      .eq('team_id', teamId);

    if (error) throw error;

    return players.map(({ player, purchase_price }) => ({
      ...player,
      purchasePrice: purchase_price,
      stats: {
        goals: player.stats?.[0]?.goals || 0,
        assists: player.stats?.[0]?.assists || 0,
        yellowCards: player.stats?.[0]?.yellow_cards || 0,
        redCards: player.stats?.[0]?.red_cards || 0,
        averageRating: player.stats?.[0]?.rating || null
      }
    }));
  },

  async purchasePlayer(playerId: string, teamId: string, price: number) {
    const { data, error } = await supabase
      .from('team_players')
      .insert({
        player_id: playerId,
        team_id: teamId,
        purchase_price: price
      })
      .select()
      .single();

    if (error) throw error;
    return data;
  }
};