import React from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import { Trophy, Users, Settings, LogOut, User, Search, Gavel, Calendar } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import CoachNavigation from './CoachNavigation';
import ManagerNavigation from './ManagerNavigation';

const Layout: React.FC = () => {
  const { user, logout } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  // Paths that should not show the navigation menus
  const excludedPaths = ['/admin', '/leagues', '/profile', '/available-leagues', '/profile/team'];

  const shouldShowCoachNav = () => {
    return user?.role === 'coach' && !excludedPaths.includes(location.pathname);
  };

  const shouldShowManagerNav = () => {
    return user?.role === 'league_manager' && !excludedPaths.includes(location.pathname);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-indigo-600 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <Trophy className="w-8 h-8" />
              <span className="ml-2 text-xl font-bold">Fantacalcio</span>
            </div>
            
            {user && (
              <div className="flex items-center space-x-4">
                <Link to="/leagues" className="flex items-center hover:text-gray-200">
                  <Trophy className="w-5 h-5 mr-1" />
                  Le Mie Leghe
                </Link>
                {user.role === 'coach' && (
                  <>
                    <Link to="/available-leagues" className="flex items-center hover:text-gray-200">
                      <Search className="w-5 h-5 mr-1" />
                      Cerca Leghe
                    </Link>
                    <Link to="/my-players" className="flex items-center hover:text-gray-200">
                      <Users className="w-5 h-5 mr-1" />
                      Gestione
                    </Link>
                  </>
                )}
                {user.role === 'admin' && (
                  <>
                    <Link to="/admin" className="flex items-center hover:text-gray-200">
                      <Settings className="w-5 h-5 mr-1" />
                      Admin
                    </Link>
                    <Link to="/admin/calendar" className="flex items-center hover:text-gray-200">
                      <Calendar className="w-5 h-5 mr-1" />
                      Calendario
                    </Link>
                    <Link to="/admin/serie-a" className="flex items-center hover:text-gray-200">
                      <Trophy className="w-5 h-5 mr-1" />
                      Serie A
                    </Link>
                  </>
                )}
                <Link to="/profile" className="flex items-center hover:text-gray-200">
                  <User className="w-5 h-5 mr-1" />
                  Profilo
                </Link>
                <button
                  onClick={handleLogout}
                  className="flex items-center hover:text-gray-200"
                >
                  <LogOut className="w-5 h-5 mr-1" />
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>

      {shouldShowCoachNav() && <CoachNavigation />}
      {shouldShowManagerNav() && <ManagerNavigation />}

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;