import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Trophy, TrendingUp, ArrowUp, ArrowDown, Minus, AlertCircle, Loader } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/authStore';

interface League {
  id: string;
  name: string;
}

interface Team {
  id: string;
  name: string;
  points: number;
  coach: {
    username: string;
  };
}

interface Standing {
  teamId: string;
  teamName: string;
  position: number;
  points: number;
  matchesPlayed: number;
  wins: number;
  draws: number;
  losses: number;
  goalsFor: number;
  goalsAgainst: number;
  goalDifference: number;
  form: string[];
}

const LeagueStandings: React.FC = () => {
  const { id: urlLeagueId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  
  const [leagues, setLeagues] = useState<League[]>([]);
  const [selectedLeagueId, setSelectedLeagueId] = useState<string | null>(urlLeagueId || null);
  const [standings, setStandings] = useState<Standing[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadLeagues();
  }, [user]);

  useEffect(() => {
    if (selectedLeagueId) {
      loadStandings(selectedLeagueId);
      
      // Update URL if it doesn't match the selected league
      if (urlLeagueId !== selectedLeagueId) {
        navigate(`/leagues/${selectedLeagueId}/standings`, { replace: true });
      }
    }
  }, [selectedLeagueId]);

  const loadLeagues = async () => {
    try {
      setLoading(true);
      
      // For coaches, get leagues where they have teams
      if (user?.role === 'coach') {
        const { data: teams, error: teamsError } = await supabase
          .from('teams')
          .select(`
            league:leagues(
              id,
              name
            )
          `)
          .eq('coach_id', user.id);

        if (teamsError) throw teamsError;

        const uniqueLeagues = teams?.reduce((acc: League[], team) => {
          if (team.league && !acc.some(l => l.id === team.league.id)) {
            acc.push(team.league);
          }
          return acc;
        }, []) || [];

        setLeagues(uniqueLeagues);
        
        // If no league is selected and we have leagues, select the first one
        if (!selectedLeagueId && uniqueLeagues.length > 0) {
          setSelectedLeagueId(uniqueLeagues[0].id);
        }
      } 
      // For league managers and admins, get all leagues
      else if (user?.role === 'league_manager' || user?.role === 'admin') {
        const { data, error } = await supabase
          .from('leagues')
          .select('id, name')
          .order('name');

        if (error) throw error;
        setLeagues(data || []);
        
        // If no league is selected and we have leagues, select the first one
        if (!selectedLeagueId && data && data.length > 0) {
          setSelectedLeagueId(data[0].id);
        }
      }
    } catch (err) {
      console.error('Error loading leagues:', err);
      setError('Errore nel caricamento delle leghe');
    } finally {
      setLoading(false);
    }
  };

  const loadStandings = async (leagueId: string) => {
    try {
      setLoading(true);
      
      // Get teams in the league
      const { data: teams, error: teamsError } = await supabase
        .from('teams')
        .select(`
          id,
          name,
          points,
          coach:profiles(username)
        `)
        .eq('league_id', leagueId)
        .order('points', { ascending: false });

      if (teamsError) throw teamsError;

      // Get matches to calculate statistics
      const { data: matches, error: matchesError } = await supabase
        .from('matches')
        .select(`
          id,
          home_team_id,
          away_team_id,
          home_score,
          away_score,
          status
        `)
        .eq('league_id', leagueId)
        .eq('status', 'completed');

      if (matchesError) throw matchesError;

      // Calculate standings
      const standingsMap: Record<string, Standing> = {};
      
      // Initialize standings with team data
      teams?.forEach((team, index) => {
        standingsMap[team.id] = {
          teamId: team.id,
          teamName: team.name,
          position: index + 1,
          points: team.points || 0,
          matchesPlayed: 0,
          wins: 0,
          draws: 0,
          losses: 0,
          goalsFor: 0,
          goalsAgainst: 0,
          goalDifference: 0,
          form: []
        };
      });

      // Calculate statistics from matches
      matches?.forEach(match => {
        // Home team stats
        if (standingsMap[match.home_team_id]) {
          standingsMap[match.home_team_id].matchesPlayed++;
          standingsMap[match.home_team_id].goalsFor += match.home_score || 0;
          standingsMap[match.home_team_id].goalsAgainst += match.away_score || 0;
          
          if (match.home_score > match.away_score) {
            standingsMap[match.home_team_id].wins++;
            standingsMap[match.home_team_id].form.unshift('W');
          } else if (match.home_score < match.away_score) {
            standingsMap[match.home_team_id].losses++;
            standingsMap[match.home_team_id].form.unshift('L');
          } else {
            standingsMap[match.home_team_id].draws++;
            standingsMap[match.home_team_id].form.unshift('D');
          }
        }
        
        // Away team stats
        if (standingsMap[match.away_team_id]) {
          standingsMap[match.away_team_id].matchesPlayed++;
          standingsMap[match.away_team_id].goalsFor += match.away_score || 0;
          standingsMap[match.away_team_id].goalsAgainst += match.home_score || 0;
          
          if (match.away_score > match.home_score) {
            standingsMap[match.away_team_id].wins++;
            standingsMap[match.away_team_id].form.unshift('W');
          } else if (match.away_score < match.home_score) {
            standingsMap[match.away_team_id].losses++;
            standingsMap[match.away_team_id].form.unshift('L');
          } else {
            standingsMap[match.away_team_id].draws++;
            standingsMap[match.away_team_id].form.unshift('D');
          }
        }
      });

      // Calculate goal difference and limit form to last 5 matches
      Object.values(standingsMap).forEach(team => {
        team.goalDifference = team.goalsFor - team.goalsAgainst;
        team.form = team.form.slice(0, 5);
      });

      // Sort standings by points, then goal difference, then goals for
      const sortedStandings = Object.values(standingsMap).sort((a, b) => {
        if (a.points !== b.points) return b.points - a.points;
        if (a.goalDifference !== b.goalDifference) return b.goalDifference - a.goalDifference;
        return b.goalsFor - a.goalsFor;
      });

      // Update positions after sorting
      sortedStandings.forEach((team, index) => {
        team.position = index + 1;
      });

      setStandings(sortedStandings);
    } catch (err) {
      console.error('Error loading standings:', err);
      setError('Errore nel caricamento della classifica');
    } finally {
      setLoading(false);
    }
  };

  const handleLeagueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLeagueId(e.target.value);
  };

  const getFormIcon = (result: string) => {
    switch (result) {
      case 'W':
        return <ArrowUp className="w-4 h-4 text-green-500" />;
      case 'L':
        return <ArrowDown className="w-4 h-4 text-red-500" />;
      default:
        return <Minus className="w-4 h-4 text-gray-500" />;
    }
  };

  if (loading && leagues.length === 0) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  const selectedLeague = leagues.find(league => league.id === selectedLeagueId);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold text-gray-900">Classifica</h1>
          {leagues.length > 0 && (
            <select
              value={selectedLeagueId || ''}
              onChange={handleLeagueChange}
              className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              {leagues.map(league => (
                <option key={league.id} value={league.id}>
                  {league.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="flex items-center space-x-4">
          {selectedLeagueId && (
            <>
              <button 
                onClick={() => navigate(`/leagues/${selectedLeagueId}/calendar`)}
                className="px-4 py-2 text-gray-600 hover:text-gray-900"
              >
                <Trophy className="w-5 h-5 inline mr-2" />
                Calendario
              </button>
              <button className="px-4 py-2 text-gray-600 hover:text-gray-900">
                <TrendingUp className="w-5 h-5 inline mr-2" />
                Statistiche
              </button>
            </>
          )}
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      {leagues.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg shadow">
          <Trophy className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna lega trovata</h3>
          <p className="mt-1 text-sm text-gray-500">
            Non sei iscritto a nessuna lega o non hai creato leghe.
          </p>
        </div>
      ) : (
        <>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Pos
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Squadra
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      PG
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      V
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      N
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      P
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      GF
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      GS
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      DR
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Punti
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Forma
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {standings.length === 0 ? (
                    <tr>
                      <td colSpan={11} className="px-6 py-4 text-center text-gray-500">
                        Nessun dato disponibile per questa lega
                      </td>
                    </tr>
                  ) : (
                    standings.map((standing) => (
                      <tr key={standing.teamId} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {standing.position}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                {standing.teamName}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                          {standing.matchesPlayed}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                          {standing.wins}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                          {standing.draws}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                          {standing.losses}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                          {standing.goalsFor}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                          {standing.goalsAgainst}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                          {standing.goalDifference}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center font-semibold">
                          {standing.points}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex justify-center space-x-1">
                            {standing.form.map((result, index) => (
                              <span key={index}>{getFormIcon(result)}</span>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Miglior Attacco</h2>
              <div className="space-y-2">
                {standings
                  .sort((a, b) => b.goalsFor - a.goalsFor)
                  .slice(0, 3)
                  .map((standing) => (
                    <div key={standing.teamId} className="flex items-center justify-between">
                      <span className="text-gray-600">{standing.teamName}</span>
                      <span className="font-semibold">{standing.goalsFor}</span>
                    </div>
                  ))}
                {standings.length === 0 && (
                  <div className="text-gray-500 text-center py-2">Nessun dato disponibile</div>
                )}
              </div>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Miglior Difesa</h2>
              <div className="space-y-2">
                {standings
                  .sort((a, b) => a.goalsAgainst - b.goalsAgainst)
                  .slice(0, 3)
                  .map((standing) => (
                    <div key={standing.teamId} className="flex items-center justify-between">
                      <span className="text-gray-600">{standing.teamName}</span>
                      <span className="font-semibold">{standing.goalsAgainst}</span>
                    </div>
                  ))}
                {standings.length === 0 && (
                  <div className="text-gray-500 text-center py-2">Nessun dato disponibile</div>
                )}
              </div>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Forma Migliore</h2>
              <div className="space-y-2">
                {standings
                  .sort((a, b) => {
                    const aWins = a.form.filter(r => r === 'W').length;
                    const bWins = b.form.filter(r => r === 'W').length;
                    return bWins - aWins;
                  })
                  .slice(0, 3)
                  .map((standing) => (
                    <div key={standing.teamId} className="flex items-center justify-between">
                      <span className="text-gray-600">{standing.teamName}</span>
                      <div className="flex space-x-1">
                        {standing.form.map((result, i) => (
                          <span key={i}>{getFormIcon(result)}</span>
                        ))}
                      </div>
                    </div>
                  ))}
                {standings.length === 0 && (
                  <div className="text-gray-500 text-center py-2">Nessun dato disponibile</div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LeagueStandings;