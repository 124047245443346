import React, { useState } from 'react';
import { Save, X } from 'lucide-react';

interface Player {
  id: string;
  name: string;
  team: string;
}

interface PlayerStats {
  goals: number;
  assists: number;
  yellowCards: number;
  redCard: boolean;
  minutesPlayed: number;
}

interface PlayerStatsInputProps {
  matchId: string;
  homeTeam: string;
  awayTeam: string;
  homePlayers: Player[];
  awayPlayers: Player[];
  onSave: (matchId: string, stats: Record<string, PlayerStats>) => void;
  onClose: () => void;
}

const PlayerStatsInput: React.FC<PlayerStatsInputProps> = ({
  matchId,
  homeTeam,
  awayTeam,
  homePlayers,
  awayPlayers,
  onSave,
  onClose
}) => {
  const [stats, setStats] = useState<Record<string, PlayerStats>>({});

  const updatePlayerStats = (playerId: string, field: keyof PlayerStats, value: number | boolean) => {
    setStats(prev => ({
      ...prev,
      [playerId]: {
        ...prev[playerId] || {
          goals: 0,
          assists: 0,
          yellowCards: 0,
          redCard: false,
          minutesPlayed: 0
        },
        [field]: value
      }
    }));
  };

  const renderPlayerRow = (player: Player) => (
    <tr key={player.id} className="hover:bg-gray-50">
      <td className="px-4 py-2 whitespace-nowrap">
        <span className="font-medium">{player.name}</span>
      </td>
      <td className="px-4 py-2">
        <input
          type="number"
          min="0"
          max="90"
          value={stats[player.id]?.minutesPlayed || 0}
          onChange={(e) => updatePlayerStats(player.id, 'minutesPlayed', parseInt(e.target.value) || 0)}
          className="w-16 rounded-md border-gray-300"
        />
      </td>
      <td className="px-4 py-2">
        <input
          type="number"
          min="0"
          value={stats[player.id]?.goals || 0}
          onChange={(e) => updatePlayerStats(player.id, 'goals', parseInt(e.target.value) || 0)}
          className="w-16 rounded-md border-gray-300"
        />
      </td>
      <td className="px-4 py-2">
        <input
          type="number"
          min="0"
          value={stats[player.id]?.assists || 0}
          onChange={(e) => updatePlayerStats(player.id, 'assists', parseInt(e.target.value) || 0)}
          className="w-16 rounded-md border-gray-300"
        />
      </td>
      <td className="px-4 py-2">
        <input
          type="number"
          min="0"
          max="2"
          value={stats[player.id]?.yellowCards || 0}
          onChange={(e) => updatePlayerStats(player.id, 'yellowCards', parseInt(e.target.value) || 0)}
          className="w-16 rounded-md border-gray-300"
        />
      </td>
      <td className="px-4 py-2">
        <input
          type="checkbox"
          checked={stats[player.id]?.redCard || false}
          onChange={(e) => updatePlayerStats(player.id, 'redCard', e.target.checked)}
          className="rounded text-indigo-600 focus:ring-indigo-500"
        />
      </td>
    </tr>
  );

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium">Statistiche Giocatori</h3>
        <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
          <X className="w-5 h-5" />
        </button>
      </div>

      <div className="space-y-6">
        <div>
          <h4 className="font-medium text-gray-900 mb-3">{homeTeam}</h4>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Giocatore</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Minuti</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Gol</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Assist</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Gialli</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Rosso</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {homePlayers.map(renderPlayerRow)}
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <h4 className="font-medium text-gray-900 mb-3">{awayTeam}</h4>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Giocatore</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Minuti</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Gol</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Assist</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Gialli</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Rosso</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {awayPlayers.map(renderPlayerRow)}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="mt-6 flex justify-end">
        <button
          onClick={() => onSave(matchId, stats)}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
        >
          <Save className="w-4 h-4 mr-2" />
          Salva Statistiche
        </button>
      </div>
    </div>
  );
};

export default PlayerStatsInput;