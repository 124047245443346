import React, { useState, useEffect } from 'react';
import { Calendar as CalendarIcon, Clock, AlertCircle, Loader, Save, RefreshCw } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import CoachNavigation from '../components/CoachNavigation';

interface SerieAMatch {
  id: string;
  matchday: number;
  home_team: string;
  away_team: string;
  match_date: string;
  home_score: number | null;
  away_score: number | null;
  status: 'scheduled' | 'in_progress' | 'completed';
}

const SerieACalendar: React.FC = () => {
  const [matches, setMatches] = useState<SerieAMatch[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedMatchday, setSelectedMatchday] = useState<number>(1);

  useEffect(() => {
    loadMatches();
  }, [selectedMatchday]);

  const loadMatches = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('serie_a_matches')
        .select('*')
        .eq('matchday', selectedMatchday)
        .order('match_date', { ascending: true });

      if (error) throw error;
      setMatches(data || []);
    } catch (err) {
      console.error('Error loading matches:', err);
      setError('Errore nel caricamento delle partite');
    } finally {
      setLoading(false);
    }
  };

  const matchdays = Array.from({ length: 38 }, (_, i) => i + 1);

  if (loading) {
    return (
      <div>
        <CoachNavigation />
        <div className="flex justify-center items-center h-64">
          <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <CoachNavigation />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900">Calendario Serie A</h2>
              
              <div className="flex items-center space-x-4">
                <select
                  value={selectedMatchday}
                  onChange={(e) => setSelectedMatchday(parseInt(e.target.value))}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  {matchdays.map(matchday => (
                    <option key={matchday} value={matchday}>
                      Giornata {matchday}
                    </option>
                  ))}
                </select>
                
                <button
                  onClick={loadMatches}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
                >
                  <RefreshCw className="w-4 h-4 mr-2" />
                  Aggiorna
                </button>
              </div>
            </div>

            {error && (
              <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
                <AlertCircle className="w-5 h-5 mr-2" />
                {error}
              </div>
            )}

            <div className="space-y-4">
              {matches.length === 0 ? (
                <div className="text-center py-12">
                  <CalendarIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna partita trovata</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Non ci sono partite programmate per questa giornata.
                  </p>
                </div>
              ) : (
                matches.map((match) => (
                  <div
                    key={match.id}
                    className="bg-white border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex-1">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <span className="font-medium text-gray-900">{match.home_team}</span>
                          </div>
                          <div className="font-bold text-2xl text-gray-900 px-4">
                            {match.status === 'completed' ? 
                              `${match.home_score} - ${match.away_score}` : 
                              'vs'
                            }
                          </div>
                          <div className="flex items-center space-x-2">
                            <span className="font-medium text-gray-900">{match.away_team}</span>
                          </div>
                        </div>
                      </div>
                      
                      <div className="ml-4 flex flex-col items-end">
                        <div className="flex items-center text-sm text-gray-500 mb-2">
                          <Clock className="w-4 h-4 mr-1" />
                          {format(new Date(match.match_date), 'dd MMM yyyy HH:mm', { locale: it })}
                        </div>
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          match.status === 'completed' ? 'bg-green-100 text-green-800' :
                          match.status === 'in_progress' ? 'bg-yellow-100 text-yellow-800' :
                          'bg-gray-100 text-gray-800'
                        }`}>
                          {match.status === 'completed' ? 'Completata' :
                           match.status === 'in_progress' ? 'In Corso' :
                           'Programmata'}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SerieACalendar;