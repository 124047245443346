import React, { useState } from 'react';
import { Save, Edit, X, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface MatchBlockProps {
  matchId: string;
  homeTeam: string;
  awayTeam: string;
  homeScore: number | null;
  awayScore: number | null;
  date: string;
  onSave: (matchId: string, homeScore: number, awayScore: number) => void;
}

const MatchBlock: React.FC<MatchBlockProps> = ({
  matchId,
  homeTeam,
  awayTeam,
  homeScore,
  awayScore,
  date,
  onSave
}) => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [scores, setScores] = useState({
    home: homeScore || 0,
    away: awayScore || 0
  });

  const handleSave = () => {
    onSave(matchId, scores.home, scores.away);
    setIsEditing(false);
  };

  return (
    <div className="bg-white border rounded-lg p-4 shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <span className="text-sm text-gray-500">{date}</span>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => navigate(`/admin/serie-a/match/${matchId}`)}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <Users className="w-4 h-4" />
          </button>
          {!isEditing ? (
            <button
              onClick={() => setIsEditing(true)}
              className="text-indigo-600 hover:text-indigo-900"
            >
              <Edit className="w-4 h-4" />
            </button>
          ) : (
            <button
              onClick={() => setIsEditing(false)}
              className="text-gray-400 hover:text-gray-600"
            >
              <X className="w-4 h-4" />
            </button>
          )}
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex-1 text-right">
          <span className="font-medium">{homeTeam}</span>
        </div>

        <div className="mx-4 flex items-center space-x-2">
          {isEditing ? (
            <>
              <input
                type="number"
                min="0"
                value={scores.home}
                onChange={(e) => setScores({ ...scores, home: parseInt(e.target.value) || 0 })}
                className="w-12 text-center rounded-md border-gray-300"
              />
              <span className="text-gray-400">-</span>
              <input
                type="number"
                min="0"
                value={scores.away}
                onChange={(e) => setScores({ ...scores, away: parseInt(e.target.value) || 0 })}
                className="w-12 text-center rounded-md border-gray-300"
              />
            </>
          ) : (
            <span className="text-xl font-bold">
              {homeScore ?? 0} - {awayScore ?? 0}
            </span>
          )}
        </div>

        <div className="flex-1">
          <span className="font-medium">{awayTeam}</span>
        </div>
      </div>

      {isEditing && (
        <div className="mt-4 flex justify-end">
          <button
            onClick={handleSave}
            className="px-3 py-1 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center text-sm"
          >
            <Save className="w-4 h-4 mr-1" />
            Salva
          </button>
        </div>
      )}
    </div>
  );
};

export default MatchBlock;