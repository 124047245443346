import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Save, AlertCircle, Loader } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface Player {
  id: string;
  name: string;
  role: string;
  real_team: string;
}

interface PlayerStats {
  id?: string;
  player_id: string;
  goals: number;
  assists: number;
  yellow_cards: number;
  red_card: boolean;
  rating: number | null;
}

interface Match {
  id: string;
  home_team: string;
  away_team: string;
  match_date: string;
  status: 'scheduled' | 'in_progress' | 'completed';
  matchday: number;
}

interface Team {
  id: string;
  name: string;
}

const SerieAMatchStats: React.FC = () => {
  const { matchId } = useParams<{ matchId: string }>();
  const [match, setMatch] = useState<Match | null>(null);
  const [homePlayers, setHomePlayers] = useState<Player[]>([]);
  const [awayPlayers, setAwayPlayers] = useState<Player[]>([]);
  const [playerStats, setPlayerStats] = useState<Record<string, PlayerStats>>({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [teams, setTeams] = useState<Record<string, string>>({});
  const [fantaMatchId, setFantaMatchId] = useState<string | null>(null);

  useEffect(() => {
    if (matchId) {
      loadMatchDetails();
    }
  }, [matchId]);

  const loadMatchDetails = async () => {
    try {
      setLoading(true);
      setError(null);

      // Load match details
      const { data: matchData, error: matchError } = await supabase
        .from('serie_a_matches')
        .select('*')
        .eq('id', matchId)
        .single();

      if (matchError) throw matchError;
      if (!matchData) throw new Error('Match not found');

      setMatch(matchData);

      // Find corresponding match in the matches table
      // Remove .single() and use .limit(1) instead since we just need one match
      const { data: fantaMatches, error: fantaMatchError } = await supabase
        .from('matches')
        .select('id')
        .eq('league_id', '00000000-0000-0000-0000-000000000000') // Replace with actual league ID if needed
        .eq('matchday', matchData.matchday)
        .limit(1);

      if (!fantaMatchError && fantaMatches && fantaMatches.length > 0) {
        setFantaMatchId(fantaMatches[0].id);
      } else {
        console.log('No corresponding fantasy match found. Will create one if needed.');
      }

      // Load teams data
      const { data: teamsData, error: teamsError } = await supabase
        .from('teams_list')
        .select('id, name')
        .in('name', [matchData.home_team, matchData.away_team]);

      if (teamsError) throw teamsError;

      const teamsMap: Record<string, string> = {};
      teamsData?.forEach((team: Team) => {
        teamsMap[team.name] = team.id;
      });
      setTeams(teamsMap);

      // Load players for both teams
      const { data: homePlayers, error: homeError } = await supabase
        .from('players')
        .select('*')
        .eq('real_team', matchData.home_team)
        .order('role', { ascending: true })
        .order('name', { ascending: true });

      if (homeError) throw homeError;
      setHomePlayers(homePlayers || []);

      const { data: awayPlayers, error: awayError } = await supabase
        .from('players')
        .select('*')
        .eq('real_team', matchData.away_team)
        .order('role', { ascending: true })
        .order('name', { ascending: true });

      if (awayError) throw awayError;
      setAwayPlayers(awayPlayers || []);

      // Load existing stats if fantasy match exists
      const { data: existingStats, error: statsError } = await supabase
        .from('player_stats')
        .select('*')
        .eq('matchday', matchData.matchday);

      if (!statsError && existingStats) {
        const statsMap: Record<string, PlayerStats> = {};
        existingStats.forEach(stat => {
          statsMap[stat.player_id] = {
            id: stat.id,
            player_id: stat.player_id,
            goals: stat.goals || 0,
            assists: stat.assists || 0,
            yellow_cards: stat.yellow_cards || 0,
            red_card: stat.red_cards > 0,
            rating: stat.rating
          };
        });

        setPlayerStats(statsMap);
      }
    } catch (err) {
      console.error('Error loading match details:', err);
      setError('Error loading match details');
    } finally {
      setLoading(false);
    }
  };

  const handleStatChange = (playerId: string, field: keyof PlayerStats, value: any) => {
    setPlayerStats(prev => ({
      ...prev,
      [playerId]: {
        ...prev[playerId] || { player_id: playerId, goals: 0, assists: 0, yellow_cards: 0, red_card: false, rating: null },
        [field]: value
      }
    }));
  };

  const handleSave = async () => {
    if (!match) return;

    try {
      setSaving(true);
      setError(null);
      setSuccess(null);

      // Instead of using match_players, we'll use player_stats which has a matchday field
      const statsToSave = Object.entries(playerStats).map(([playerId, stat]) => {
        return {
          player_id: playerId,
          matchday: match.matchday,
          goals: stat.goals,
          assists: stat.assists,
          yellow_cards: stat.yellow_cards,
          red_cards: stat.red_card ? 1 : 0,
          rating: stat.rating
        };
      });

      // Upsert player stats
      const { error: upsertError } = await supabase
        .from('player_stats')
        .upsert(statsToSave);

      if (upsertError) throw upsertError;

      setSuccess('Statistics saved successfully');
    } catch (err) {
      console.error('Error saving stats:', err);
      setError('Error saving statistics');
    } finally {
      setSaving(false);
    }
  };

  const renderPlayerRow = (player: Player) => {
    const stats = playerStats[player.id] || {
      player_id: player.id,
      goals: 0,
      assists: 0,
      yellow_cards: 0,
      red_card: false,
      rating: null
    };

    return (
      <tr key={player.id} className="hover:bg-gray-50">
        <td className="px-4 py-2 whitespace-nowrap">
          <div>
            <div className="font-medium text-gray-900">{player.name}</div>
            <div className="text-sm text-gray-500">{player.role}</div>
          </div>
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <input
            type="number"
            min="0"
            value={stats.goals}
            onChange={(e) => handleStatChange(player.id, 'goals', parseInt(e.target.value) || 0)}
            className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <input
            type="number"
            min="0"
            value={stats.assists}
            onChange={(e) => handleStatChange(player.id, 'assists', parseInt(e.target.value) || 0)}
            className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <input
            type="number"
            min="0"
            max="2"
            value={stats.yellow_cards}
            onChange={(e) => handleStatChange(player.id, 'yellow_cards', parseInt(e.target.value) || 0)}
            className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <input
            type="checkbox"
            checked={stats.red_card}
            onChange={(e) => handleStatChange(player.id, 'red_card', e.target.checked)}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <input
            type="number"
            min="0"
            max="10"
            step="0.5"
            value={stats.rating || ''}
            onChange={(e) => handleStatChange(player.id, 'rating', e.target.value ? parseFloat(e.target.value) : null)}
            className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </td>
      </tr>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  if (!match) {
    return (
      <div className="text-center py-12">
        <div className="text-xl font-medium text-gray-900">Match not found</div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900">
            {match.home_team} vs {match.away_team}
          </h1>
          <button
            onClick={handleSave}
            disabled={saving}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center disabled:bg-indigo-300"
          >
            {saving ? (
              <Loader className="w-4 h-4 mr-2 animate-spin" />
            ) : (
              <Save className="w-4 h-4 mr-2" />
            )}
            Save Statistics
          </button>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
            <AlertCircle className="w-5 h-5 mr-2" />
            {error}
          </div>
        )}

        {success && (
          <div className="bg-green-50 border border-green-200 text-green-600 px-4 py-3 rounded flex items-center">
            <Save className="w-5 h-5 mr-2" />
            {success}
          </div>
        )}

        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">{match.home_team}</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Player</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Goals</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Assists</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Yellow Cards</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Red Card</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rating</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {homePlayers.map(renderPlayerRow)}
              </tbody>
            </table>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">{match.away_team}</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Player</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Goals</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Assists</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Yellow Cards</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Red Card</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rating</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {awayPlayers.map(renderPlayerRow)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SerieAMatchStats;