import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useParams } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './pages/Login';
import MyLeagues from './pages/MyLeagues';
import Leghe from './pages/Leghe';
import LeagueManage from './pages/LeagueManage';
import LeagueTeams from './pages/LeagueTeams';
import LeagueCalendar from './pages/LeagueCalendar';
import LeagueTransfers from './pages/LeagueTransfers';
import LeagueStandings from './pages/LeagueStandings';
import AdminPanel from './pages/AdminPanel';
import CalendarManagement from './pages/CalendarManagement';
import SerieAMatchManagement from './pages/SerieAMatchManagement';
import SerieAMatchStats from './pages/SerieAMatchStats';
import TeamManagement from './pages/TeamManagement';
import PlayerMarket from './pages/PlayerMarket';
import Profile from './pages/Profile';
import LiveAuction from './pages/LiveAuction';
import LiveMatch from './pages/LiveMatch';
import MyPlayers from './pages/MyPlayers';
import Formation from './pages/Formation';
import Calendar from './pages/Calendar';
import SerieACalendar from './pages/SerieACalendar';
import LiveAuctions from './pages/LiveAuctions';
import FreePlayers from './pages/FreePlayers';
import AvailableLeagues from './pages/AvailableLeagues';
import TeamDetails from './pages/TeamDetails';
import { useAuthStore } from './store/authStore';
import { supabase } from './lib/supabase';

const RouteManager = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  
  useEffect(() => {
    sessionStorage.setItem('lastRoute', location.pathname + location.search);
  }, [location]);

  return <>{children}</>;
};

function App() {
  const { user, setUser, checkSession } = useAuthStore();

  useEffect(() => {
    checkSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_OUT') {
        setUser(null);
        sessionStorage.removeItem('lastRoute');
      } else if (session) {
        checkSession();
        const lastRoute = sessionStorage.getItem('lastRoute');
        if (lastRoute) {
          window.history.pushState({}, '', lastRoute);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [checkSession, setUser]);

  function ProtectedRoute({ children, requiredRole }: { children: React.ReactNode; requiredRole?: string }) {
    if (!user) {
      sessionStorage.setItem('attemptedRoute', window.location.pathname);
      return <Navigate to="/login" />;
    }

    if (requiredRole && user.role !== requiredRole && user.role !== 'admin') {
      return <Navigate to="/leagues" />;
    }

    return <>{children}</>;
  }

  return (
    <BrowserRouter>
      <RouteManager>
        <Routes>
          <Route path="/login" element={user ? <Navigate to={sessionStorage.getItem('attemptedRoute') || '/leagues'} /> : <Login />} />
          <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
            <Route index element={<Navigate to="/leagues" replace />} />
            <Route path="leagues" element={
              user?.role === 'coach' ? <MyLeagues /> : <Leghe />
            } />
            <Route path="available-leagues" element={<AvailableLeagues />} />
            <Route path="leagues/:id" element={<LeagueStandings />} />
            <Route path="leagues/:id/standings" element={<LeagueStandings />} />
            <Route path="leagues/:id/manage" element={<LeagueManage />} />
            <Route path="leagues/:id/settings" element={<LeagueManage />} />
            <Route path="leagues/:id/teams" element={<LeagueTeams />} />
            <Route path="leagues/:id/calendar" element={<LeagueCalendar />} />
            <Route path="leagues/:id/transfers" element={<LeagueTransfers />} />
            <Route path="leagues/:id/auction" element={<LiveAuction />} />
            <Route path="leagues/:id/match/:matchId" element={<LiveMatch />} />
            <Route path="team" element={<TeamManagement />} />
            <Route path="market" element={<PlayerMarket />} />
            <Route path="admin" element={<ProtectedRoute requiredRole="admin"><AdminPanel /></ProtectedRoute>} />
            <Route path="admin/calendar" element={<ProtectedRoute requiredRole="admin"><CalendarManagement /></ProtectedRoute>} />
            <Route path="admin/serie-a" element={<ProtectedRoute requiredRole="admin"><SerieAMatchManagement /></ProtectedRoute>} />
            <Route path="admin/serie-a/match/:matchId" element={<ProtectedRoute requiredRole="admin"><SerieAMatchStats /></ProtectedRoute>} />
            <Route path="profile" element={<Profile />} />
            <Route path="profile/team" element={<TeamDetails />} />
            <Route path="my-players" element={<MyPlayers />} />
            <Route path="formation" element={<Formation />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="calendar/serie-a" element={<SerieACalendar />} />
            <Route path="live-auctions" element={<LiveAuctions />} />
            <Route path="free-players" element={<FreePlayers />} />
            <Route path="live-match" element={<LiveMatch />} />
          </Route>
        </Routes>
      </RouteManager>
    </BrowserRouter>
  );
}

export default App;