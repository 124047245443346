import React, { useState, useEffect } from 'react';
import { useTeamStore } from '../store/teamStore';
import { useFormationStore } from '../store/formationStore';
import { Player } from '../types';
import { Loader, AlertCircle, Save, Trophy, Calendar, ArrowLeftRight } from 'lucide-react';
import CoachNavigation from '../components/CoachNavigation';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/authStore';

interface Team {
  id: string;
  name: string;
  league: {
    id: string;
    name: string;
  };
}

interface Match {
  id: string;
  matchday: number;
  played_at: string;
  status: 'scheduled' | 'in_progress' | 'completed';
}

interface Substitute {
  id: string;
  name: string;
  role: string;
  team: string;
}

const Formation: React.FC = () => {
  const { user } = useAuthStore();
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [matches, setMatches] = useState<Match[]>([]);
  const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);
  const [selectedFormation, setSelectedFormation] = useState('4-3-3');
  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<{[key: string]: Player | null}>({});
  const [substitutes, setSubstitutes] = useState<Substitute[]>([]);
  const [showPlayerSelector, setShowPlayerSelector] = useState<string | null>(null);
  const [showSubstituteSelector, setShowSubstituteSelector] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const formations = {
    '4-3-3': {
      name: '4-3-3',
      positions: [
        { id: 'gk', x: 50, y: 90, role: 'P' },
        { id: 'lb', x: 20, y: 70, role: 'D' },
        { id: 'cb1', x: 40, y: 70, role: 'D' },
        { id: 'cb2', x: 60, y: 70, role: 'D' },
        { id: 'rb', x: 80, y: 70, role: 'D' },
        { id: 'cm1', x: 30, y: 50, role: 'C' },
        { id: 'cm2', x: 50, y: 50, role: 'C' },
        { id: 'cm3', x: 70, y: 50, role: 'C' },
        { id: 'lw', x: 20, y: 30, role: 'A' },
        { id: 'st', x: 50, y: 30, role: 'A' },
        { id: 'rw', x: 80, y: 30, role: 'A' }
      ]
    },
    '4-4-2': {
      name: '4-4-2',
      positions: [
        { id: 'gk', x: 50, y: 90, role: 'P' },
        { id: 'lb', x: 20, y: 70, role: 'D' },
        { id: 'cb1', x: 40, y: 70, role: 'D' },
        { id: 'cb2', x: 60, y: 70, role: 'D' },
        { id: 'rb', x: 80, y: 70, role: 'D' },
        { id: 'lm', x: 20, y: 50, role: 'C' },
        { id: 'cm1', x: 40, y: 50, role: 'C' },
        { id: 'cm2', x: 60, y: 50, role: 'C' },
        { id: 'rm', x: 80, y: 50, role: 'C' },
        { id: 'st1', x: 35, y: 30, role: 'A' },
        { id: 'st2', x: 65, y: 30, role: 'A' }
      ]
    },
    '3-5-2': {
      name: '3-5-2',
      positions: [
        { id: 'gk', x: 50, y: 90, role: 'P' },
        { id: 'cb1', x: 30, y: 70, role: 'D' },
        { id: 'cb2', x: 50, y: 70, role: 'D' },
        { id: 'cb3', x: 70, y: 70, role: 'D' },
        { id: 'lm', x: 20, y: 50, role: 'C' },
        { id: 'cm1', x: 40, y: 50, role: 'C' },
        { id: 'cm2', x: 50, y: 50, role: 'C' },
        { id: 'cm3', x: 60, y: 50, role: 'C' },
        { id: 'rm', x: 80, y: 50, role: 'C' },
        { id: 'st1', x: 35, y: 30, role: 'A' },
        { id: 'st2', x: 65, y: 30, role: 'A' }
      ]
    }
  };

  useEffect(() => {
    if (user) {
      loadTeams();
    }
  }, [user]);

  useEffect(() => {
    if (selectedTeam) {
      loadMatches();
      loadPlayers();
      loadFormation();
    }
  }, [selectedTeam]);

  const loadTeams = async () => {
    try {
      setLoading(true);
      const { data: teamsData, error: teamsError } = await supabase
        .from('teams')
        .select(`
          id,
          name,
          league:leagues (
            id,
            name
          )
        `)
        .eq('coach_id', user?.id);

      if (teamsError) throw teamsError;
      if (!teamsData || teamsData.length === 0) {
        setTeams([]);
        setSelectedTeam(null);
        return;
      }

      setTeams(teamsData);
      setSelectedTeam(teamsData[0]);
    } catch (err) {
      console.error('Error loading teams:', err);
      setError('Errore nel caricamento delle squadre');
    }
  };

  const loadMatches = async () => {
    if (!selectedTeam) return;

    try {
      const { data: matchesData, error: matchesError } = await supabase
        .from('matches')
        .select('*')
        .eq('league_id', selectedTeam.league.id)
        .or('home_team_id.eq.' + selectedTeam.id + ',away_team_id.eq.' + selectedTeam.id)
        .order('matchday', { ascending: true });

      if (matchesError) throw matchesError;
      setMatches(matchesData || []);
      if (matchesData && matchesData.length > 0) {
        setSelectedMatch(matchesData[0]);
      }
    } catch (err) {
      console.error('Error loading matches:', err);
      setError('Errore nel caricamento delle partite');
    }
  };

  const loadPlayers = async () => {
    if (!selectedTeam) return;

    try {
      const { data: playerData, error: playerError } = await supabase
        .from('team_players')
        .select(`
          player:players (
            id,
            name,
            role,
            real_team,
            stats:player_stats (
              goals,
              assists,
              yellow_cards,
              red_cards,
              rating
            )
          )
        `)
        .eq('team_id', selectedTeam.id);

      if (playerError) throw playerError;

      const formattedPlayers = playerData.map(item => ({
        id: item.player.id,
        name: item.player.name,
        team: item.player.real_team,
        role: item.player.role,
        stats: {
          goals: item.player.stats?.[0]?.goals || 0,
          assists: item.player.stats?.[0]?.assists || 0,
          yellowCards: item.player.stats?.[0]?.yellow_cards || 0,
          redCards: item.player.stats?.[0]?.red_cards || 0,
          averageRating: item.player.stats?.[0]?.rating || null
        }
      }));

      setPlayers(formattedPlayers);
    } catch (err) {
      console.error('Error loading players:', err);
      setError('Errore nel caricamento dei giocatori');
    } finally {
      setLoading(false);
    }
  };

  const loadFormation = async () => {
    if (!selectedTeam || !selectedMatch) return;

    try {
      const { data: formation, error: formationError } = await supabase
        .from('team_formations')
        .select(`
          formation,
          player_positions (
            position,
            player_id,
            is_substitute
          )
        `)
        .eq('team_id', selectedTeam.id)
        .single();

      if (formationError) throw formationError;

      if (formation) {
        setSelectedFormation(formation.formation);
        
        // Set selected players
        const positions: {[key: string]: Player | null} = {};
        const subs: Substitute[] = [];

        formation.player_positions.forEach((pos: any) => {
          const player = players.find(p => p.id === pos.player_id);
          if (player) {
            if (pos.is_substitute) {
              subs.push({
                id: player.id,
                name: player.name,
                role: player.role,
                team: player.team
              });
            } else {
              positions[pos.position] = player;
            }
          }
        });

        setSelectedPlayers(positions);
        setSubstitutes(subs);
      }
    } catch (err) {
      console.error('Error loading formation:', err);
      // Don't show error as this might be first time setting formation
    }
  };

  const handlePositionClick = (positionId: string, role: string) => {
    setShowPlayerSelector(positionId);
  };

  const handlePlayerSelect = (position: string, player: Player | null) => {
    // Remove player from previous position if exists
    const previousPosition = Object.entries(selectedPlayers).find(([_, p]) => p?.id === player?.id)?.[0];
    if (previousPosition) {
      setSelectedPlayers(prev => ({
        ...prev,
        [previousPosition]: null
      }));
    }

    // Remove from substitutes if exists
    if (player) {
      setSubstitutes(prev => prev.filter(p => p.id !== player.id));
    }

    // Update selected players
    setSelectedPlayers(prev => ({
      ...prev,
      [position]: player
    }));

    setShowPlayerSelector(null);
  };

  const handleSubstituteSelect = (player: Player) => {
    // Remove from field positions if exists
    const fieldPosition = Object.entries(selectedPlayers).find(([_, p]) => p?.id === player.id)?.[0];
    if (fieldPosition) {
      setSelectedPlayers(prev => ({
        ...prev,
        [fieldPosition]: null
      }));
    }

    // Add to substitutes if not already there
    if (!substitutes.find(s => s.id === player.id)) {
      setSubstitutes(prev => [...prev, {
        id: player.id,
        name: player.name,
        role: player.role,
        team: player.team
      }]);
    }

    setShowSubstituteSelector(false);
  };

  const handleRemoveSubstitute = (substituteId: string) => {
    setSubstitutes(prev => prev.filter(s => s.id !== substituteId));
  };

  const handleSaveFormation = async () => {
    if (!selectedTeam || !selectedMatch) return;

    try {
      setSaving(true);
      setError(null);

      // Prepare positions data
      const positions = [
        ...Object.entries(selectedPlayers).map(([position, player]) => ({
          position,
          player_id: player?.id,
          is_substitute: false
        })),
        ...substitutes.map((sub, index) => ({
          position: `sub${index + 1}`,
          player_id: sub.id,
          is_substitute: true
        }))
      ].filter(pos => pos.player_id); // Remove empty positions

      // Save formation
      const { error: formationError } = await supabase
        .from('team_formations')
        .upsert({
          team_id: selectedTeam.id,
          formation: selectedFormation,
          created_at: new Date().toISOString()
        });

      if (formationError) throw formationError;

      // Save player positions
      const { error: positionsError } = await supabase
        .from('player_positions')
        .upsert(positions);

      if (positionsError) throw positionsError;

      setError(null);
    } catch (err: any) {
      console.error('Error saving formation:', err);
      setError(err.message);
    } finally {
      setSaving(false);
    }
  };

  if (loading && teams.length === 0) {
    return (
      <div>
        <CoachNavigation />
        <div className="flex justify-center items-center h-64">
          <Loader className="w-8 h-8 animate-spin text-indigo-600" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <CoachNavigation />
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      </div>
    );
  }

  if (teams.length === 0) {
    return (
      <div>
        <CoachNavigation />
        <div className="text-center py-12">
          <Trophy className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">Nessuna squadra trovata</h3>
          <p className="mt-1 text-sm text-gray-500">
            Non hai ancora creato una squadra.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <CoachNavigation />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
              <h2 className="text-2xl font-bold text-gray-900">Formazione</h2>
              
              <div className="flex flex-wrap items-center gap-4">
                <select
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={selectedTeam?.id}
                  onChange={(e) => {
                    const team = teams.find(t => t.id === e.target.value);
                    setSelectedTeam(team || null);
                  }}
                >
                  {teams.map((team) => (
                    <option key={team.id} value={team.id}>
                      {team.name} ({team.league.name})
                    </option>
                  ))}
                </select>

                <select
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={selectedMatch?.id}
                  onChange={(e) => {
                    const match = matches.find(m => m.id === e.target.value);
                    setSelectedMatch(match || null);
                  }}
                >
                  {matches.map((match) => (
                    <option key={match.id} value={match.id}>
                      Giornata {match.matchday}
                    </option>
                  ))}
                </select>

                <select
                  value={selectedFormation}
                  onChange={(e) => setSelectedFormation(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  {Object.keys(formations).map(formation => (
                    <option key={formation} value={formation}>{formation}</option>
                  ))}
                </select>

                <button
                  onClick={handleSaveFormation}
                  disabled={saving}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-300"
                >
                  {saving ? (
                    <Loader className="w-4 h-4 mr-2 animate-spin" />
                  ) : (
                    <Save className="w-4 h-4 mr-2" />
                  )}
                  Salva
                </button>
              </div>
            </div>

            <div className="relative h-[600px] bg-gradient-to-b from-green-600 to-green-700 rounded-lg overflow-hidden">
              <div className="absolute inset-0">
                {/* Field markings */}
                <div className="absolute inset-4 border-2 border-white/30 rounded" />
                <div className="absolute left-1/2 top-4 bottom-4 -translate-x-px border-l-2 border-white/30" />
                <div className="absolute left-4 right-4 top-1/2 -translate-y-px border-t-2 border-white/30" />
                <div className="absolute left-1/2 top-4 -translate-x-1/2 w-32 h-32 border-2 border-white/30 rounded-full" />
                
                {/* Player positions */}
                {formations[selectedFormation as keyof typeof formations].positions.map((pos) => {
                  const player = selectedPlayers[pos.id];
                  return (
                    <div
                      key={pos.id}
                      className="absolute w-12 h-12 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      style={{ left: `${pos.x}%`, top: `${pos.y}%` }}
                      onClick={() => handlePositionClick(pos.id, pos.role)}
                    >
                      <div className={`
                        w-full h-full rounded-full flex items-center justify-center text-sm font-medium
                        ${player
                          ? 'bg-white text-gray-900'
                          : 'bg-white/20 text-white border-2 border-white/50'}
                      `}>
                        {player?.name?.split(' ')[0] || pos.role}
                      </div>
                    </div>
                  );
                })}

                {/* Player selector modal */}
                {showPlayerSelector && (
                  <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                    <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full mx-4">
                      <h3 className="text-lg font-medium text-gray-900 mb-4">
                        Seleziona Giocatore
                      </h3>
                      
                      <div className="space-y-2 max-h-96 overflow-y-auto">
                        <button
                          className="w-full text-left px-4 py-2 hover:bg-gray-50 rounded-lg text-gray-500"
                          onClick={() => handlePlayerSelect(showPlayerSelector, null)}
                        >
                          Rimuovi giocatore
                        </button>
                        
                        {players
                          .filter(p => {
                            const position = formations[selectedFormation as keyof typeof formations]
                              .positions.find(pos => pos.id === showPlayerSelector);
                            return p.role === position?.role;
                          })
                          .map(player => {
                            const isSelected = Object.values(selectedPlayers).some(p => p?.id === player.id) ||
                                            substitutes.some(s => s.id === player.id);
                            return (
                              <button
                                key={player.id}
                                className={`w-full text-left px-4 py-2 hover:bg-gray-50 rounded-lg ${
                                  isSelected ? 'bg-gray-50' : ''
                                }`}
                                onClick={() => handlePlayerSelect(showPlayerSelector, player)}
                                disabled={isSelected}
                              >
                                <div className="font-medium text-gray-900">{player.name}</div>
                                <div className="text-sm text-gray-500">{player.team}</div>
                              </button>
                            );
                          })}
                      </div>
                      
                      <div className="mt-4 flex justify-end">
                        <button
                          onClick={() => setShowPlayerSelector(null)}
                          className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
                        >
                          Annulla
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Substitutes bench */}
            <div className="mt-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium text-gray-900">Panchina</h3>
                <button
                  onClick={() => setShowSubstituteSelector(true)}
                  className="text-indigo-600 hover:text-indigo-700 text-sm font-medium flex items-center"
                >
                  <ArrowLeftRight className="w-4 h-4 mr-1" />
                  Aggiungi Sostituto
                </button>
              </div>

              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                {substitutes.map((substitute) => (
                  <div
                    key={substitute.id}
                    className="bg-white border rounded-lg p-3 text-center relative group"
                  >
                    <button
                      onClick={() => handleRemoveSubstitute(substitute.id)}
                      className="absolute top-2 right-2 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      ×
                    </button>
                    <div className="font-medium text-gray-900">{substitute.name}</div>
                    <div className="text-sm text-gray-500">{substitute.role}</div>
                  </div>
                ))}

                {Array.from({ length: Math.max(0, 12 - substitutes.length) }).map((_, index) => (
                  <div
                    key={`empty-${index}`}
                    className="border-2 border-dashed border-gray-300 rounded-lg p-3 text-center text-gray-400 cursor-pointer hover:border-gray-400 hover:text-gray-500"
                    onClick={() => setShowSubstituteSelector(true)}
                  >
                    <div className="text-sm">Aggiungi</div>
                  </div>
                ))}
              </div>
            </div>

            {/* Substitute selector modal */}
            {showSubstituteSelector && (
              <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full mx-4">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Seleziona Sostituto
                  </h3>
                  
                  <div className="space-y-2 max-h-96 overflow-y-auto">
                    {players
                      .filter(player => {
                        const isOnField = Object.values(selectedPlayers).some(p => p?.id === player.id);
                        const isSubstitute = substitutes.some(s => s.id === player.id);
                        return !isOnField && !isSubstitute;
                      })
                      .map(player => (
                        <button
                          key={player.id}
                          className="w-full text-left px-4 py-2 hover:bg-gray-50 rounded-lg"
                          onClick={() => handleSubstituteSelect(player)}
                        >
                          <div className="font-medium text-gray-900">{player.name}</div>
                          <div className="text-sm text-gray-500">
                            {player.role} - {player.team}
                          </div>
                        </button>
                      ))}
                  </div>
                  
                  <div className="mt-4 flex justify-end">
                    <button
                      onClick={() => setShowSubstituteSelector(false)}
                      className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
                    >
                      Annulla
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Formation;