import { supabase } from '../lib/supabase';

export const teamService = {
  async createTeam(data: {
    name: string;
    leagueId: string;
    budgetRemaining: number;
  }) {
    const { data: team, error } = await supabase
      .from('teams')
      .insert({
        name: data.name,
        league_id: data.leagueId,
        coach_id: (await supabase.auth.getUser()).data.user?.id,
        budget_remaining: data.budgetRemaining
      })
      .select()
      .single();

    if (error) throw error;
    return team;
  },

  async getTeamsByLeague(leagueId: string) {
    const { data: teams, error } = await supabase
      .from('teams')
      .select(`
        *,
        coach:profiles(username),
        players:team_players(
          player:players(*)
        )
      `)
      .eq('league_id', leagueId);

    if (error) throw error;
    return teams;
  },

  async getMyTeams() {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data: teams, error } = await supabase
      .from('teams')
      .select(`
        *,
        league:leagues(*),
        players:team_players(
          player:players(*)
        )
      `)
      .eq('coach_id', user.id);

    if (error) throw error;
    return teams;
  }
};